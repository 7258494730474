<template>
  <div class="quotes-view">
    <!-- <div class="container"> -->
    <div class="header">
      <h1>
        Random Quotes
      </h1>
    </div>
    <div class="quote" v-for="quote in quotes" :key="quote.id">
      <div class="quote-header">
        {{ quote.author }}
      </div>
      <div class="quote-body">
        {{ quote.body }}
      </div>
      <div class="quote-footer">
        <img
          src="../../assets/quotes/thumb_unfilled.png"
          alt="thumbs up"
          @click="likeQuote(quote)"
        />
        <img
          src="../../assets/quotes/thumb_unfilled.png"
          alt="thumbs down"
          id="thumbs-down"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      quotes: [],
      apiKey: ''
    }
  },
  methods: {
    async fetchRandomQuotes() {
      if (this.apiKeyComputed) {
        let quoteObject = await fetch('https://favqs.com/api/quotes', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token token="${this.apiKeyComputed}"`
          },
          method: 'GET'
        })
          .then(response => response.json())
          .then(result => {
            return result
          })
          .catch(e => {
            console.log(e)
          })
        this.quotes = quoteObject.quotes
      }
    },
    likeQuote(quote) {
      console.log(quote)
    },
    async setApiKey() {
      this.apiKey = await this.getQuotesApiKey
      return true
    }
  },
  computed: {
    ...mapGetters(['getQuotesApiKey']),
    apiKeyComputed() {
      return this.getQuotesApiKey
    }
  },
  beforeMount() {
    this.setApiKey()
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchRandomQuotes()
    })
  },
  watch: {
    apiKeyComputed: function() {
      this.setApiKey()
      this.fetchRandomQuotes()
    }
  }
}
</script>

<style scoped>
h1 {
  text-align: center;
  position: fixed;
  left: 80px;
  z-index: 1;
}

.header {
  height: 100px;
}

.quotes-view {
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-row-gap: 25px;
}
.quote {
  border: 1px solid #7fc8f8;
  box-shadow: 1px 1px 1px 1px #676767;
  display: flex;
  flex-direction: column;
  height: 500px;
  z-index: 3;
}
.quote-header {
  align-items: center;
  background-color: #a5adb6;
  border-bottom: 0.5px solid #7fc8f8;
  display: flex;
  font-weight: 700;
  height: 50px;
  justify-content: center;
}

.quote-body {
  align-items: center;
  background-color: #f4f5f6;
  display: flex;
  flex-grow: 1;
  font-size: 1.5rem;
  min-height: 140px;
  padding: 20px;
}

.quote-footer {
  display: flex;
  height: 100px;
  border-top: 0.5px solid black;
  background-color: #a5adb6;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.quote-footer img {
  align-self: center;
  justify-self: center;
  height: 40px;
}

#thumbs-down {
  rotate: 180deg;
}
</style>
