import * as fb from '../../firebase'
import router from '../../router/index'

const state = {
	userProfile: {}
}

const mutations = {
	setUserProfile(state, val) {
		state.userProfile = val
	},
	addQuotesApiKey(state, val) {
		state.userProfile.quotesApiKey = val
	},
	addQuoteUserSession(state, val) {
		state.userProfile.quoteUserSession = val
	}
}

const actions = {
	async login(context, form) {
		// sign user in
		let { user } = await fb.auth.signInWithEmailAndPassword(
			form.email,
			form.password
		)
		user.password = form.password

		// fetch user profile and set in state
		context.dispatch('fetchUserProfile', user)
	},
	async createQuoteUserSession(context, form) {
		let userO = {
			user: {
				login: form.name,
				password: form.password
			}
		}

		let userSession = await fetch('https://favqs.com/api/session', {
			body: JSON.stringify(userO),
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token token="${context.state.userProfile.quotesApiKey}"`
			},
			method: 'POST'
		})
			.then((result) => result.json())
			.then((response) => {
				return response
			})
			.catch((e) => {
				console.log(e)
			})

		if (userSession) {
			context.commit('addQuoteUserSession', userSession['User-Token'])
		}
	},
	async fetchUserProfile(context, user) {
		// fetch user profile
		let userProfile = await fb.usersCollection.doc(user.uid).get()

		userProfile = userProfile.data()

		userProfile.uid = user.uid
		
		// set user profile in state
		context.commit('setUserProfile', userProfile)
		// userProfile.password = user.password
		context.dispatch('createQuoteUserSession', userProfile)

		// change route to home
		if (router.currentRoute._value.path === '/login') {
			router.push('/')
		}
	},
	async setPin(context, payload) {
		console.log('setPin',payload)
		let user ={
			uid: payload.uid,
			quotesApiKey: payload.quotesApiKey,
			name: payload.name,
			pin: payload.pin
		}

		await fb.usersCollection.doc(user.uid).set(user)

		context.commit('setUserProfile', user)
	},
	async signup(context, form) {
		// sign user up
		const { user } = await fb.auth.createUserWithEmailAndPassword(
			form.email,
			form.password
		)

		// create user profile object in usersCollections
		await fb.usersCollection.doc(user.uid).set({
			name: form.name
		})

		// fetch user profile and set in state
		context.dispatch('fetchUserProfile', user)
	},
	async logout(context) {
		// console.log('logout')
		fb.auth
			.signOut()
			.then(() => {
				// Sign-out successful.
			})
			.catch((error) => {
				// An error happened.
				console.log(error)
			})
		context.commit('setUserProfile', {})
	},
	async destroyQuoteUserSession(context) {
		const apiKey = context.state.userProfile.quotesApiKey
		const quoteUserSession = context.state.userProfile.quoteUserSession
		fetch('https://favqs.com/api/session', {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Token token="${apiKey}"`,
			'User-Token': `"${quoteUserSession}"`
		},
		method: 'DELETE'
	})
		.then((response) => response.json())
		.catch((e) => {
			console.log(e)
		})
	},
	async setQuotesApiKey(context, payload) {
		console.log('setQuotesApiKey', payload)
		let userProfile = context.state.userProfile
		userProfile.quotesApiKey = payload
		await fb.usersCollection.doc(userProfile.uid).set(userProfile)
		context.commit('addQuotesApiKey', payload)
	}
}

const getters = {
	getUser(state) {
		return state.userProfile
	},
	getQuotesApiKey(state) {
		return state.userProfile.quotesApiKey
	}
}

export default {
	state,
	mutations,
	actions,
	getters
}
