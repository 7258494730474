<template>
	<div class="content">
		<div class="content-header">
			<span data-cy="dailyTimeDisplay" v-if="option === 'daily'">
				{{ removedDate }}
			</span>
			<span data-cy="randomTimeDisplay" v-else>
				{{ entry.time }}
			</span>
			<div data-cy="editEntryButton" @click="editEntry">
				<img src="../../assets/edit.svg" alt="edit" />
			</div>
		</div>
		<div data-cy="entryContentBody" class="content-body">
				{{ entry.entry }}
		</div>
	</div>
</template>

<script>
	export default {
		emits: ['editEntry'],
		props: ['entry', 'option'],
		methods: {
			editEntry() {
				this.$emit('editEntry', this.entry)
			}
		},
		computed: {
			removedDate() {
				return this.entry.time.split(' ')[1]
			}
		}
	}
</script>

<style scoped>
	.content {
		min-height: 100px;
		border: 1px solid #727272;
		background-color: #eef0f2;
		box-shadow: 1px 1px 1px 1px #676767;
	}

	.content-header {
		background-color: #2D3142;
		color: white;
		height: 30px;
		border-bottom: 1px solid black;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		padding-left: 10px;
		padding-right: 10px;
	}
	.content-header span {
		grid-row: 1;
		align-self: center;
	}
	.content-header div {
		grid-row: 1;
		justify-self: end;
	}
	.content-header img {
		height: 27px;
	}
	.content-body {
    white-space: pre-line;
		background-color: #f4f5f6;
		padding: 10px;
	}
</style>
