<template>
  <div class="filter-container" @keyup="checkKey">
    <p
      tabindex="1"
      @click="lowerDate"
      @keyup.enter="lowerDate"
      @keyup.space="lowerDate"
    >
      &#60;
    </p>
    <div
      tabindex="2"
      id="journalFilter"
      data-cy="journalFilterCheckbox"
      class="checkbox"
      :class="{
        checked: filters.journalFilter,
        unchecked: !filters.journalFilter
      }"
      @click="toggleFilter"
      @keyup.enter="toggleFilter"
      @keyup.space="toggleFilter"
    >
      J
    </div>
    <div
      tabindex="3"
      id="tasklistFilter"
      data-cy="tasklistFilterCheckbox"
      class="checkbox"
      :class="{
        checked: filters.tasklistFilter,
        unchecked: !filters.tasklistFilter
      }"
      @click="toggleFilter"
      @keyup.enter="toggleFilter"
      @keyup.space="toggleFilter"
    >
      Ta
    </div>
    <div
      tabindex="4"
      id="timeFilter"
      data-cy="timeFilterCheckbox"
      class="checkbox"
      :class="{ checked: filters.timeFilter, unchecked: !filters.timeFilter }"
      @click="toggleFilter"
      @keyup.enter="toggleFilter"
      @keyup.space="toggleFilter"
    >
      Ti
    </div>
    <p
      tabindex="5"
      @click="raiseDate"
      @keyup.enter="raiseDate"
      @keyup.space="raiseDate"
    >
      &#62;
    </p>
  </div>
</template>

<script>
export default {
  emits: ['change-filter', 'lowerDate', 'raiseDate'],
  data() {
    return {
      filters: {
        journalFilter: true,
        tasklistFilter: false,
        timeFilter: false
      }
    }
  },
  methods: {
		checkKey(key) {
			if (key.key === 'ArrowRight') {
				this.raiseDate()
			} else if(key.key === 'ArrowLeft') {
				this.lowerDate()
			}
		},
    lowerDate() {
      this.$emit('lowerDate')
    },
    raiseDate() {
      this.$emit('raiseDate')
    },
    toggleFilter(e) {
      e.preventDefault()
      const inputId = e.target.id
      // setting all filters to false
      for (let filter in this.filters) {
        this.filters[filter] = false
      }
      // setting the chosen filter to true
      const updatedFilters = {
        ...this.filters,
        [inputId]: true
      }
      this.filters = updatedFilters
      this.$emit('change-filter', inputId)
    }
  }
}
</script>

<style scoped>
.filter-container {
  display: flex;
  justify-content: center;
}
.filter-container p {
  margin-right: 5px;
}
.checkbox {
  border-radius: 5px;
  width: 40px;
  height: 40px;
  border: 1px solid black;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-right: 5px;
  user-select: none;
}
.checked {
  box-shadow: inset 1px 1px 1px 1px #676767;
  transition-duration: 0.5s;
}
.checked:focus {
  box-shadow: inset 1px 1px 1px 1px black;
}
.unchecked {
  box-shadow: 1px 1px 1px 1px #676767;
}
.unchecked:focus {
  box-shadow: 1px 1px 1px 1px black;
}
</style>
