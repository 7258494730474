import * as fb from '../../firebase'
import { v4 as uuidv4 } from 'uuid'

const state = {
  timeGroups: []
}

const mutations = {
  setTimeGroups(state, val) {
    state.timeGroups = val
  }
}

const actions = {
  async addTimeTrackerGroup(context, payload) {
    const uid = context.rootState.UserStore.userProfile.uid

    fb.timeTrackerCollection(uid)
      .add(payload)
      .then(() => {
        console.log('Time group added successfully!')
      })
      .catch(err => {
        console.log('Error when adding expense', err)
      })
    context.dispatch('fetchTimeGroups', uid)
  },
  async fetchTimeGroups(context, uid) {
    let timeGroups = []

    const tempArr = await fb.timeTrackerCollection(uid).get()

    tempArr.forEach(group => {
      let groupObject = group.data()
      groupObject.id = group.id
      timeGroups.push(groupObject)
    })

    timeGroups.forEach(timeGroup => {
      // console.log('timeGroup', timeGroup)
      if (timeGroup.stamps) {
        timeGroup.stamps.forEach(stamp => {
          if (!stamp.id) {
            stamp.id = uuidv4()
          }
        })
      }
    })

    // set user profile in state
    context.commit('setTimeGroups', timeGroups)
  },
  async saveTimeGroup(context, payload) {
    const uid = context.rootState.UserStore.userProfile.uid
    await fb.timeTrackerCollection(uid)
      .doc(payload.id)
      .set(payload)
      .then(() => {
        console.log('TimeTracker updated succesfully!')
      })
      .catch(err => {
        console.log('Error saving timetracker-group in db', err)
      })
    context.dispatch('fetchTimeGroups', uid)
  }
}

const getters = {
  getTimeGroups(state) {
    return state.timeGroups
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
