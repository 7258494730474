<template>
	<div class="add-task-form" data-cy="addTaskForm">
		<input
			ref="nameInput"
			data-cy="addTaskNameInput"
			type="text"
			id="task-name"
			v-model="addTaskForm.taskNameInput"
			placeholder="Name"
			@keyup.enter="addTask"
		/>
		<label for="subtasks">Subtasks</label>
		<input
			type="checkbox"
			name="subtasks"
			v-model="addTaskForm.subtasksCheckbox"
			data-cy="subtasksCheckbox"
			@click="toggleSubTasks"
		/>
		<input type="date" id="task-date" v-model="addTaskForm.taskDateInput" />
		<input
			v-if="!editTask"
			type="button"
			class="btn-primary"
			value="Add task"
			data-cy="addTaskButton"
			@click="addTask"
		/>
		<input
			v-if="editTask"
			type="button"
			class="btn-primary"
			value="Save task"
			data-cy="saveTaskButton"
			@click="saveTask"
		/>
		<div v-if="addTaskForm.subtasksCheckbox" class="sub-tasks">
			<SubTaskInputComponent
				v-for="subTask in subTasks"
				:key="subTask.id"
				:id="subTask.id"
				:name="subTask.name"
				@subTaskNameChange="subTaskNameChange"
			></SubTaskInputComponent>
			<img
				@click="addSubTask"
				src="../../assets/tasklist/addTask.svg"
				alt="Add task"
			/>
		</div>
	</div>
</template>

<script>
	import moment from 'moment'

	import SubTaskInputComponent from './SubTaskInputComponent'

	export default {
		emits: ['addTask', 'saveEditTask'],
		props: ['clearForm'],
		data() {
			return {
        task: {},
				editTask: false,
				subTasksCounter: 1,
				subTasks: [
					{
						id: 0
					}
				],
				addTaskForm: {
					taskNameInput: '',
					taskDateInput: '',
					subtasksCheckbox: ''
				}
			}
		},
		methods: {
			subTaskNameChange(payload) {
				let index = this.subTasks.findIndex((task) => task.id === payload.id)
				this.subTasks[index] = payload
			},
			addSubTask() {
				this.subTasks.push({ id: this.subTasksCounter })
				this.subTasksCounter++
			},
      toggleSubTasks() {
				if (this.addTaskForm.subtasksCheckbox) {
					this.subtasksCheckbox = false
					this.subTasks = [0]
					this.subTasksCounter = 1
				}
			},
			addTask() {
				if (!this.addTaskForm.taskNameInput) return
				let date
				if (!this.addTaskForm.taskDateInput) {
					date = moment().format('YYYY-MM-DD')
				} else {
					date = this.addTaskForm.taskDateInput
				}
				let taskO = {
					name: this.addTaskForm.taskNameInput,
					date: date,
					status: 'new'
				}
				if (this.addTaskForm.subtasksCheckbox) taskO.subTasks = this.subTasks
				this.$emit('addTask', taskO)
				this.addTaskForm.taskNameInput = ''
        this.addTaskForm.taskDateInput = ''
        this.addTaskForm.subtasksCheckbox = false
				this.toggleSubTasks()
			},
			saveTask() {
        if (!this.addTaskForm.taskNameInput) return
				let date
				if (!this.addTaskForm.taskDateInput) {
					date = moment().format('YYYY-MM-DD')
				} else {
					date = this.addTaskForm.taskDateInput
				}
				this.task.name = this.addTaskForm.taskNameInput
        this.task.date = date

				if (this.addTaskForm.subtasksCheckbox) this.task.subTasks = this.subTasks

				this.$emit('saveEditTask', this.task)

        this.addTaskForm.taskNameInput = ''
        this.addTaskForm.taskDateInput = ''
        this.addTaskForm.subtasksCheckbox = false
				this.toggleSubTasks()
			}
		},
		mounted() {
			console.log()
			this.$refs.nameInput.focus()

			if (this.editTaskObj) {
        this.task = this.editTaskObj
				this.editTask = true
				this.addTaskForm.taskNameInput = this.editTaskObj.name
				this.taskDateInput = this.editTaskObj.date
				// console.log(this.editTaskObj.subTasks)

				if (!this.editTaskObj.subTasks) return

				this.addTaskForm.subtasksCheckbox = true
				this.subTasks = []
				this.editTaskObj.subTasks.forEach((subTask) => {
					this.subTasks.push(subTask)
					this.subTasksCounter++
				})
			}
		},
    watch: {
      clearForm: function (newVal) {
        if (newVal) {
          this.toggleSubTasks()
          // this.toggleAddTaskForm()
        }
      }
    },
		components: {
			SubTaskInputComponent
		}
	}
</script>

<style scoped>
	.add-task-form {
		border: 1px solid #4e5166;
		background-color: #fffffb;
	}

	.sub-tasks {
		padding: 10px;
		display: grid;
		gap: 10px;
	}
	.sub-tasks img {
		justify-self: center;
	}
</style>
