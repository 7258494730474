<template>
	<div class="time-filter">
		<div
			tabindex="1"
			id="today"
			data-cy="todayFilterCheckbox"
			class="checkbox"
			:class="{
				checked: filterValues.today,
				unchecked: !filterValues.today
			}"
			@click="toggleFilterValues"
			@keyup.enter="toggleFilterValues"
			@keyup.space="toggleFilterValues"
		>
			D
		</div>
		<div
			tabindex="2"
			id="thisWeek"
			data-cy="todayFilterCheckbox"
			class="checkbox"
			:class="{
				checked: filterValues.thisWeek,
				unchecked: !filterValues.thisWeek
			}"
			@click="toggleFilterValues"
			@keyup.enter="toggleFilterValues"
			@keyup.space="toggleFilterValues"
		>
			W
		</div>
		<div
			tabindex="3"
			id="thisMonth"
			data-cy="todayFilterCheckbox"
			class="checkbox"
			:class="{
				checked: filterValues.thisMonth,
				unchecked: !filterValues.thisMonth
			}"
			@click="toggleFilterValues"
			@keyup.enter="toggleFilterValues"
			@keyup.space="toggleFilterValues"
		>
			M
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				filterValues: {
					today: true,
					thisWeek: false,
					thisMonth: false
				}
			}
		},
		methods: {
			toggleFilterValues(e) {
				let id = e.target.id
				// Setting each key value to false
				for (const filter in this.filterValues) {
					// If key value is same as id, and it is already true, set id to empty string
					if (filter === id && this.filterValues[filter]) {
						id = ''
					}
					this.filterValues[filter] = false
				}
				// Setting the pressed value to true, if id is set
				if (id) {
					let tempO = {
						...this.filterValues,
						[id]: true
					}
					this.filterValues = tempO
				}
				this.$emit('change-filter', id)
			}
		}
	}
</script>

<style scoped>
	.time-filter {
		display: flex;
		justify-content: space-around;
		padding: 10px;
	}
	.checkbox {
		margin-right: 10px;
		border-radius: 5px;
		width: 40px;
		height: 40px;
		border: 1px solid black;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		margin-right: 5px;
		user-select: none;
	}
	.checked {
		box-shadow: inset 1px 1px 1px 1px #676767;
		transition-duration: 0.5s;
	}
	.checked:focus {
		box-shadow: inset 1px 1px 1px 1px black;
	}
	.unchecked {
		box-shadow: 1px 1px 1px 1px #676767;
	}
	.unchecked:focus {
		box-shadow: 1px 1px 1px 1px black;
	}
</style>
