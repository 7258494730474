<template>
  <div data-cy="taskgroup-container">
    <h1 data-cy="task-group-heading">
      {{ group.name }}
    </h1>
    <img
      data-cy="expand-options-button"
      class="expand-options-button"
      :class="{ 'expand-options-button-active': displayOptions }"
      src="../../assets/expand.svg"
      alt="Expand Options"
      @click="displayOptions = !displayOptions"
    />
    <div class="options" v-if="displayOptions">
      <div class="option">
        <img
          data-cy="delete-group-button"
          @click="deleteGroup"
          src="../../assets/trash.svg"
          alt="Delete"
        />
      </div>
    </div>
    <add-task-form
      v-if="displayAddTask"
      @addTask="addTask"
      data-cy="addTaskForm-template"
    ></add-task-form>
    <div id="class-container" class="container">
      <div class="task-container">
        <task-component
          data-cy="task"
          v-for="(task, index) in newTasks"
          :key="task.id"
          :task="task"
          :index="index"
          @deleteTask="deleteTask"
          @toggleTaskStatus="toggleTaskStatus"
          @toggleSubTaskStatus="toggleSubTaskStatus"
          @displayTask="displayTask"
        ></task-component>
      </div>
      <div id="done-class-container" class="task-container">
        <h1>Done</h1>
        <task-component
          data-cy="done-task"
          v-for="task in doneTasks"
          :task="task"
          :key="task.id"
          @deleteTask="deleteTask"
          @toggleTaskStatus="toggleTaskStatus"
          @toggleSubTaskStatus="toggleSubTaskStatus"
          @displayTask="displayTask"
        ></task-component>
      </div>
      <img
        data-cy="toggleAddTaskForm"
        @click="toggleAddTaskForm"
        id="add-task-button"
        src="../../assets/tasklist/addTask.svg"
        alt="Add task"
      />
    </div>
    <task-details
      data-cy="task-details"
      v-if="displayDetails"
      :task="updatedTask"
      @closeDetails="closeDetails"
      @toggleTaskStatus="toggleTaskStatus"
      @toggleSubTaskStatus="toggleSubTaskStatus"
      @saveTask="saveTask"
    ></task-details>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

import AddTaskForm from '../../components/tasks/AddTaskForm.vue'
import TaskComponent from '../../components/tasks/TaskComponent.vue'
import TaskDetails from '../../components/tasks/TaskDetails.vue'

export default {
  data() {
    return {
      displayAddTask: false,
      displayOptions: false,
      displayDetails: false,
      taskDetailsObject: null
    }
  },
  methods: {
    closeDetails() {
      this.displayDetails = false
      this.taskDetailsObject = null
    },
    displayTask(task) {
      this.displayDetails = true
      this.taskDetailsObject = task
    },
    toggleAddTaskForm() {
      this.displayAddTask = !this.displayAddTask
    },
    toggleTaskStatus(task) {
      let payload = { ...this.group }
      if (task.status === 'new') {
        task.status = 'done'
      } else if (task.status === 'done') {
        task.status = 'new'
      }
      payload.tasks.forEach((_task, index) => {
        if (_task.id === task.id) {
          payload.tasks[index] = task
        }
      })
      this.$store.dispatch('saveGroup', payload)
      // this.$store.dispatch('changeTaskStatus', task)
    },
    saveTask(task) {
      let payload = { ...this.group }
      payload.tasks.forEach((_task, index) => {
        if (_task.id === task.id) {
          payload.tasks[index] = task
        }
      })
      this.$store.dispatch('saveGroup', payload)
    },
    toggleSubTaskStatus(task) {
      let payload = { ...this.group }
      payload.tasks.forEach((_task, index) => {
        if (_task.id === task.id) {
          payload.tasks[index] = task
        }
      })
      this.$store.dispatch('saveGroup', payload)
    },
    addTask(data) {
      let payload = { ...this.group }
      data.id = uuidv4()
      if (!payload.tasks) {
        payload.tasks = []
      }
      payload.tasks.push(data)
      this.$store.dispatch('saveGroup', payload)
      this.toggleAddTaskForm()
    },
    deleteTask(task) {
      let payload = { ...this.group }
      payload.tasks.forEach((_task, index) => {
        if (_task.id === task.id) {
          payload.tasks.splice(index, 1)
        }
      })
      this.$store.dispatch('saveGroup', payload)
    },
    deleteGroup() {
      this.$store.dispatch('deleteTask', this.group)
      this.$router.push('/taskgroups')
    }
  },
  computed: {
    updatedTask() {
      let allTasks = [...this.group.tasks]
      let task = null
      allTasks.forEach(_task => {
        if (_task.id === this.taskDetailsObject.id) {
          task = _task
        }
      })
      return task
    },
    newTasks() {
      let tasks = this.group.tasks
      if (this.group.tasks) {
        tasks = tasks.filter(task => task.status === 'new')
      }
      return tasks
    },
    doneTasks() {
      let tasks = this.group.tasks
      if (this.group.tasks) {
        tasks = tasks.filter(task => task.status === 'done')
      }
      return tasks
    },
    group() {
      let groups = [...this.$store.getters.returnAllGroups]
      let id = this.$route.params.id
      let group = {
        name: 'Loading'
      }
      groups.forEach(_group => {
        if (_group.id === id) {
          group = _group
        }
      })
      return group
    }
  },
  components: {
    AddTaskForm,
    TaskComponent,
    TaskDetails
  }
}
</script>

<style scoped>
.task-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: 5px;
}
.expand-options-button {
  grid-row: 1;
  position: fixed;
  right: 30px;
  top: 30px;
  transition: 0.5s;
}
.expand-options-button-active {
  rotate: 90deg;
  transition: 0.5s;
}
.options {
  position: fixed;
  right: 30px;
  z-index: 2;
  transition: 1s;
}
.option {
  background-color: white;
  height: 50px;
  border: 1px solid black;
  border-radius: 5px;
  opacity: 100%;
  transition: opacity 2s;
}

#add-task-button {
  box-shadow: 1px 1px 1px 1px #4e5166;
  position: fixed;
  background-color: #fffffb;
  bottom: 10px;
}

@media screen and (min-width: 600px) {
  .task-container {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media screen and (min-width: 850px) {
  .task-container {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media screen and (min-width: 1100px) {
  .task-container {
    padding-left: 200px;
    padding-right: 200px;
  }
}
@media screen and (min-width: 1400px) {
  .task-container {
    padding-left: 500px;
    padding-right: 500px;
  }
}
</style>
