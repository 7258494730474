<template>
	<div @click="toggleStatus" class="shopping-item" data-cy="shopping-item-name">
		{{ breakItem }}
	</div>
</template>

<script>
	export default {
		emits: ['toggleItemStatus'],
		props: ['item'],
		methods: {
			toggleStatus() {
				this.$emit('toggleItemStatus', this.item)
			}
		},
		computed: {
			breakItem() {
				if (this.item.name.length >= 7) {
					return (
						this.item.name.substring(0, 8) +
						'- ' +
						this.item.name.substring(8, this.item.name.length)
					)
				} else {
					return this.item.name
				}
			}
		}
	}
</script>

<style scoped>
.shopping-item {
		background-color: #464F51;
		color: #fff;
		width: 70px;
		height: 70px;
		border: 1px solid black;
		box-shadow: 1px 1px 1px 1px #676767;
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-radius: 50%;
	}
</style>
