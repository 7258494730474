<template>
  <div>
    <h1>{{ favouriteTeam.name }}</h1>
    <choose-team
      v-if="displayChooseTeam"
      @chooseTeam="chooseTeam"
    ></choose-team>
    <PreviousAndNextGame
      v-if="favouriteTeamExists"
      :next="favouriteTeam.nextGameSchedule.dates[0].games[0].gamePk"
      :previous="favouriteTeam.previousGameSchedule.dates[0].games[0].gamePk"
      @playVideo="showVideoModal"
    />
    <video-modal
      :gameId="videoGame"
      v-if="displayVideoModal"
      @closeVideoModal="closeVideoModal"
    ></video-modal>
    <game-list
      v-if="displayLastNightsGames"
      :date="date"
      @closeGameList="closeGameList"
      @watchGame="showVideoModal"
    ></game-list>
    <div v-if="favouriteTeamExists" class="buttons-container">
      <div class="btn btn-primary" @click="showLastNightsGames">
        Last nights games
      </div>
    </div>
  </div>
</template>

<script>
import ChooseTeam from '@/components/nhl/ChooseTeam.vue'
import GameList from '@/components/nhl/GameList.vue'
import PreviousAndNextGame from '@/components/nhl/PreviousAndNextGame.vue'
import VideoModal from '@/components/nhl/VideoModal.vue'

import moment from 'moment'

export default {
  data() {
    return {
      displayVideoModal: false,
      displayLastNightsGames: false,
      date: moment().format('YYYY-MM-DD'),
      videoGame: null
    }
  },
  methods: {
    closeVideoModal() {
      this.displayVideoModal = false
    },
    showVideoModal(gameId) {
      this.videoGame = gameId
      this.displayVideoModal = true
      this.markGameAsWatched(gameId)
    },
    markGameAsWatched(gameId) {
      this.$store.dispatch('markGameAsWatched', gameId)
    },
    chooseTeam(team) {
      this.$store.dispatch('markTeamAsFavourite', team)
    },
    showLastNightsGames() {
      this.displayLastNightsGames = true
    },
    closeGameList() {
      this.displayLastNightsGames = false
    }
  },
  computed: {
    favouriteTeamExists() {
      let isEmpty =
        Object.keys(this.$store.getters.getFavouriteTeam).length === 0 &&
        this.$store.getters.getFavouriteTeam.constructor === Object

      if (isEmpty) {
        return false
      } else {
        return true
      }
    },
    favouriteTeam() {
      return this.$store.getters.getFavouriteTeam
    },
    displayChooseTeam() {
      if (this.favouriteTeamExists) {
        return false
      } else {
        return true
      }
    }
  },
  beforeMount() {
    this.$store.dispatch('fetchAllTeams')
  },
  components: {
    ChooseTeam,
    PreviousAndNextGame,
    VideoModal,
    GameList
  }
}
</script>

<style scoped>
.buttons-container {
  padding: 20px;
}
.btn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
</style>
