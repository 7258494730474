<template>
  <div class="app-container" @keydown="checkKey" @click="resetTimeoutTimer">
    <cli-component
      v-if="signedIn && displayCli && !displayTimeoutComponent"
      @closeCli="closeCli"
    ></cli-component>
    <SideBar v-if="signedIn && !displayTimeoutComponent"></SideBar>
    <TimeoutModal v-if="displayTimeoutComponent" @signIn="removeTimeout" />
    <router-view v-if="!displayTimeoutComponent" />
  </div>
</template>

<script>
import SideBar from './components/SideBar'
import TimeoutModal from './components/TimeoutModal.vue'
import CliComponent from './components/cli/CliComponent.vue'

export default {
  data() {
    return {
      displayCli: false,
      key: null,
      interval: null,
      displayTimeoutComponent: false
    }
  },
  methods: {
    startTimeoutTimer() {
      this.interval = setInterval(() => {
        this.displayTimeoutComponent = true
        clearInterval(this.interval)
      }, 300000)
    },
    resetTimeoutTimer() {
      clearInterval(this.interval)
      this.startTimeoutTimer()
    },
    removeTimeout() {
      this.displayTimeoutComponent = false
      clearInterval(this.interval)
      this.startTimeoutTimer()
    },
    checkKey(e) {
      this.resetTimeoutTimer()
      if (e.keyCode === 16) {
        this.key = 'Shift'
      } else if (e.keyCode === 190 && this.key === 'Shift') {
        this.displayCli = true
      } else {
        this.key = null
      }
    },
    closeCli() {
      this.displayCli = false
    }
  },
  computed: {
    signedIn() {
      return !!this.$store.getters.getUser.uid
    }
  },
  mounted() {
    this.startTimeoutTimer()
  },
  components: {
    SideBar,
    CliComponent,
    TimeoutModal
  }
}
</script>

<style>
body {
  background-color: #dce1e3;
  margin: 0;
}

input:focus {
  background-color: unset;
  box-shadow: 0 0 3pt 2pt black;
  border: 1px solid black;
  border-radius: 4px;
  z-index: 99;
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-column-gap: 0px;
  grid-row-gap: 1px;
  padding: 10px;
}

.btn {
  border-radius: 5px;
}

input[type='email'],
input[type='password'],
input[type='text'],
input[type='number'],
input[type='date'] {
  background-color: #dce1e3;
  color: black;
  font-size: 20px;
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid black;
  border-top: none;
  border-right: none;
  border-left: none;
  margin-top: 5px;
  height: 40px;
  outline: none;
}

.btn-primary {
  margin-top: 20px;
  margin-bottom: 5px;
  background-color: #40423d;
  color: white;
  font-size: 18px;
  border-radius: 4px;
}

#app {
  font-family: Arvo, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}
.app-container {
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.items-container {
	background-color: #7c7c7c;
  border: 1px solid black;
  border-radius: 10px;
  overflow: auto;
  padding: 20px;
  display: grid;
  gap: 20px;
  grid-auto-rows: max-content;
	max-height: 20em;
}
.storage-item {
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
</style>
