<template>
  <div class="video-modal-container">
    <div class="modal-header">
      <img
        id="close-icon"
        src="../../assets/close.svg"
        alt="show side-bar"
        @click="closeVideoModal"
      />
      <div>
        <p>{{ awayTeam }}</p>
        <p>@</p>
        <p>{{ homeTeam }}</p>
      </div>
    </div>
    <video :src="videosrc" controls></video>
  </div>
</template>

<script>
export default {
  emits: ['closeVideoModal'],
  props: ['gameId'],
  data() {
    return {
      videosrc: null,
      homeTeam: '',
      awayTeam: ''
    }
  },
  methods: {
    closeVideoModal() {
      this.$emit('closeVideoModal')
    },
    async fetchGameContent() {
      const media = await fetch(
        `http://statsapi.web.nhl.com/api/v1/game/${this.gameId}/content`
      )
        .then((res) => res.json())
        .then((res) => res)

      this.videosrc = media.media.epg[3].items[0].playbacks[3].url
    },
    async fetchGameTeams() {
      const allTeams = this.$store.getters.getAllTeams
      const game = await fetch(
        `http://statsapi.web.nhl.com/api/v1/game/${this.gameId}/linescore`
      )
        .then((res) => res.json())
        .then((res) => res)

      console.log('game', game)
      let homeName = game.teams.home.team.name
      let awayName = game.teams.away.team.name
      let homeId = game.teams.home.team.id
      let awayId = game.teams.away.team.id
      console.log('homeId', homeId)
      console.log('awayId', awayId)
      this.homeTeam = allTeams.find(
        (team) => team.id === homeId && team.name === homeName
      ).abbreviation
      this.awayTeam = allTeams.find(
        (team) => team.id === awayId && team.name === awayName
      ).abbreviation
    }
  },
  mounted() {
    this.fetchGameContent()
    this.fetchGameTeams()
  }
}
</script>

<style scoped>
.video-modal-container {
  position: absolute;
  z-index: 2;
  background-color: #dce1e3;
  inset: 0;
}
.modal-header {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 10px;
  padding: 20px;
}
.modal-header img {
  grid-row: 1;
  grid-column: 1;
}
.modal-header div {
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  justify-self: center;
  display: flex;
  align-content: space-around;
}
.modal-header div p {
  margin-left: 5px;
}
video {
  max-width: 90%;
}
</style>
