import * as fb from '../../firebase'

const state = {
	weeklyGoals: {}
}

const mutations = {
	setWeeklyGoals(state, val) {
		state.weeklyGoals = val
	}
}

const actions = {
	async addWeeklyGoal(context, payload) {
		const uid = context.rootState.UserStore.userProfile.uid
		fb.weeklyGoalsCollection(uid).add(payload)

		context.dispatch('fetchWeeklyGoals', uid)
	},
	async fetchWeeklyGoals(context, uid) {
		let goals = []
		const allGoals = await fb.weeklyGoalsCollection(uid).get()
		allGoals.forEach((goal) => {
			let goalObject = goal.data()
			goalObject.id = goal.id
			goals.push(goalObject)
		})
		// set user profile in state
		context.commit('setWeeklyGoals', goals)
	},
	async deleteWeeklyGoalFromDb(context, id) {
		const uid = context.rootState.UserStore.userProfile.uid

		fb.weeklyGoalsCollection(uid)
			.doc(id)
			.delete()
			.then(() => {
				console.log('Goal removed from database!')
			}).catch(err => {
        console.log('Error when deleting goal from db', err)
      })
		context.dispatch('fetchWeeklyGoals', uid)
	}
}

const getters = {
	getWeeklyGoals(state) {
		return state.weeklyGoals
	}
}

export default {
	state,
	mutations,
	actions,
	getters
}
