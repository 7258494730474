import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

// firebase init
const firebaseConfig = {
  apiKey: 'AIzaSyBoJH75vslpYJLWi4IJikHC53R7nPehmQ4',
  authDomain: 'health-a6022.firebaseapp.com',
  databaseURL: 'https://health-a6022.firebaseio.com',
  projectId: 'health-a6022',
  storageBucket: 'health-a6022.appspot.com',
  messagingSenderId: '985473366452',
  appId: '1:985473366452:web:3c0af57e74f85a75cbdae1'
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()

// collection references
const usersCollection = db.collection('users')
const healthCollection = uid => usersCollection.doc(uid).collection('health')
const shoppingListCollection = uid => usersCollection.doc(uid).collection('shoppinglist')
const taskListCollection = uid => usersCollection.doc(uid).collection('tasks')
const dailyJournalCollection = uid => usersCollection.doc(uid).collection('health').doc('journal').collection('daily')
const randomJournalCollection = uid => usersCollection.doc(uid).collection('health').doc('journal').collection('random')
const weeklyGoalsCollection = uid => usersCollection.doc(uid).collection('weeklygoals')
const monthlyBudgetCollection = (uid, year, month) => usersCollection.doc(uid).collection('budget').doc(year).collection(month)
const stampCollection = (uid) => usersCollection.doc(uid).collection('stamp')
const timeTrackerCollection = (uid) => usersCollection.doc(uid).collection('timetracker')
const storageCollection = (uid) => usersCollection.doc(uid).collection('storage')
const nhlCollection = (uid) => usersCollection.doc(uid).collection('nhl')

export {
  db,
  auth,
  dailyJournalCollection,
  randomJournalCollection,
  usersCollection,
  healthCollection,
  shoppingListCollection,
  taskListCollection,
  weeklyGoalsCollection,
  monthlyBudgetCollection,
  stampCollection,
  storageCollection,
  timeTrackerCollection,
  nhlCollection
}
