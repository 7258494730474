<template>
  <div
    class="day"
    @click="openDay"
    @keyup.enter="openDay"
    @keyup.space="openDay"
  >
    {{ date }}
  </div>
</template>

<script>
// import moment from 'moment'

export default {
  emits: ['openDay'],
  props: ['date'],
  methods: {
    openDay() {
      this.$emit('openDay', this.date)
    }
  }
}
</script>

<style scoped>
.day {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #fcf7f8;
  box-shadow: 0px 4px 4px 1px #727272;
  user-select: none;
}

.day:focus {
  box-shadow: inset 0px 4px 4px 1px #727272;
}
.day:hover {
  box-shadow: inset 0px 4px 4px 1px #727272;
}
</style>
