import * as fb from '../../firebase'

const state = {
  teams: [],
  favouriteTeam: {},
  watchedGames: []
}

const mutations = {
  setAllTeams(state, val) {
    state.teams = val
  },
  setFavouriteTeam(state, val) {
    state.favouriteTeam = val
  },
  setWatchedGames(state, val) {
    state.watchedGames = val
  }
}

const actions = {
  // async addTimeTrackerGroup(context, payload) {
  // 	const uid = context.rootState.UserStore.userProfile.uid

  // 	fb.timeTrackerCollection(uid)
  // 		.add(payload)
  // 		.then(() => {
  // 			console.log('Time group added successfully!')
  // 		})
  // 		.catch((err) => {
  // 			console.log('Error when adding expense', err)
  // 		})
  // 	context.dispatch('fetchTimeGroups', uid)
  // },
  async fetchAllTeams(context) {
    let nhlTeams = []

    const tempArr = await fetch(
      'https://statsapi.web.nhl.com/api/v1/teams?expand=team.schedule.next&expand=team.schedule.previous'
    )
      .then((res) => res.json())
      .then((res) => res.teams)

    tempArr.forEach((group) => {
      nhlTeams.push(group)
    })

    // set user profile in state
    context.commit('setAllTeams', nhlTeams)
  },
  async markTeamAsFavourite(context, payload) {
    const uid = context.rootState.UserStore.userProfile.uid

    await fb
      .nhlCollection(uid)
      .doc('favouriteTeam')
      .set(payload)
    context.commit('setFavouriteTeam', payload)
  },
  async fetchFavouriteTeam(context, uid) {
    let team = await fb
      .nhlCollection(uid)
      .doc('favouriteTeam')
      .get()
      .then((doc) => {
        return doc.data()
      })
    console.log('team', team)
    team = await fetch(
      `https://statsapi.web.nhl.com/api/v1/teams/${team.id}?expand=team.schedule.next&expand=team.schedule.previous`
    )
      .then((res) => res.json())
      .then((res) => res.teams[0])
    context.commit('setFavouriteTeam', team)
  },
  async markGameAsWatched(context, payload) {
    const uid = context.rootState.UserStore.userProfile.uid
    let double = false
    let watchedGames = context.state.watchedGames
    if (watchedGames) {
      watchedGames.forEach((game) => {
        if (game === payload) {
          double = true
        }
      })
    }
    if (double) return
    let game
    if (watchedGames) {
      game = {
        games: [payload, ...context.state.watchedGames]
      }
    } else {
      game = {
        games: [payload]
      }
    }

    await fb
      .nhlCollection(uid)
      .doc('watchedGames')
      .set(game)
    context.dispatch('fetchWatchedGames', uid)
  },
  async fetchWatchedGames(context, uid) {
    let games = await fb
      .nhlCollection(uid)
      .doc('watchedGames')
      .get()
      .then((res) => res.data())

    if (!games) games = []
    context.commit('setWatchedGames', games.games)
  }
  // async saveTimeGroup(context, payload) {
  //   const uid = context.rootState.UserStore.userProfile.uid
  //   await fb.timeTrackerCollection(uid)
  //     .doc(payload.id)
  //     .set(payload)
  //     .then(() => {
  //       console.log('TimeTracker updated succesfully!')
  //     })
  //     .catch(err => {
  //       console.log('Error saving timetracker-group in db', err)
  //     })
  //   context.dispatch('fetchTimeGroups', uid)
  // }
}

const getters = {
  getAllTeams(state) {
    return state.teams
  },
  getFavouriteTeam(state) {
    return state.favouriteTeam
  },
  getAllWatchedGames(state) {
    return state.watchedGames
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
