<template>
  <div class="game-list-container">
    <div class="header">
      <img
        id="close-icon"
        src="../../assets/close.svg"
        alt="show side-bar"
        @click="goBack"
      />
      <h1>{{ yesterdayDate }}</h1>
    </div>
    <div class="games-list">
      <game-item
        v-for="game in games"
        :key="game.gamePk"
        :game="game"
        @watchGame="watchGame"
      ></game-item>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import GameItem from './GameItem.vue'

export default {
  emits: ['closeGameList', 'watchGame'],
  props: ['date'],
  data() {
    return {
      yesterdayDate: null,
      games: []
    }
  },
  methods: {
    watchGame(gameId) {
      this.$emit('watchGame', gameId)
    },
    goBack() {
      this.$emit('closeGameList')
    },
    async fetchAndReturnGame(gameId) {
      let game = await fetch(
        `https://statsapi.web.nhl.com/api/v1/game/${gameId}/linescore`
      )
        .then((res) => res.json())
        .then((res) => res)
      return game
    },
    async fetchAndDisplayGames() {
      let games = await fetch(
        `https://statsapi.web.nhl.com/api/v1/schedule?date=${this.yesterdayDate}`
      )
        .then((res) => res.json())
        .then((res) => res.dates[0].games)
      await games.forEach(async (game) => {
        let gamePk = game.gamePk
        game = await this.fetchAndReturnGame(gamePk)
        game.gamePk = gamePk
        this.games.push(game)
      })
    }
  },
  mounted() {
    this.yesterdayDate = moment(this.date)
      .subtract(1, 'days')
      .format('YYYY-MM-DD')
    this.fetchAndDisplayGames()
  },
  components: {
    GameItem
  }
}
</script>

<style scoped>
.game-list-container {
  position: absolute;
  z-index: 1;
  background-color: #dce1e3;
  inset: 0;
}

.header {
  display: grid;
  padding: 0 20px;
}
.header img {
  grid-row: 1;
  grid-column: 1;
  align-self: center;
}
.header h1 {
  align-self: center;
  justify-self: center;
  grid-row: 1;
  grid-column: 1;
}
.games-list {
  display: grid;
  gap: 20px;
  padding: 20px;
}
</style>
