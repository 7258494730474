<template>
  <div class="item-container">
    <header>
      <img
        class="close-button"
        src="@/assets/close.svg"
        alt="Close"
        @click="closeItem"
      />
      <h1>{{ name }}</h1>
      <img
        class="options-button"
        src="@/assets/expand.svg"
        alt="Options"
        @click="toggleDisplayOptions"
      />
      <div class="options" v-if="displayOptions">
        <img src="@/assets/edit.svg" alt="Edit" @click="editItem" />
        <img src="@/assets/trash.svg" alt="Delete" @click="deleteItem" />
      </div>
    </header>
    <!-- <main> -->
    <div class="body">
      <p>Height</p>
      <p>{{ height }}</p>
      <p>Width</p>
      <p>{{ width }}</p>
      <p>Depth</p>
      <p>{{ depth }}</p>
      <p>Container</p>
      <select v-model="container" @change="changeContainer">
        <option value="false">No</option>
        <option value="true">Yes</option>
      </select>
      <p>Parent</p>
      <input
        type="button"
        :value="parentName"
        class="btn"
        @click="showChangeParent"
      />
    </div>
    <div class="parent-trail">
      <parent-trail
        v-if="parent"
        :parentId="parent"
        :allItems="allItems"
        :itemName="name"
      ></parent-trail>
    </div>
    <div class="child-items-container" v-if="isContainer">
      <p class="contains-paragraph">Contains</p>
      <div class="items-container">
        <item-component
          v-for="item in childItems"
          :key="item.id"
          :item="item"
          @openItem="openItem"
        ></item-component>
        <div class="storage-item" v-if="childItems.length < 1">No items</div>
      </div>
    </div>
    <button v-if="isContainer" class="btn-primary" @click="addItem">
      Add Item
    </button>
    <!-- </main> -->
    <change-parent
      v-if="displayChangeParent"
      :allItems="allItems"
      :itemId="itemId"
      @closeChangeParent="closeChangeParent"
      @changeParent="changeParent"
    ></change-parent>
  </div>
</template>

<script>
import ItemComponent from '@/components/storage/ItemComponent.vue'
import ParentTrail from '@/components/storage/ParentTrail.vue'
import ChangeParent from '@/components/storage/ChangeParent.vue'

export default {
  data() {
    return {
      displayOptions: false,
      displayChangeParent: false,
      container: false
    }
  },
  methods: {
    showChangeParent() {
      this.displayChangeParent = true
    },
    closeChangeParent() {
      this.displayChangeParent = false
    },
    toggleDisplayOptions() {
      this.displayOptions = !this.displayOptions
    },
    closeItem() {
      let parent
      if (this.item) parent = this.item.parent
      if (this.path) {
        this.$router.push(this.path)
      } else if (this.parentExists(parent)) {
        this.$router.push('/item/' + this.item.parent)
      } else {
        this.$router.push('/storage')
      }
    },
    parentExists(parent) {
      let index = this.allItems.findIndex((item) => item.id === parent)
      if (index === -1) {
        return false
      } else {
        return true
      }
    },
    changeContainer() {
      let payload = this.item
      payload.container = this.container
      this.$store.dispatch('saveItem', payload)
    },
    setContainer() {
      this.container = this.item.container
    },
    addItem() {
      this.$router.push({
        name: 'AddItem',
        params: { path: this.$route.path, parentItem: this.item.id }
      })
    },
    changeParent(parentId) {
      let payload = this.item
      payload.parent = parentId
      this.$store.dispatch('saveItem', payload)
    },
    editItem() {
      console.log(this.item.id)
      this.$router.push({
        name: 'AddItem',
        params: { editItem: this.item.id, path: this.$route.path }
      })
    },
    deleteItem() {
      this.$store.dispatch('deleteItem', this.item.id)
      this.toggleDisplayOptions()
      this.closeItem()
    },
    openItem(id) {
      this.$router.push({
        name: 'Item',
        params: { id }
      })
    }
  },
  computed: {
    allItems() {
      return this.$store.getters.getAllItems
    },
    item() {
      return this.allItems.find((item) => item.id === this.$route.params.id)
    },
    name() {
      if (!this.item) return ''
      return this.item.name
    },
    height() {
      if (!this.item) return 0
      if (this.item.height) return this.item.height
      return 0
    },
    width() {
      if (!this.item) return 0
      if (this.item.width) return this.item.width
      return 0
    },
    depth() {
      if (!this.item) return 0
      if (this.item.depth) return this.item.depth
      return 0
    },
    itemId() {
      if (!this.item) return null
      if (this.item.id) return this.item.id
      return null
    },
    childItems() {
      let childItems = this.allItems.filter(
        (item) => item.parent === this.item.id
      )
      childItems = childItems.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
      return childItems
    },
    parent() {
      if (!this.item) return null
      if (this.item.parent) return this.item.parent
      return null
    },
    parentName() {
      let parent = this.allItems.find((item) => item.id === this.item.parent)
      if (!parent) return 'None'
      return parent.name
    },
    isContainer() {
      if (!this.item) return false
      if (this.item.container === true || this.item.container === 'true') {
        return true
      } else {
        return false
      }
    }
  },
  mounted() {
    if (this.item) {
      this.setContainer()
    }
  },
  watch: {
    item: function(newVal) {
      if (newVal) {
        this.setContainer()
      }
    }
  },
  components: {
    ItemComponent,
    ParentTrail,
    ChangeParent
  }
}
</script>

<style scoped>
.item-container {
  position: relative;
  background-color: #dce1e3;
  z-index: 3;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
	/* overflow: hidden; */
}
header {
  display: grid;
  padding-top: 20px;
  padding-bottom: 20px;
}
.close-button {
  grid-row: 1;
  grid-column: 1;
  align-self: center;
}
header h1 {
  grid-row: 1;
  grid-column: 1;
  margin: 0;
  justify-self: center;
}
.options-button {
  grid-row: 1;
  grid-column: 1;
  justify-self: end;
  align-self: center;
  transform: rotate(90deg);
}
.options {
	background-color: white;
	border: 1px solid black;
	border-radius: 5px;
	padding: 5px;
  position: absolute;
  right: 15px;
  display: grid;
  top: 80px;
}

.parent-trail {
  padding: 10px;
}

.body {
	background-color: #E8E8E8;
	color: black;
  display: grid;
  grid-template-columns: .5fr .9fr 0.2fr 1fr;
  justify-items: start;
  padding: 10px;
  border: 1px solid black;
}
.contains-paragraph {
	color: black;
	text-decoration: underline;
	font-size: 1.3rem;
}
.btn {
	height: 40px;
	align-self: center;
}
select {
	align-self: center;
}
</style>
