<template>
	<div class="storage-container">
		<header>
			<h1>Storage</h1>
			<img
				class="search-image"
				src="../assets/search.png"
				alt="Search"
				@click="search"
			/>
		</header>

		<div class="items-container">
			<item-component
				v-for="item in baseItems"
				:key="item.id"
				:item="item"
				@openItem="openItem"
			></item-component>
      <div class="storage-item" v-if="baseItems.length < 1">No items</div>
		</div>
		<button @click="addItem">Add Item</button>
	</div>
</template>

<script>
	import ItemComponent from '@/components/storage/ItemComponent.vue'
	export default {
		methods: {
			addItem() {
				this.$router.push('/additem')
			},
			openItem(id) {
				this.$router.push('/item/' + id)
			},
			search() {
				this.$router.push('/searchStorage')
			}
		},
		computed: {
			baseItems() {
				const allItems = [...this.$store.getters.getAllItems]
				let baseItems = allItems.filter((item) => !item.parent)
				baseItems = baseItems.sort((a, b) => {
					if (a.name < b.name) {
						return -1
					}
					if (a.name > b.name) {
						return 1
					}
					return 0
				})
				return baseItems
			}
		},
		components: {
			ItemComponent
		}
	}
</script>

<style scoped>
	.storage-container {
		display: grid;
		grid-template-rows: 0.1fr .6fr 0.1fr;
		height: 100%;
		overflow: hidden;
		padding-left: 20px;
		padding-right: 20px;
	}

	header {
		display: grid;
		padding: 20px;
	}
	header h1 {
		grid-column: 1;
		grid-row: 1;
		margin: 0;
	}
	.search-image {
		grid-column: 1;
		grid-row: 1;
		justify-self: end;
		height: 40px;
	}
</style>
