<template>
	<div class="body">
		<stamp-timer-form
			:stamp="returnActiveStamp"
			@stampIn="stampIn"
			@stampOut="stampOut"
		></stamp-timer-form>

    <div class="posts">
      <post-component
        v-for="post in formattedPostedTime"
        :key="post.id"
        :post="post"
        @deletePost="deletePost"
      ></post-component>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import StampTimerForm from '../components/stampclock/StampTimerForm'
import PostComponent from '../components/stampclock/PostComponent'

export default {
  methods: {
    deletePost(id) {
      this.$store.dispatch('deleteStampPostFromDb', id)
    },
    stampIn(postObject) {
      this.$store.dispatch('addStampPost', postObject)
    },
    stampOut(postObject) {
      this.$store.dispatch('editPost', postObject)
    }
  },
  beforeMount() {
    this.$store.dispatch('fetchAllStamps')
  },
  computed: {
    ...mapGetters(['getAllStamps']),
    returnActiveStamp() {
      let allStamps = this.getAllStamps
      let index = allStamps.findIndex(stamp => !stamp.ended)
      if (index === -1) {
        return false
      } else {
        return allStamps[index]
      }
    },
    formattedPostedTime() {
      console.log('this.getAllStamps', this.getAllStamps)
      if (this.getAllStamps.length < 1) {
        return null
      } else {
        const tempArr = [...this.getAllStamps]
        tempArr.forEach((post, index) => {
          if (!post.ended) {
            tempArr.splice(index, 1)
          }
        })
        return tempArr
      }
    }
  },
  components: {
    StampTimerForm,
    PostComponent
  }
}
</script>

<style scoped>
.posts {
  padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (min-width: 700px) {
  .posts {
    padding-left: 100px;
    padding-right: 100px;
  }
}
</style>
