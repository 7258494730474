import * as fb from '../../firebase'
// import router from '../../router/index'

const state = {
  tasks: [],
  groups: []
}

const mutations = {
  setTasks(state, val) {
    state.tasks = val
  },
  setTaskGroups(state, val) {
    state.groups = val
  }
}

const actions = {
  async addGroupToDb(context, payload) {
    const uid = await context.rootState.UserStore.userProfile.uid
    await fb
      .taskListCollection(uid)
      .add(payload)
      .catch(err => {
        console.log(err)
      })
    context.dispatch('fetchAllGroups', uid)
  },
  async saveGroup(context, payload) {
    const uid = await context.rootState.UserStore.userProfile.uid
    await fb
      .taskListCollection(uid)
      .doc(payload.id)
      .set(payload)
      .then(() => {
        console.log('Task-item successfully saved!')
      })
      .catch(err => {
        console.log('Error when changing shopping item', err)
      })
    context.dispatch('fetchAllGroups', uid)
  },
  async fetchAllGroups(context, uid) {
    const dbTasks = await fb.taskListCollection(uid).get()
    let taskGroups = []
    dbTasks.forEach(item => {
      let taskObject = item.data()
      taskObject.id = item.id
      if (taskObject.container) {
        taskGroups.push(taskObject)
      }
    })
    context.commit('setTaskGroups', taskGroups)
  },
  async addTaskToDb(context, payload) {
    const uid = await context.rootState.UserStore.userProfile.uid
    await fb
      .taskListCollection(uid)
      .add(payload)
      .catch(err => {
        console.log(err)
      })
    context.dispatch('fetchAllTasks', uid)
  },
  async changeTaskStatus(context, payload) {
    const uid = await context.rootState.UserStore.userProfile.uid
    await fb
      .taskListCollection(uid)
      .doc(payload.id)
      .set(payload)
      .then(() => {
        console.log('Task-item successfully saved!')
      })
      .catch(err => {
        console.log('Error when changing shopping item', err)
      })
  },
  async deleteTask(context, payload) {
    const uid = await context.rootState.UserStore.userProfile.uid

    await fb
      .taskListCollection(uid)
      .doc(payload.id)
      .delete()
      .then(() => {
        console.log('Task removed')
      })
      .catch(err => {
        console.log('Error when deleting task', err)
      })
    context.dispatch('fetchAllTasks', uid)
		context.dispatch('fetchAllGroups', uid)
  },
  async fetchAllTasks(context, uid) {
    const tasks = []
    const dbTasks = await fb.taskListCollection(uid).get()
    dbTasks.forEach(item => {
      let taskObject = item.data()
      taskObject.id = item.id
      if (taskObject.status) {
        tasks.push(taskObject)
      }
    })
    context.commit('setTasks', tasks)
  }
}

const getters = {
  returnAllTasks(state) {
    return state.tasks
  },
  returnAllGroups(state) {
    return state.groups
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
