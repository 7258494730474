<template>
	<div class="add-item-container">
    <header>
      <img src="@/assets/close.svg" alt="Close" @click="closeAddItem">
		<h1 v-if="!$route.params.editItem">Add Item</h1>
		<h1 v-if="$route.params.editItem">Edit Item</h1>
    </header>
		<form class="form" @submit.prevent="addItem">
			<input type="text" v-model="name" placeholder="Name" />
			<div>
				<input type="checkbox" id="container" v-model="container" />
				<label for="container">Container</label>
			</div>
			<input type="text" v-model="width" placeholder="Width" />
			<input type="text" v-model="height" placeholder="Height" />
			<input type="text" v-model="depth" placeholder="Depth" />
			<input type="text" v-model="weight" placeholder="Weight" />
      <input v-if="!$route.params.editItem" type="submit" class="btn-primary" value="Add">
      <input v-if="$route.params.editItem" type="submit" class="btn-primary" value="Save">
		</form>
	</div>
</template>

<script>
	export default {
    props: ['path', 'parentItem', 'editItem'],
    data() {
      return {
        name: '',
        container: false,
        width: '',
        height: '',
        depth: '',
        weight: '',
        parent: this.parentItem ? this.parentItem : null,
        id: null
      }
    },
    methods: {
      addItem() {
        if (!this.name) return
        const payload = {
          name: this.name,
          container: this.container,
          width: this.width,
          height: this.height,
          depth: this.depth,
          weight: this.weight,
          parent: this.parent,
        }
        if (this.editItem) {
          payload.id = this.id
          this.$store.dispatch('saveItem', payload)
        } else {
          this.$store.dispatch('addItem', payload)
        }
        this.closeAddItem()
      },
      closeAddItem() {
        if (this.path) {
          this.$router.push(this.path)
        } else {
          this.$router.push('/storage')
        }
      }
    },
    computed: {
      allItems() {
        return this.$store.getters.getAllItems
      }
    },
    mounted() {
      if (this.$route.params.editItem) {
        const item = this.allItems.find(item => item.id === this.$route.params.editItem)
        this.name = item.name
        this.container = item.container
        this.width = item.width
        this.height = item.height
        this.depth = item.depth
        this.weight = item.weight
        this.parent = item.parent
        this.id = item.id
      }
    }
  }
</script>

<style scoped>
	.add-item-container {
		display: grid;
    position: relative;
    z-index: 2;
    padding-left: 20px;
    padding-right: 20px;
		height: 100%;
    background-color: #dce1e3;
	}
  header {
    display: grid;
    padding-top: 20px;
  }
  header img {
    grid-row: 1;
    grid-column: 1;
  }
  header h1 {
    grid-row: 1;
    grid-column: 1;
    margin: 0;
    justify-self: center;
  }
  .form {
    display: flex;
    flex-direction: column;
  }
  .form input {
    margin-bottom: 5px;
  }
  .form input[type="submit"] {
    height: 40px;
  }
  .form input[type="checkbox"] {
    transform: scale(1.5);
    margin-right: 10px;
  }
</style>
