<template>
	<div>
		<p>Change quotes api key</p>
		<input type="text" placeholder="Api key" v-model="apiKeyInput" />
		<input
			type="button"
			value="Change"
			class="btn-primary"
			@click="changeApiKey"
		/>
	</div>
	<div>
		<p>Add or change pin code!</p>
		<input
			@keyup.enter="setPinCode"
			type="number"
			v-model="pin"
			placeholder="Pin"
		/>
		<input
			type="button"
			@click="setPinCode"
			class="btn-primary"
			value="Change pin"
		/>
	</div>
	<div>
		<p>Change weekly goals</p>
		<input type="text" v-model="weeklyGoalInput" @keyup.enter="addWeeklyGoal" />
		<button class="btn-primary" @click="addWeeklyGoal">Add</button>
		<div v-for="goal in allGoals" :key="goal.id">
			{{ goal.name }}
			<button @click="removeWeeklyGoal(goal.id)">Remove</button>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	export default {
		data() {
			return {
				apiKeyInput: '',
				weeklyGoalInput: '',
				pin: ''
			}
		},
		methods: {
			setPinCode() {
				let userO = this.getUser

				if(this.pin.toString().length !== 4) return
        
				userO.pin = this.pin
				this.$store.dispatch('setPin', userO)
				this.pin = ''
			},
			addWeeklyGoal() {
				if (!this.weeklyGoalInput) {
					return
				}
				let payload = {
					name: this.weeklyGoalInput
				}

				this.$store.dispatch('addWeeklyGoal', payload)
				this.weeklyGoalInput = ''
			},
			removeWeeklyGoal(id) {
				this.$store.dispatch('deleteWeeklyGoalFromDb', id)
			},
			changeApiKey() {
				this.$store.dispatch('setQuotesApiKey', this.apiKeyInput)
				this.apiKeyInput = ''
			}
		},
		computed: {
			...mapGetters(['getWeeklyGoals', 'getUser']),
			allGoals() {
				return this.getWeeklyGoals
			}
		}
	}
</script>
