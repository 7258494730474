<template>
	<div>
		<!-- <div v-for="team in teams" :key="team.id">
      {{ team.name }}
    </div> -->
		<select v-model="teamSelect">
			<option v-for="team in teams" :key="team.id" :value="team.id">{{
				team.name
			}}</option>
		</select>
		<button class="btn-primary" @click="chooseTeam">Select</button>
	</div>
</template>

<script>
	export default {
		emits: ['chooseTeam'],
		data() {
			return {
				teamSelect: null
			}
		},
		methods: {
			chooseTeam() {
        console.log(this.teamSelect)
        let allTeams = [...this.teams]
        let index = allTeams.findIndex(team => team.id === this.teamSelect)
        this.$emit('chooseTeam', allTeams[index])
      }
		},
		computed: {
			teams() {
				return this.$store.getters.getAllTeams
			}
		}
	}
</script>

<style></style>
