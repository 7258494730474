<template>
  <div class="game-list">
    <div class="game" @click="playVideo">
      <p>{{ previousGame.away.shortName }}</p>
      <p>@</p>
      <p>{{ previousGame.home.shortName }}</p>
      <p>{{ awayScores }}</p>
      <p>{{ previousGame.extendedGame }}</p>
      <p class="third-column">{{ homeScores }}</p>
    </div>
    <div class="game">
      <p>{{ nextGame.away.shortName }}</p>
      <p>@</p>
      <p>{{ nextGame.home.shortName }}</p>
      <p>{{ nextGame.away.goals }}</p>
      <p class="third-column">{{ nextGame.home.goals }}</p>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['playVideo'],
  props: ['previous', 'next'],
  data() {
    return {
      previousGame: {
        away: {
          shortName: '',
          goals: ''
        },
        home: {
          shortName: '',
          goals: ''
        },
        extendedGame: null
      },
      nextGame: {
        away: {
          shortName: ''
        },
        home: {
          shortName: ''
        },
        date: ''
      }
    }
  },
  methods: {
    playVideo() {
      this.$emit('playVideo', this.previous)
    },
    fetchAndReturnTeamShortName(teamId) {
      const allTeams = this.$store.getters.getAllTeams
      if (allTeams.length > 0) {
        let index = allTeams.findIndex((team) => team.id === teamId)
        return allTeams[index].abbreviation
      } else {
        return []
      }
    },
    async fetchPreviousGame() {
      let game = await fetch(
        `https://statsapi.web.nhl.com/api/v1/game/${this.previous}/linescore`
      )
        .then((res) => res.json())
        .then((res) => res)

      this.previousGame.away.goals = game.teams.away.goals
      this.previousGame.away.shortName = this.fetchAndReturnTeamShortName(
        game.teams.away.team.id
      )

      this.previousGame.home.goals = game.teams.home.goals
      this.previousGame.home.shortName = this.fetchAndReturnTeamShortName(
        game.teams.home.team.id
      )

      if (game.hasShootout) {
        this.previousGame.extendedGame = 'SO'
      }
      if (game.periods.length > 3 && !game.hasShootout) {
        this.previousGame.extendedGame = 'OT'
      }
    },
    async fetchNextGame() {
      let game = await fetch(
        `https://statsapi.web.nhl.com/api/v1/game/${this.next}/boxscore`
      )
        .then((res) => res.json())
        .then((res) => res)

      this.nextGame.away.shortName = this.fetchAndReturnTeamShortName(
        game.teams.away.team.id
      )
      this.nextGame.home.shortName = this.fetchAndReturnTeamShortName(
        game.teams.home.team.id
      )
    }
  },
  computed: {
    watchedGame() {
      let allGames = this.$store.getters.getAllWatchedGames
      let index = -1
      if (allGames) {
        index = allGames.findIndex((game) => game === this.previous)
      }
      if (index === -1) {
        return false
      } else {
        return true
      }
    },
    awayScores() {
      if (this.watchedGame) {
        return this.previousGame.away.goals
      } else {
        return '*'
      }
    },
    homeScores() {
      if (this.watchedGame) {
        return this.previousGame.home.goals
      } else {
        return '*'
      }
    }
  },
  mounted() {
    this.fetchPreviousGame()
    this.fetchNextGame()
  }
}
</script>

<style scoped>
.game-list {
  display: grid;
  padding: 20px;
  gap: 20px;
}
.game {
  border: 1px solid black;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr;
}
.third-column {
  grid-column: 3;
}
</style>
