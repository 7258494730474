<template>
  <section>
    <div
      id="dailyFilter"
      data-cy="dailyFilterCheckbox"
      class="checkbox"
      :class="{ checked: filters.dailyFilter, unchecked: !filters.dailyFilter }"
      @click="toggleFilter"
    >
      D
    </div>
    <div
      id="randomFilter"
      data-cy="randomFilterCheckbox"
      class="checkbox"
      :class="{
        checked: filters.randomFilter,
        unchecked: !filters.randomFilter
      }"
      @click="toggleFilter"
    >
      R
    </div>
  </section>
</template>

<script>
export default {
  emit: ['change-filter'],
  data() {
    return {
      filters: {
        dailyFilter: true,
        randomFilter: false
      }
    }
  },
  methods: {
    toggleFilter(event) {
      const inputId = event.target.id
      let isActive
      if (event.target.id === 'dailyFilter') {
        isActive = !this.filters.dailyFilter
      } else if (event.target.id === 'randomFilter') {
        isActive = !this.filters.randomFilter
      }
      const updatedFilters = {
        ...this.filters,
        [inputId]: isActive
      }
      this.filters = updatedFilters
      this.$emit('change-filter', updatedFilters)
    }
  }
}
</script>

<style scoped>
section {
  display: flex;
}
.checkbox {
  border-radius: 5px;
  width: 40px;
  height: 40px;
  border: 1px solid black;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-right: 5px;
  user-select: none;
}
.checked {
  box-shadow: inset 1px 1px 1px 1px #676767;
  transition-duration: 0.5s;
}
.unchecked {
  box-shadow: 1px 1px 1px 1px #676767;
}
</style>
