<template>
  <div v-if="apiKey">
    <div>
      <h1>Quotes</h1>
    </div>
    <div @click="goToLink" id="random-quotes">Random Quotes</div>
    <div
      v-if="!searchAuthorInput"
      @click="searchAuthorInput = !searchAuthorInput"
    >
      Search Author
    </div>
    <div v-if="searchAuthorInput">
      <input type="text" id="" />
      <input type="button" value="Search" @click="fetchUsersQuotes" />
    </div>
    <div class="quote" v-for="quote in usersQuotes" :key="quote.id">
      {{ quote.body }}
    </div>
  </div>
  <div v-else>
    <input type="text" placeholder="Add api key" v-model="apiKeyInput" />
    <input type="button" value="Add" class="btn-primary" @click="addApiKey" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      displayRandomQuotes: false,
      apiKeyInput: '',
      selectQuotes: '',
      searchAuthorInput: false,
      usersQuotes: []
    }
  },
  methods: {
    goToLink(event) {
      this.$router.push('/' + event.target.id)
      this.displaySideBar = false
    },
    displayQuotes(event) {
      let quote = event.target.value
      if (quote === 'random') {
        this.fetchRandomQuotes()
        this.displayRandomQuotes = true
      } else {
        this.displayRandomQuotes = false
      }
    },
    async fetchUsersQuotes() {
      let username = this.$store.getters.getUser.name
      let apiKey = this.apiKey
      console.log(username)
      let quoteObject = await fetch(
        `https://favqs.com/api/quotes/?filter=${username}&type=user`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token token="${apiKey}"`
          },
          method: 'GET'
        }
      )
        .then(response => response.json())
        .then(result => {
          return result
        })
        .catch(e => {
          console.log(e)
        })

      this.usersQuotes = quoteObject.quotes
    },
    async typeAhead() {
      let quoteObject = await fetch('https://favqs.com/api/typeahead', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token token="${this.apiKey}"`
        },
        method: 'GET'
      })
        .then(response => response.json())
        .then(result => {
          return result
        })
        .catch(e => {
          console.log(e)
        })
      console.log(quoteObject)
    },
    addApiKey() {
      this.$store.dispatch('setQuotesApiKey', this.apiKeyInput)
    }
  },
  computed: {
    ...mapActions(['setQuotesApiKey']),
    ...mapGetters(['getQuotesApiKey']),
    apiKey() {
      return this.getQuotesApiKey
    }
  }
}
</script>

<style scoped>
.quote {
  background-color: red;
}
</style>
