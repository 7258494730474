import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '../firebase'

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Journal from '../views/Journal.vue'
import UserPage from '../views/UserPage.vue'
import Settings from '../views/userpage/Settings.vue'
import Tasklist from '../views/Tasklist.vue'
import TaskGroup from '../views/tasks/TaskGroup.vue'
import TaskGroups from '../views/tasks/TaskGroups.vue'
import ShoppingList from '../views/ShoppingList.vue'
import Quotes from '../views/Quotes.vue'
import RandomQuotes from '../views/quotes/Random-Quotes.vue'
import Budget from '../views/Budget.vue'
import Days from '../views/Days.vue'
import Day from '../views/Day.vue'
import StampClock from '../views/StampClock.vue'
import TimeTracker from '../views/TimeTracker.vue'
import TimeTrackerGroup from '../views/timetracker/TimeTrackerGroup.vue'
import Storage from '../views/Storage.vue'
import SearchStorage from '../views/storage/SearchStorage.vue'
import Item from '../views/storage/StorageItem.vue'
import AddItem from '../views/storage/AddItem.vue'
import Cli from '../views/Cli.vue'
import Nhl from '../views/Nhl.vue'
import Calendar from '../views/Calendar.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/stampClock',
    name: 'StampClcok',
    component: StampClock,
    meta: { requiresAuth: true }
  },
  {
    path: '/timetracker',
    name: 'Timetracker',
    component: TimeTracker,
    meta: { requiresAuth: true }
  },
  {
    path: '/timetrackergroup/:id',
    name: 'Timetrackergroup',
    component: TimeTrackerGroup,
    meta: { requiresAuth: true }
  },
  {
    path: '/budget',
    name: 'Budget',
    component: Budget,
    meta: { requiresAuth: true }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
    meta: { requiresAuth: true }
  },
  {
    path: '/storage',
    name: 'Storage',
    component: Storage,
    meta: { requiresAuth: true }
  },
  {
    path: '/searchstorage',
    name: 'SearchStorage',
    component: SearchStorage,
    meta: { requiresAuth: true }
  },
  {
    path: '/item/:id',
    name: 'Item',
    component: Item,
    meta: { requiresAuth: true }
  },
  {
    path: '/additem',
    name: 'AddItem',
    props: true,
    component: AddItem,
    meta: { requiresAuth: true }
  },
  {
    path: '/cli/:command',
    name: 'Cli',
    component: Cli,
    meta: { requiresAuth: true }
  },
  {
    path: '/nhl',
    name: 'Nhl',
    component: Nhl,
    meta: { requiresAuth: true }
  },
  {
    path: '/days',
    name: 'Days',
    component: Days,
    meta: { requiresAuth: true }
  },
  {
    path: '/day/:date',
    name: 'Day',
    component: Day,
    meta: { requiresAuth: true }
  },
  {
    path: '/journal',
    name: 'Journal',
    component: Journal,
    meta: { requiresAuth: true }
  },
  {
    path: '/tasklist',
    name: 'Tasks',
    component: Tasklist,
    meta: { requiresAuth: true }
  },
  {
    path: '/taskgroups',
    name: 'TaskGroups',
    component: TaskGroups,
    meta: { requiresAuth: true }
  },
  {
    path: '/taskgroup/:id',
    name: 'TaskGroup',
    component: TaskGroup,
    meta: { requiresAuth: true }
  },
  {
    path: '/shoppinglist',
    name: 'Shoppinglist',
    component: ShoppingList,
    meta: { requiresAuth: true }
  },
  {
    path: '/quotes',
    name: 'Quotes',
    component: Quotes,
    meta: { requiresAuth: true }
  },
  {
    path: '/random-quotes',
    name: 'Random Quotes',
    component: RandomQuotes,
    meta: { requiresAuth: true }
  },
  {
    path: '/userpage',
    name: 'UserPage',
    component: UserPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Login,
    requiresAuth: false
  },
  {
    path: '/:notFound(.*)',
    redirect: '/'
  }
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some((x) => x.meta.requiresAuth)
	if (requiresAuth && !auth.currentUser) {
		next('/login')
	} else {
		next()
	}
})

export default router
