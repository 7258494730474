<template>
	<div class="questions-form">
		<div class="questions-form-child">
			Dag 1-5?
		</div>
		<input
			class="slider questions-form-child"
			type="range"
			min="1"
			max="5"
			v-model="questionsForm.dayRating"
		/>
		<span class="questions-form-child"> {{ questionsForm.dayRating }} </span>
		<div class="questions-form-child">Steg?</div>
		<input
			data-cy="stepsInput"
			class="questions-form-child"
			type="number"
			v-model="questionsForm.stepsCount"
		/>
		<button data-cy="addDailyQuestions" class="questions-form-child" @click="saveDailyQuestions">
			Add
		</button>
	</div>
</template>

<script>
	export default {
		emits: ['saveQuestions'],
		data() {
			return {
				questionsForm: {
					dayRating: 3,
					stepsCount: null
				}
			}
		},
		methods: {
			saveDailyQuestions() {
				if (!this.questionsForm.stepsCount) return

				let payload = {
					dayRating: this.questionsForm.dayRating,
					steps: this.questionsForm.stepsCount
				}
				this.$emit('saveQuestions', payload)

				this.dayRating = 3
				this.stepsCount = ''
			}
		}
	}
</script>

<style scoped>
	.questions-form {
		height: 200px;
		display: grid;
		grid-template-columns: 1fr;
	}

	.questions-form-child {
		justify-self: center;
		align-self: center;
	}

	.slider {
		width: 200px;
		height: 20px;
		padding: 10px;
		border-radius: 10px;
		box-shadow: -2px -2px 8px white, 2px 2px 8px rgba(black, 0.5);
	}
	.slider::-webkit-slider-runnable-track {
		display: flex;
		align-items: center;
		height: 20px;
		border-radius: 10px;
		box-shadow: inset -2px -2px 8px white, inset 2px 2px 8px rgba(0, 0, 0, 0.5);
	}
	.slider::-webkit-slider-thumb {
		position: relative;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		box-shadow: -1px -1px 2px white, 1px 1px 2px rgba(0, 0, 0, 0.3);
		background-color: #e0e0e0;
		background-image: linear-gradient(
			-45deg,
			rgba(255, 255, 255, 0.8),
			transparent
		);
	}
	.slider::-moz-range-track {
		display: flex;
		align-items: center;
		height: 20px;
		border-radius: 10px;
		box-shadow: inset -2px -2px 8px white, inset 2px 2px 8px rgba(0, 0, 0, 0.5);
	}
	.slider::-moz-range-thumb {
		position: relative;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		box-shadow: -1px -1px 2px white, 1px 1px 2px rgba(0, 0, 0, 0.3);
		background-color: #e0e0e0;
		background-image: linear-gradient(
			-45deg,
			rgba(255, 255, 255, 0.8),
			transparent
		);
	}

	input[type='range'] {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
	input[type='range']:focus {
		outline: none;
	}
	input[type='range']::-webkit-slider-thumb {
		-webkit-appearance: none;
	}
	input[type='range']::-moz-range-thumb {
		border: none;
	}
</style>
