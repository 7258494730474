<template>
  <div class="card">
    <p data-cy="date-display">
      {{ dateDisplay }}
    </p>
    <p data-cy="stamp-name">
      {{ stamp.name }}
    </p>
    <p data-cy="total-time">
      {{ totalTime }}
    </p>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: ['stamp'],
  computed: {
    totalTime() {
      let totalTimeInMs = 0
      if (this.stamp.subStamps) {
        this.stamp.subStamps.forEach(_stamp => {
          totalTimeInMs += _stamp.durationInMs
        })
      }
      totalTimeInMs = moment()
        .hour(0)
        .minute(0)
        .second(totalTimeInMs / 1000)
        .format('HH:mm:ss')
      return totalTimeInMs
    },
    dateDisplay() {
      let date
      if (this.stamp.date) {
        date = this.stamp.date
      } else {
        if (this.stamp.subStamps.length > 1) {
          date = `${this.stamp.subStamps[this.stamp.subStamps.length - 1].date}`
        } else {
          date = this.stamp.subStamps[0].date
        }
      }
      return date
    }
  }
}
</script>

<style scoped>
.card {
  min-height: 30px;
  display: grid;
  align-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom: 1px solid black;
}
.card p {
  margin: 0;
}
</style>
