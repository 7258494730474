<template>
  <h1>Tasks</h1>
  <div class="container">
    <add-task-form
      v-if="displayAddTask"
      :editTaskObj="editTaskObj"
      @addTask="addTask"
      @saveEditTask="saveEditTask"
    ></add-task-form>

    <div class="task-container" data-cy="newTasksContainer">
      <TaskComponent
        v-for="(task, index) in getNewTasks"
        :index="index"
        :key="task.id"
        :task="task"
        @editTask="editTask"
        @deleteTask="deleteTask"
        @toggleTaskStatus="toggleTaskStatus"
        @toggleSubTaskStatus="toggleSubTaskStatus"
      ></TaskComponent>
    </div>
    <div class="task-container" data-cy="doneTasksContainer">
      <h2>Done tasks</h2>
      <TaskComponent
        v-for="task in getDoneTasks"
        :key="task.id"
        :task="task"
        @editTask="editTask"
        @deleteTask="deleteTask"
        @toggleTaskStatus="toggleTaskStatus"
        @toggleSubTaskStatus="toggleSubTaskStatus"
      ></TaskComponent>
    </div>

    <img
      data-cy="toggleAddTaskForm"
      @click="toggleAddTaskForm"
      id="add-task-button"
      src="../assets/tasklist/addTask.svg"
      alt="Add task"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import TaskComponent from '../components/tasks/TaskComponent'

import AddTaskForm from '../components/tasks/AddTaskForm'

export default {
  data() {
    return {
      displayAddTask: false,
      editTaskObj: null
    }
  },
  methods: {
    saveEditTask(task) {
      console.log('tasklist.vue - task.id', task.id)
      this.$store.dispatch('changeTaskStatus', task)
      this.toggleAddTaskForm()
    },
    deleteTask(task) {
      this.$store.dispatch('deleteTask', task)
    },
    toggleAddTaskForm() {
      if (this.displayAddTask) {
        this.displayAddTask = false
      } else {
        this.displayAddTask = true
      }
    },
    editTask(task) {
      this.editTaskObj = task
      this.displayAddTask = true
    },
    toggleTaskStatus(task) {
      if (task.status === 'new') {
        task.status = 'done'
      } else if (task.status === 'done') {
        task.status = 'new'
      }
      this.$store.dispatch('changeTaskStatus', task)
    },
    toggleSubTaskStatus(task) {
      this.$store.dispatch('changeTaskStatus', task)
    },
    addTask(task) {
      this.$store.dispatch('addTaskToDb', task)
      this.toggleAddTaskForm()
    }
  },
  computed: {
    ...mapActions(['fetchAllTasks', 'addTaskToDb', 'changeTaskStatus']),
    ...mapGetters(['returnAllTasks', 'returnAllGroups']),
    getNewTasks() {
      let returnVal
      if (this.returnAllTasks.length >= 1) {
        // let allGroups = [...this.returnAllGroups]
        let allTasks = [...this.returnAllTasks]

        // allGroups.forEach(group => {
        //   allTasks = allTasks.concat(group.tasks)
        // })

        returnVal = allTasks.filter(task => task.status === 'new')
        returnVal = returnVal.sort((a, b) => {
          return new Date(a.date) - new Date(b.date)
        })
      } else {
        returnVal = null
      }
      return returnVal
    },
    getDoneTasks() {
      let returnVal
      if (this.returnAllTasks.length >= 1) {
				// let allGroups = [...this.returnAllGroups]
        let allTasks = [...this.returnAllTasks]

        // allGroups.forEach(group => {
        //   allTasks = allTasks.concat(group.tasks)
        // })

        returnVal = allTasks.filter(task => task.status === 'done')
        returnVal = returnVal.sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })
      } else {
        returnVal = null
      }
      return returnVal
    }
  },
  components: {
    TaskComponent,
    AddTaskForm
  }
}
</script>

<style scoped>
.task-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: 5px;
}

#add-task-button {
  box-shadow: 1px 1px 1px 1px #4e5166;
  position: fixed;
  background-color: #fffffb;
  bottom: 10px;
}
</style>
