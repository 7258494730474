<template>
	<div class="expense-container">
		<div class="expense">
			<div
				type="radio"
				id="budget"
				class="radio"
				@click="toggleFilter"
				:class="{ checked: filter.budget }"
			>
				B
			</div>
			<div
				type="radio"
				id="result"
				class="radio"
				@click="toggleFilter"
				:class="{ checked: filter.result }"
			>
				R
			</div>
			<div
				v-if="filter.budget"
				type="radio"
				class="radio add-button"
				:class="{ checked: displayAddExpense }"
				@click="toggleAddExpense"
			>
				Add budget
			</div>
			<div
				type="radio"
				class="radio add-button"
				:class="{ checked: displayAddIncome }"
				@click="toggleAddIncome"
			>
				Add Income
			</div>
			<div
				v-if="filter.result"
				type="radio"
				class="radio add-button"
				:class="{ checked: displayAddResult }"
				@click="toggleAddResult"
			>
				Add result
			</div>
		</div>
		<form @submit="prevent" class="add-form" v-if="displayAddExpense">
			<input type="text" placeholder="Name" v-model="addExpenseForm.name" />
			<div>
				<input
					type="number"
					placeholder="Amount"
					v-model="addExpenseForm.amount"
				/>
				<input
					type="number"
					placeholder="Date"
					v-model="addExpenseForm.date"
					:class="{ error: addExpenseDaysError }"
				/>
				<input
					type="button"
					value="Add"
					class="btn-primary"
					@click="addExpense"
					:disabled="addExpenseDaysError"
				/>
			</div>
		</form>
		<form @submit="prevent" class="add-form" v-if="displayAddIncome">
			<input
				type="text"
				name="name"
				id=""
				v-model="addIncomeForm.name"
				placeholder="Name"
			/>
			<div>
				<input
					type="number"
					v-model="addIncomeForm.amount"
					placeholder="Amount"
				/>
				<input type="number" v-model="addIncomeForm.date" placeholder="Date" />
				<input
					type="button"
					value="Add"
					class="btn-primary"
					@click="addIncome"
				/>
			</div>
		</form>
		<form @submit="prevent" class="add-form" v-if="displayAddResult">
			<input type="text" placeholder="Name" v-model="addResultForm.name" />
			<div>
				<input
					type="number"
					placeholder="Amount"
					v-model="addResultForm.amount"
				/>
				<input
					type="number"
					placeholder="Date"
					v-model="addResultForm.date"
					:class="{ error: addResultDaysError }"
				/>
				<input
					type="button"
					value="Add"
					class="btn-primary"
					@click="addResult"
				/>
			</div>
		</form>
		<div
			v-if="filter.budget && monthlyExpenses.length > 0"
			class="expense-container"
		>
			<div class="expense">
				<div>Date</div>
				<div>Name</div>
				<div>Amount</div>
			</div>
			<monthly-expense
				class="expense"
				v-for="expense in monthlyExpenses"
				:key="expense.id"
				:expense="expense"
			></monthly-expense>
			<div class="expense">
				<div></div>
				<div>Total: {{ total }}:-</div>
			</div>
		</div>
		<div
			class="expense"
			v-else-if="filter.budget && monthlyExpenses.length < 1"
		>
			<div></div>
			<div>
				Add an expense
			</div>
		</div>
		<div
			v-if="filter.result && monthlyExpenses.length > 0"
			class="expense-container"
		>
			<monthly-expense
				class="expense"
				v-for="expense in monthlyExpenses"
				:key="expense.id"
				:expense="expense"
			></monthly-expense>
			<div class="expense">
				<div></div>
				<div>Total: {{ total }}:-</div>
			</div>
		</div>
		<div
			class="expense"
			v-else-if="filter.result && monthlyExpenses.length < 1"
		>
			<div></div>
			<div>
				Add an expense
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment'
	import { mapGetters } from 'vuex'

	import MonthlyExpense from './MonthlyExpense'

	export default {
		emits: ['addExpense'],
		props: ['month', 'year'],
		data() {
			return {
				displayAddExpense: false,
				displayAddResult: false,
				displayAddIncome: false,
				expenses: '',
				addExpenseForm: {
					name: '',
					amount: '',
					date: ''
				},
				addResultForm: {
					name: '',
					amount: '',
					date: ''
				},
				addIncomeForm: {
					name: '',
					amount: '',
					date: ''
				},
				filter: {
					budget: true,
					result: false
				}
			}
		},
		methods: {
			toggleAddExpense() {
				this.displayAddExpense = !this.displayAddExpense
			},
			toggleAddResult() {
				this.displayAddResult = !this.displayAddResult
			},
			toggleAddIncome() {
				this.displayAddIncome = !this.displayAddIncome
			},
			toggleFilter(event) {
				if (event.target.id === 'budget') {
					this.filter.budget = true
					this.filter.result = false
				} else if (event.target.id === 'result') {
					this.filter.budget = false
					this.filter.result = true
				}
			},
			addExpense() {
				if (
					this.addExpenseForm.name &&
					this.addExpenseForm.amount &&
					this.addExpenseForm.date
				) {
					let fullDate = moment()
						.month(this.month)
						.date(this.addExpenseForm.date)
						.format('YYYY-MM-DD')
					let expenseObject = {
						name: this.addExpenseForm.name,
						amount: this.addExpenseForm.amount,
						date: fullDate,
						type: 'budget'
					}
					let payload = {
						expenseObject: expenseObject,
						year: this.year,
						month: this.month
					}
					this.$emit('addExpense', payload)

					// Clearing the form
					this.addExpenseForm.name = ''
					this.addExpenseForm.amount = ''
					this.addExpenseForm.date = ''
					this.displayAddExpense = false
				}
			},
			addIncome() {
				let fullDate = moment()
					.month(this.month)
					.date(this.addIncomeForm.date)
					.format('YYYY-MM-DD')
				let resultObject = {
					name: this.addIncomeForm.name,
					amount: this.addIncomeForm.amount,
					date: fullDate,
					type: 'income'
				}
				console.log(resultObject)
			},
			addResult() {
				let fullDate = moment()
					.month(this.month)
					.date(this.addResultForm.date)
					.format('YYYY-MM-DD')
				let resultObject = {
					name: this.addResultForm.name,
					amount: this.addResultForm.amount,
					date: fullDate,
					type: 'result'
				}
				let payload = {
					expenseObject: resultObject,
					year: this.year,
					month: this.month
				}

				console.log(payload)
				this.$emit('addExpense', payload)

				this.addResultForm.name = ''
				this.addResultForm.amount = ''
				this.addResultForm.date = ''
				this.displayAddResult = false
			}
		},
		computed: {
			...mapGetters(['getMonthlyExpenses']),
			monthlyExpenses() {
				return this.getMonthlyExpenses.filter((expense) => {
					if (this.filter.budget && expense.type === 'budget') {
						return true
					}
					if (this.filter.result && expense.type === 'result') {
						return true
					}
					return false
				})
			},
			addExpenseDaysError() {
				let daysLength = moment()
					.month(this.month)
					.daysInMonth()
				return (
					this.addExpenseForm.date > daysLength ||
					(this.addExpenseForm.date.length > 0 && this.addExpenseForm.date < 1)
				)
			},
			addResultDaysError() {
				let daysLength = moment()
					.month(this.month)
					.daysInMonth()
				return (
					this.addResultForm.date > daysLength ||
					(this.addResultForm.date.length > 0 && this.addResultForm.date < 1)
				)
			},
			total() {
				let total = 0
				this.monthlyExpenses.forEach((expense) => {
					total += parseInt(expense.amount)
				})
				return total
			}
		},
		watch: {
			month: function(newVal) {
				let payload = {
					year: this.year,
					month: newVal
				}
				this.$store.dispatch('fetchMonth', payload)
			}
		},
		components: {
			MonthlyExpense
		},
		created() {
			let payload = {
				year: '2021',
				month: this.month
			}
			this.$store.dispatch('fetchMonth', payload)
		}
	}
</script>

<style scoped>
	.expense-container {
		display: grid;
		grid-template-columns: 1fr;
		grid-auto-rows: auto;
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		padding: 10px;
	}
	.radio {
		-webkit-appearance: none;
		border-radius: 5px;
		width: 40px;
		height: 40px;
		border: 1px solid black;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		margin-right: 5px;
		user-select: none;
		outline: none;
		box-shadow: 1px 1px 1px 1px #676767;
	}

	.checked {
		box-shadow: inset 1px 1px 1px 1px #676767;
		transition-duration: 2s;
	}

	.expense {
		display: grid;
		align-content: flex-end;
		justify-items: center;
		overflow: hidden;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		border: 1px solid #727272;
		background-color: #eef0f2;
		box-shadow: 0px 4px 4px #727272;
		padding-left: 10px;
		padding-right: 10px;
		user-select: none;
		z-index: 2;
	}
	.error {
		border: 1px solid red;
	}
	.add-button {
		width: 60px;
	}

	.add-button:active {
		box-shadow: inset 1px 1px 1px 1px #676767;
	}
	.add-form {
		height: 100px;
		margin-top: 10px;
		display: flex;
		flex-direction: column;
		border: 1px solid #727272;
		background-color: #eef0f2;
		box-shadow: 0px 4px 4px #727272;
		padding-left: 10px;
		padding-right: 10px;
	}
	.add-form div {
		display: flex;
		align-content: center;
		justify-content: center;
	}
	.add-form input[type='number'] {
		font-size: 1rem;
		height: 40px;
		width: 60px;
		margin-right: 10px;
		-moz-appearance: textfield;
	}

	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
</style>
