<template>
<div class="expense">
  <div>{{ expense.date }}</div>
  <div>{{ expense.name }}</div>
  <div>{{ expense.amount }}:-</div>
</div>
</template>

<script>
export default {
  props: ['expense']
}
</script>

<style scoped>
.expense {
  display: flex;
  align-content: center;
  justify-content: center;
}
</style>