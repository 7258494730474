<template>
  <div>
    <div class="task" data-cy="taskComponent" @click="displayTask">
      <img
        data-cy="uncheckedButton"
        class="checkbox"
        src="../../assets/tasklist/unchecked.svg"
        alt="unchecked"
        v-if="task.status === 'new'"
        @click="toggleStatus"
      />
      <img
        data-cy="checkedButton"
        class="checkbox"
        src="../../assets/tasklist/checked.svg"
        alt="checked"
        v-if="task.status === 'done'"
        @click="toggleStatus"
      />
      <div>
        <h2>
          {{ task.name }}
        </h2>
        <span>
          {{ task.date }}
        </span>
      </div>
      <img
        src="../../assets/tasklist/arrow.svg"
        id="expand-sub-task-arrow"
        alt="Show subtasks"
        v-if="task.subTasks"
        @click="toggleDisplaySubTasks"
      />
      <img
        data-cy="expandOptionsButton"
        class="expand-options-button"
        src="../../assets/expand.svg"
        alt="Expand Options"
        @click="displayOptions = !displayOptions"
      />
    </div>
    <div
      class="expanded-options"
      v-if="displayOptions"
      :data-cy="'expandedOptions' + index"
    >
      <div>
        <div>
          <img
            @click="editTask"
            src="../../assets/edit.svg"
            alt="Edit Tsask"
            data-cy="editTask"
          />
        </div>
        <div>
          <img
            @click="deleteTask"
            src="../../assets/trash.svg"
            alt="Delete Task"
            data-cy="deleteTask"
          />
        </div>
      </div>
    </div>
    <div class="expanded" v-if="displaySubTasks">
      <sub-task-component
        v-for="subTask in task.subTasks"
        :key="subTask.id"
        :subTask="subTask"
        @toggleSubTaskStatus="toggleSubTaskStatus"
      ></sub-task-component>
    </div>
  </div>
</template>

<script>
import SubTaskComponent from './SubTaskComponent'

export default {
  emits: [
    'toggleTaskStatus',
    'toggleSubTaskStatus',
    'deleteTask',
    'editTask',
    'displayTask'
  ],
  props: ['task', 'index'],
  data() {
    return {
      displaySubTasks: false,
      displayOptions: false
    }
  },
  methods: {
    displayTask(data) {
      if (data.target.localName === 'img') return
      this.$emit('displayTask', this.task)
    },
    editTask() {
      this.$emit('editTask', this.task)
    },
    toggleSubTaskStatus(subTask) {
      if (subTask.status === 'new') {
        subTask.status = 'done'
      } else {
        subTask.status = 'new'
      }
      let payload = this.task
      let index = payload.subTasks.findIndex(task => task.id === subTask.id)
      payload.subTasks[index] = subTask
      this.$emit('toggleSubTaskStatus', payload)
    },
    toggleStatus() {
      this.$emit('toggleTaskStatus', this.task)
    },
    toggleDisplaySubTasks() {
      if (this.displaySubTasks) {
        this.displaySubTasks = false
      } else {
        this.displaySubTasks = true
      }
    },
    deleteTask() {
      this.$emit('deleteTask', this.task)
    }
  },
  components: {
    SubTaskComponent
  }
}
</script>

<style scoped>
.task {
  border: 2px solid #4e5166;
  border-radius: 5px;
  background-color: #fffffb;
  display: grid;
  grid-template-columns: 0.1fr 1fr 0.1fr;
  grid-template-rows: 1fr;
  align-content: center;
  padding-left: 10px;
}
.task h2 {
  grid-row: 1;
  align-self: center;
  justify-self: center;
  text-align: center;
}
.task div {
  align-self: center;
  justify-self: center;
  grid-row: 1;
}

.checkbox {
  grid-row: 1;
  align-self: center;
  justify-self: start;
}

#expand-sub-task-arrow {
  grid-row: 1;
  align-self: center;
  justify-self: end;
  height: 20px;
}

.expand-options-button {
  grid-row: 1;
  rotate: 90deg;
  align-self: center;
  justify-self: end;
}

.expanded-options{
  position: relative;
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: end;
  padding-right: 10px;
}

.expanded-options > div {
	display: grid;
  gap: 5px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
	position: absolute;
  top: 0;
  z-index: 1;
}

.expanded-options > div > div {
  background-color: #fff;
  box-shadow: 1px 1px 1px 1px #676767;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  /* position: absolute; */
}

.expanded {
  margin-top: -5px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid black;
  background-color: #fffffb;
}
</style>
