<template>
  <div class="form-stuff">
    <div class="top"></div>
    <div class="bottom"></div>
    <div class="center">
      <!-- <h1>Login</h1> -->
      <form class="form" @submit="login" v-if="showLoginForm">
        <input
          type="email"
          id="email"
          name="email"
          v-model="loginForm.email"
          placeholder="Email"
        />
        <span v-if="error.emailError">{{ error.emailError }}</span>
        <input
          type="password"
          id="password"
          name="password"
          v-model="loginForm.password"
          placeholder="Password"
          @keyup.enter="login"
        />
        <span v-if="error.passwordError">{{ error.passwordError }}</span>
        <input
          type="button"
          @click="login()"
          class="btn-primary"
          value="Login"
          id="login-button"
        />
        <input
          type="button"
          @click="toggleForm()"
          value="Sign up"
          id="sign-up-button"
        />
      </form>
      <form class="form" @submit.prevent v-else>
        <input
          type="text"
          name="name"
          v-model="signupForm.name"
          placeholder="Name"
        />
        <input
          type="email"
          name="email"
          v-model="signupForm.email"
          placeholder="Email"
        />
        <input
          type="password"
          name="password"
          v-model="signupForm.password"
          placeholder="Password"
        />
        <input
          type="button"
          @click="signup"
          class="btn-primary"
          id="login-button"
          value="Button"
        />
        <input
          type="button"
          @click="toggleForm()"
          value="Sign in"
          id="sign-up-button"
        />
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showLoginForm: true,
      loginForm: {
        email: '',
        password: ''
      },
      signupForm: {
        email: '',
        password: '',
        name: ''
      },
      error: {
        emailError: null,
        passwordError: null
      }
    }
  },
  methods: {
    toggleForm() {
      this.showLoginForm = !this.showLoginForm
    },
    login() {
      if (!this.loginForm.email) {
        this.error.emailError = 'Please enter an email'
      }
      if (!this.loginForm.password) {
        this.error.passwordError = 'Please enter a password'
      }
      if (this.loginForm.email && this.loginForm.password) {
        this.$store.dispatch('login', {
          email: this.loginForm.email,
          password: this.loginForm.password
        })
      }
    },
    signup() {
      this.$store.dispatch('signup', {
        name: this.signupForm.name,
        email: this.signupForm.email,
        password: this.signupForm.password
      })
    }
  }
}
</script>

<style scoped>
span {
  color: red;
}
@media screen and (min-width: 600px) {
  .form-stuff {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media screen and (min-width: 900px) {
  .form-stuff {
    padding-left: 200px;
    padding-right: 200px;
  }
}
@media screen and (min-width: 1100px) {
  .form-stuff {
    padding-left: 300px;
    padding-right: 300px;
  }
}
@media screen and (min-width: 1300px) {
  .form-stuff {
    padding-left: 400px;
    padding-right: 400px;
  }
}
@media screen and (min-width: 1500px) {
  .form-stuff {
    padding-left: 500px;
    padding-right: 500px;
  }
}
/* Login-page start */
.form {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
}

.form input[type='email'],
input[type='password'],
input[type='text'] {
  background-color: #dce1e3;
  color: black;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid black;
  border-top: none;
  border-right: none;
  border-left: none;
  margin-top: 5px;
  height: 40px;
  outline: none;
}

.form input[type='email']::placeholder,
input[type='password']::placeholder,
input[type='text']::placeholder {
  color: black;
  opacity: 0.92;
}

.form :invalid {
  border: 2px solid red;
}

.form input[type='button'] {
  margin-left: 100px;
  margin-right: 100px;
  height: 30px;
  outline: none;
}

#sign-up-button {
  background-color: #dce1e3;
  font-size: 16px;
  text-decoration: underline;
  border: none;
  outline: none;
}
/* login-page end */
</style>
