import * as fb from '../../firebase'
// import router from '../../router/index'

const state = {
	shoppingItems: {}
}

const mutations = {
	setShoppingItems(state, val) {
		state.shoppingItems = val
	}
}

const actions = {
	async addShoppingItem(context, payload) {
		const uid = await context.rootState.UserStore.userProfile.uid
		await fb
			.shoppingListCollection(uid)
			.add(payload)
			.catch((e) => {
				console.log(e)
			})
		context.dispatch('fetchAllShoppingItems', uid)
	},
	async changeShoppingItem(context, payload) {
		const uid = await context.rootState.UserStore.userProfile.uid
		await fb
			.shoppingListCollection(uid)
			.doc(payload.id)
			.set(payload)
			.then(() => {
				console.log('Shopping-item successfully saved!')
			})
			.catch((e) => {
				console.log('Error when changing shopping item', e)
			})
	},
	async fetchAllShoppingItems(context, uid) {
		let shoppingItems = []
		const dbShoppingItems = await fb.shoppingListCollection(uid).get()
		dbShoppingItems.forEach((item) => {
			let itemObject = item.data()
			itemObject.id = item.id
			shoppingItems.push(itemObject)
		})
		context.commit('setShoppingItems', shoppingItems)
	}
}

const getters = {
	returnAllShoppingItems(state) {
		return state.shoppingItems
	}
}

export default {
	state,
	mutations,
	actions,
	getters
}
