<template>
  <div>
    <h1>Days</h1>
    <div class="days-container">
      <DayItem
        v-for="(timeStamp, index) in allTimeStamps"
        :tabindex="index + 1"
        :key="timeStamp.id"
        :date="timeStamp.date"
        @openDay="openDay"
      ></DayItem>
    </div>
  </div>
</template>

<script>
import DayItem from '../components/days/DayItem.vue'

export default {
  methods: {
    openDay(date) {
      console.log(date)
      this.$router.push('/day/' + date)
    }
  },
  computed: {
    allTimeStamps() {
      let timeGroups = this.$store.getters.getTimeGroups

      let timeStamps = []
      timeGroups.forEach((timeGroup) => {
        if (timeGroup.stamps) timeStamps = timeStamps.concat(timeGroup.stamps)
      })

      let tempArr = []

      // Grouping timestamps by date
      timeStamps.forEach((stamp, i) => {
        timeStamps.forEach((_stamp, j) => {
          // Not checking itself
          if (i !== j) {
            if (stamp.date === _stamp.date) {
              let foundIndex = tempArr.findIndex((s) => s.date === _stamp.date)
              if (foundIndex === -1 && _stamp.date) tempArr.push(_stamp)
            }
          }
        })
      })

      // Sorting by date
      tempArr.sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })

      return tempArr
    }
  },
  mounted() {
    window.scrollTo(0,0)
  },
  components: {
    DayItem
  }
}
</script>

<style scoped>
.days-container {
  display: grid;
  padding: 20px;
  gap: 20px;
}
</style>
