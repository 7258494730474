<template>
	<div class="header">
		<div>
			<h2>
				{{ months.previousMonth.month }}
			</h2>
			<div>
				{{ months.previousMonth.year }}
			</div>
		</div>
		<button @click="previousMonth">
			&lt;
		</button>
		<div>
			<h1>
				{{ months.currentMonth.month }}
			</h1>
			<div>
				{{ months.currentMonth.year }}
			</div>
		</div>
		<button @click="nextMonth">
			&gt;
		</button>
		<div>
			<h2>
				{{ months.nextMonth.month }}
			</h2>
			<div>
				{{ months.nextMonth.year }}
			</div>
		</div>
	</div>
	<div></div>
	<monthly-expenses
		:month="months.currentMonth.month"
    :year="months.currentMonth.year"
		@addExpense="addExpense"
	></monthly-expenses>
</template>

<script>
	import moment from 'moment'

	import MonthlyExpenses from '../components/budget/MonthlyExpenses'

	export default {
		data() {
			return {
				months: {},
				selectedMonth: ''
			}
		},
		methods: {
			addExpense(payload) {
				this.$store.dispatch('addExpense', payload)
			},
      previousMonth() {
        this.selectedMonth = moment(this.selectedMonth).subtract(1, 'month')
        this.updateMonths()
      },
      nextMonth() {
        this.selectedMonth = moment(this.selectedMonth).add(1, 'month')
        this.updateMonths()
      },
			updateMonths() {
				this.months.previousMonth = {
					year: moment(this.selectedMonth)
						.subtract(1, 'month')
						.format('YYYY'),
					month: moment(this.selectedMonth)
						.subtract(1, 'month')
						.format('MMMM')
				}

				this.months.currentMonth = {
					year: moment(this.selectedMonth).format('YYYY'),
					month: moment(this.selectedMonth).format('MMMM')
				}

				this.months.nextMonth = {
					year: moment(this.selectedMonth)
						.add(1, 'month')
						.format('YYYY'),
					month: moment(this.selectedMonth)
						.add(1, 'month')
						.format('MMMM')
				}
			}
		},
		components: {
			MonthlyExpenses
		},
		created() {
			this.selectedMonth = moment()
			this.updateMonths()
		}
	}
</script>

<style scoped>
	.header {
		display: flex;
		align-content: center;
		justify-content: space-around;
	}
</style>
