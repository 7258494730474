<template>
	<div class="calendar-container">
		<full-calendar :options="calendarOptions"></full-calendar>
		<add-event
			v-if="displayAddEvent"
			:eventData="eventData"
			@closeAddEvent="closeAddEvent"
		></add-event>
	</div>
</template>

<script>
	import '@fullcalendar/core/vdom' // solves problem with Vite
	import FullCalendar from '@fullcalendar/vue3'
	import dayGridPlugin from '@fullcalendar/daygrid'
	import interactionPlugin from '@fullcalendar/interaction'
	import timeGridPlugin from '@fullcalendar/timegrid'

	import AddEvent from '@/components/calendar/AddEvent.vue'

	import moment from 'moment'

	export default {
		data() {
			return {
				displayAddEvent: false,
				eventData: null,
				calendarOptions: {
					height: '100%',
					selectable: true,
					displayEventEnd: true,
					firstDay: 1,
					headerToolbar: {
						center: 'dayGridMonth,timeGridWeek,timeGridDay'
					},
					select: this.handleSelect,
					slotLabelFormat: {
						hour: 'numeric',
						minute: '2-digit',
						omitZeroMinute: false,
						meridiem: 'short',
						// Change the time on y-axis to 24-hour
						hour12: false
					},
					dateClick: this.handleDateClick,
					eventTimeFormat: {
						hour: '2-digit',
						minute: '2-digit',
						second: '2-digit',
						hour12: false
					},
					plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
					initialView: 'timeGridWeek',
					events: [],
					slotDuration: '00:30:00'
				}
			}
		},
		methods: {
			handleSelect(arg) {
				const startDate = arg.startStr.split('T')[0]
				const startTime = arg.startStr.split('T')[1].split('+')[0]
				const endDate = arg.endStr.split('T')[0]
				const endTime = arg.endStr.split('T')[1].split('+')[0]

				const format = 'YYYY-MM-DD HH:mm:ss'

				let start = startDate + ' ' + startTime
				start = moment(start, format)
				let end = endDate + ' ' + endTime
				end = moment(end, format)
				const now = moment().format(format)

				// console.log('start', start)
				// console.log('end', end)
				// console.log('now', now)

				if (start.isBetween(now, end)) {
					this.displayAddEvent = true
					this.eventData = {
						startTime: startTime,
						startDate: startDate,
						endDate: endDate,
						endTime: endTime
					}
				}
			},
			closeAddEvent() {
				this.displayAddEvent = false
				this.eventData = null
			},
			setEvents() {
				this.calendarOptions.events = []
				this.getAllDailyTimeStamps.forEach((stamp) => {
					this.calendarOptions.events.push({
						date: stamp.date,
						start: `${stamp.date}T${stamp.startTime}`,
						end: `${stamp.date}T${stamp.endTime}`,
						allDay: false,
						title: stamp.name
					})
				})
			}
		},
		computed: {
			getAllTasksWithTimes() {
				const allTaskGroups = this.$store.getters.returnAllGroups
				const tasks = []
				allTaskGroups.forEach(group => {
					group.tasks.forEach(task => {
						if (task.startTime && task.endTime) {
							tasks.push(task)
						}
					})
				})
				const allTasks = this.$store.getters.returnAllTasks
				allTasks.forEach(task => {
					if (task.startTime && task.endTime) {
							tasks.push(task)
						}
				})
				return tasks
			},
			getAllDailyTimeStamps() {
				let timeGroups = [...this.$store.getters.getTimeGroups]
				let stamps = []

				timeGroups.forEach((timeGroup) => {
					if (timeGroup.stamps) {
						timeGroup.stamps.forEach((stamp) => {
							let stampO = { ...stamp }
							stampO.group = timeGroup.name
							stamps.push(stampO)
						})
					}
				})

				stamps.forEach((stamp) => {
					if (stamp) {
						stamp.startTime = stamp.startTime.split(' ')[1]
						if (stamp.endTime) {
							stamp.endTime = stamp.endTime.split(' ')[1]
						} else {
							stamp.endTime = moment().format('HH:mm:ss')
						}
					}
				})
				stamps = stamps.concat(this.getAllTasksWithTimes)
				return stamps
			}
		},
		mounted() {
			if (this.getAllDailyTimeStamps.length > 0) {
				this.setEvents()
			}
		},
		watch: {
			getAllDailyTimeStamps: function(newVal) {
				if (newVal.length > 0) {
					this.setEvents()
				}
			}
		},
		components: {
			FullCalendar,
			AddEvent
		}
	}
</script>

<style scoped>
	.calendar-container {
		height: 100%;
	}
</style>
