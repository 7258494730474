// import Vuex from 'vuex'
import * as fb from '../../firebase'

function compare(a, b) {
	a = new Date(a.date)
	b = new Date(b.date)
	if (a > b) {
		return -1
	}
	if (a < b) {
		return 1
	}
	return 0
}

const state = {
	notes: []
}
const mutations = {
	setNotes(state, val) {
		state.notes = val
	}
}
const actions = {
	async fetchAllNotes(context, uid) {
		let notesArr = []
		await fb
			.dailyJournalCollection(uid)
			.get()
			.then((notes) => {
				notes.forEach((note) => {
					let noteObject = note.data()
          noteObject.id = note.id
          notesArr.push(noteObject)
				})
			})

		await fb
			.randomJournalCollection(uid)
			.get()
			.then((notes) => {
        notes.forEach(note => {
          let noteObject = note.data()
          noteObject.id = note.id
          notesArr.push(noteObject)
        })
			})

		notesArr = notesArr.sort(compare)
		context.commit('setNotes', notesArr)
	},
	async addNoteToDb(context, payload) {
		console.log('payload', payload)
    const uid = context.rootState.UserStore.userProfile.uid
		if (payload.option === 'daily') {
			await fb.dailyJournalCollection(uid).add(payload).catch((err) => {
				console.log(err)
			})
		} else if (payload.option === 'random') {
			await fb.randomJournalCollection(uid).add(payload).catch((err) => {
				console.log(err)
			})
		}
		context.dispatch('fetchAllNotes', uid)
	},
	async saveNoteInDb(context, payload) {
    const uid = context.rootState.UserStore.userProfile.uid
		if (payload.option === 'daily') {
			await fb.dailyJournalCollection(uid)
				.doc(payload.id)
				.set(payload)
				.then(() => {
					console.log('Collection saved in daily!')
				})
				.catch((e) => {
					console.log('Error saving document in daily', e)
				})
		} else if (payload.option === 'random') {
			await fb.randomJournalCollection(uid)
				.doc(payload.id)
				.set(payload)
				.then(() => {
					console.log('Collection saved in random!')
				})
				.catch((err) => {
					console.log(err)
				})
		}
		context.dispatch('fetchAllNotes', uid)
	},
	async deleteNoteFromDb(context, note) {
    const uid = context.rootState.UserStore.userProfile.uid
		if (note.option === 'daily') {
			fb.dailyJournalCollection(uid)
				.doc(note.id)
				.delete()
				.then(() => {
					console.log('Document successfully deleted from daily!')
				})
				.catch((e) => {
					console.log('Error deleting document from daily:', e)
				})
		} else if (note.option === 'random') {
			fb.randomJournalCollection(uid)
				.doc(note.id)
				.delete()
				.then(() => {
					console.log('Document successfully deleted from random!')
				})
				.catch((e) => {
					console.log('Error deleting document from random:', e)
				})
		}
		context.dispatch('fetchAllNotes', uid)
	}
}

const getters = {
	getAllNotes(state) {
		return state.notes
	}
}

export default { state, mutations, actions, getters }
