<template>
  <div class="day-container">
    <div class="header">
      <h1>{{ date }}</h1>
      <day-filter
        @change-filter="changeFilter"
        @lowerDate="lowerDate"
        @raiseDate="raiseDate"
      ></day-filter>
    </div>
    <day-journal :date="date" v-if="filter === 'journalFilter'"></day-journal>
    <day-tasklist
      :date="date"
      v-if="filter === 'tasklistFilter'"
    ></day-tasklist>
    <day-time :date="date" v-if="filter === 'timeFilter'"></day-time>
  </div>
</template>

<script>
import DayFilter from '@/components/day/DayFilter.vue'
import DayTime from '@/components/day/DayTime.vue'
import DayJournal from '@/components/day/DayJournal.vue'
import DayTasklist from '@/components/day/DayTasklist.vue'

import moment from 'moment'

export default {
  data() {
    return {
      date: moment().format('yyyy-MM-DD'),
      filter: 'journalFilter'
    }
  },
  methods: {
    setRoute() {
      this.$router.push('/day/' + this.date)
    },
    lowerDate() {
      this.date = moment(this.date)
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      this.setRoute()
    },
    raiseDate() {
      this.date = moment(this.date)
        .add(1, 'day')
        .format('YYYY-MM-DD')
        this.setRoute()
    },
    changeFilter(filter) {
      this.filter = filter
    }
  },
  mounted() {
    this.date = this.$route.params.date
  },
  components: {
    DayFilter,
    DayTime,
    DayJournal,
    DayTasklist
  }
}
</script>

<style scoped>
.day-container {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.header {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f1eeee;
}
.header h1 {
  margin-top: 0;
}
</style>
