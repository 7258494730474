<template>
  <div class="backdrop">
    <div class="sign-in-form">
      <input
        ref="input"
        type="number"
        placeholder="Enter pin"
        v-model="pin"
        @keyup.enter="signIn"
      />
      <button class="btn" @click="signIn">Sign in</button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['signIn'],
  data() {
    return {
      pin: ''
    }
  },
  methods: {
    signIn() {
      if (this.pin === this.user.pin) {
        console.log('sign in')
        this.$emit('signIn')
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  mounted() {
    this.$refs.input.focus()
  }
}
</script>

<style scoped>
.backdrop {
  position: absolute;
  z-index: 99;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sign-in-form {
  border: 1px solid black;
  background-color: white;
  height: 200px;
  width: 200px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
</style>
