<template>
  <div class="game" @click="watchGame">
    <p>{{ home.shortName }}</p>
    <p>@</p>
    <p>{{ away.shortName }}</p>
    <p>{{ homeScores }}</p>
    <p>{{ extendedTimeDisplay }}</p>
    <p class="third-column">{{ awayScores }}</p>
  </div>
</template>

<script>
export default {
  emits: ['watchGame'],
  props: ['game'],
  data() {
    return {
      home: {
        shortName: null,
        goals: null,
      },
      away: {
        shortName: null,
        goals: null,
      },
      extendedTime: null,
    }
  },
  methods: {
    watchGame() {
      this.$emit('watchGame', this.game.gamePk)
    },
    fetchAndReturnShortName(id) {
      const allTeams = this.$store.getters.getAllTeams
      let index = -1
      if (allTeams) {
        index = allTeams.findIndex((team) => team.id === id)
      }
      return allTeams[index].abbreviation
    },
  },
  computed: {
    watchedGame() {
      let allGames = this.$store.getters.getAllWatchedGames
      let index = -1
      if (allGames) {
        index = allGames.findIndex((game) => game === this.game.gamePk)
      }
      if (index === -1) {
        return false
      } else {
        return true
      }
    },
    extendedTimeDisplay() {
      if (this.watchedGame) {
        return this.extendedTime
      } else {
        return ''
      }
    },
    awayScores() {
      if (this.watchedGame) {
        return this.away.goals
      } else {
        return '*'
      }
    },
    homeScores() {
      if (this.watchedGame) {
        return this.home.goals
      } else {
        return '*'
      }
    },
  },
  mounted() {
    this.home.goals = this.game.teams.home.goals
    this.home.shortName = this.fetchAndReturnShortName(
      this.game.teams.home.team.id
    )

    this.away.goals = this.game.teams.away.goals
    this.away.shortName = this.fetchAndReturnShortName(
      this.game.teams.away.team.id
    )

    if (this.game.hasShootout) {
      this.extendedTime = 'SO'
    }
    if (!this.game.hasShootout && this.game.periods.length === 4) {
      this.extendedTime = 'OT'
    }
  },
}
</script>

<style scoped>
.game {
  border: 1px solid black;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr;
  box-shadow: 1px 1px 5px 1px #676767;
}
.third-column {
  grid-column: 3;
}
</style>
