<template>
  <div class="stamp-item-container">
    <div class="header">
      <img src="../../assets/close.svg" alt="Close" @click="closeDetails" />
      <h1>{{ stampItem.name }}</h1>
    </div>
    <div class="time-stamp-container">
      <div
        data-cy="sub-stamp"
        class="card"
        v-for="stamp in sortedSubStampArr"
        :key="stamp.id"
        @click="editSubStamp(stamp)"
      >
        <p data-cy="sub-stamp-date">
          {{ stamp.date }}
        </p>
        <p>
          -
        </p>
        <p data-cy="sub-stamp-duration">
          {{ stamp.duration }}
        </p>
      </div>
    </div>
    <EditSubStamp
      data-cy="edit-sub-stamp"
      :stamp="editStamp"
      v-if="displayEditSubStamp"
      @closeEditSubStamp="closeEditSubStamp"
      @saveSubStampItem="saveSubStampItem"
    />
  </div>
</template>

<script>
import moment from 'moment'

import EditSubStamp from './EditSubStamp.vue'

export default {
  emits: ['closeStampItem', 'saveStampItem'],
  props: ['stampItem'],
  data() {
    return {
      editStamp: null,
      displayEditSubStamp: false
    }
  },
  methods: {
    recalculateDuration() {
      let payload = { ...this.stampItem }
      payload.startTime = moment(this.startTime).format('YYYY-MM-DD HH:mm:ss')
      payload.endTime = moment(this.endTime).format('YYYY-MM-DD HH:mm:ss')

      const duration = moment.duration(
        moment(payload.endTime).diff(moment(payload.startTime))
      )
      const milliseconds = duration.asMilliseconds()
      this.duration = moment()
        .hour(0)
        .minute(0)
        .second(milliseconds / 1000)
        .format('HH:mm:ss')
      payload.durationInMs = milliseconds
      payload.duration = this.duration

      this.$emit('saveStampItem', payload)
    },
    saveSubStampItem(payload) {
      this.$emit('saveStampItem', payload)
    },
    closeDetails() {
      this.$emit('closeStampItem')
    },
    editSubStamp(stamp) {
      this.displayEditSubStamp = true
      this.editStamp = stamp
    },
    closeEditSubStamp() {
      this.displayEditSubStamp = false
      this.editStamp = null
    }
  },
  computed: {
    sortedSubStampArr() {
      const tempArr = [...this.stampItem.subStamps]
      tempArr.sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })
      return tempArr
    }
  },
  components: {
    EditSubStamp
  }
}
</script>

<style scoped>
.stamp-item-container {
  position: absolute;
  inset: 10px;
  background-color: #dce1e3;
  z-index: 2;
  padding: 10px;
}
.header {
  display: grid;
}
.header img {
  grid-row: 1;
  grid-column: 1;
}
.header h1 {
  grid-row: 1;
  grid-column: 1;
  margin-top: 0;
  justify-self: center;
}

.time-stamp-container {
  display: grid;
  padding: 40px;
}
.card {
  min-height: 50px;
  display: grid;
  gap: 20px;
  align-content: end;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom: 1px solid black;
}
.card p {
  margin: 0;
}

@media screen and (min-width: 600px) {
  .time-stamp-container {
    padding-left: 200px;
    padding-right: 200px;
  }
}
@media screen and (min-width: 800px) {
  .time-stamp-container {
    padding-left: 400px;
    padding-right: 400px;
  }
}
@media screen and (min-width: 1200px) {
  .time-stamp-container {
    padding-left: 600px;
    padding-right: 600px;
  }
}
</style>
