import * as fb from '../../firebase'

const state = {
  stamps: []
}

const mutations = {
  setStamps(state, val) {
    state.stamps = val
  }
}

const actions = {
  async addStampPost(context, payload) {
    const uid = context.rootState.UserStore.userProfile.uid
    
    fb.stampCollection(uid)
      .add(payload)
      .then(() => {
        console.log('Stamppost added successfully!')
      })
      .catch(err => {
        console.log('Error when adding expense', err)
      })
    context.dispatch('fetchAllStamps', payload)
  },
  async editPost(context, payload) {
    const uid = context.rootState.UserStore.userProfile.uid
    fb.stampCollection(uid).doc(payload.id).set(payload).then(() => {
      console.log('Stamp post successfully edited')
    })
  },
  async fetchAllStamps(context) {
    let stamps = []

    const uid = fb.auth.currentUser.uid
    const allStamps = await fb
      .stampCollection(uid)
      .get()

    allStamps.forEach(stamp => {
      let stampO = stamp.data()
      stampO.id = stamp.id
      stamps.push(stampO)
    })
    // set user profile in state
    context.commit('setStamps', stamps)
  },
  async deleteStampPostFromDb(context, id) {
    const uid = context.rootState.UserStore.userProfile.uid

    fb.stampCollection(uid)
      .doc(id)
      .delete()
      .then(() => {
        console.log('Post removed from database!')
      })
      .catch(err => {
        console.log('Error when deleting goal from db', err)
      })
    context.dispatch('fetchAllStamps', uid)
  }
}

const getters = {
  getAllStamps(state) {
    console.log('hejsan')
    return state.stamps
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
