<template>
  <div class="group-card" v-if="totalTime">
    <p>
      {{ timeGroup.name }}
    </p>
    <p>
      {{ totalTime }}
    </p>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: ['timeGroup', 'filter'],
  computed: {
    totalTime() {
      let totalTimeInMs = 0
      let result
      if (!this.timeGroup.stamps) {
        result = '00:00:00'
      } else {
        this.timeGroup.stamps.forEach(stamp => {
          if (stamp.ended && this.filter === '') {
            totalTimeInMs += stamp.durationInMs
          } else if (stamp.ended && this.filter === 'today') {
            let date = moment().format('yyyy-MM-DD')
            if (stamp.date === date) {
              totalTimeInMs += stamp.durationInMs
            }
          } else if (stamp.ended && this.filter === 'thisWeek') {
            if (moment(stamp.date).isSame(new Date(), 'week')) {
              totalTimeInMs += stamp.durationInMs
            }
          } else if (stamp.ended && this.filter === 'thisMonth') {
            if (moment(stamp.date).isSame(new Date(), 'month')) {
              totalTimeInMs += stamp.durationInMs
            }
          }
        })

        const duration = moment.duration(totalTimeInMs / 1000 / 60, 'minutes')
        let hours = Math.floor(duration.asHours())
        let minutes = Math.floor(duration.asMinutes()) - hours * 60
        let seconds =
          Math.floor(duration.asSeconds()) - hours * 60 * 60 - minutes * 60

        if (hours < 10) {
          hours = '0' + hours
        }
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        if (seconds < 10) {
          seconds = '0' + seconds
        }
        result = `${hours}:${minutes}:${seconds}`
      }

      if (result === '00:00:00' && this.filter !== '') return false

      return result
    }
  }
}
</script>

<style scoped>
.group-card {
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 1px 3px 5px 2px #676767;
  height: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 600px) {
  .group-card {
    height: 100px;
  }
}
</style>
