<template>
	<div class="day-time-container">
		<div class="group-container">
			<div class="time-group" v-if="!displayTimeGroups" @click="displayTimeGroups = !displayTimeGroups">
				Show summary
			</div>
			<span v-if="displayTimeGroups" @click="displayTimeGroups = !displayTimeGroups">
				<div
					class="time-group"
					v-for="timeGroup in getGroupTimes"
					:key="timeGroup.id"
				>
					<p>{{ timeGroup.name }}</p>
					<p>{{ timeGroup.duration }}</p>
				</div>
			</span>
		</div>
		<daily-calendar
			:stamps="getAllDailyTimeStamps"
			:date="date"
		></daily-calendar>
	</div>
</template>

<script>
	import moment from 'moment'

	import DailyCalendar from './DailyCalendar.vue'

	export default {
		components: { DailyCalendar },
		props: ['date'],
		data() {
			return {
				displayTimeGroups: false
			}
		},
		methods: {
			calculateDurationFromMs(ms) {
				const duration = moment.duration(ms / 1000 / 60, 'minutes')
				let hours = Math.floor(duration.asHours())
				let minutes = Math.floor(duration.asMinutes()) - hours * 60
				let seconds =
					Math.floor(duration.asSeconds()) - hours * 60 * 60 - minutes * 60

				if (hours < 10) {
					hours = '0' + hours
				}
				if (minutes < 10) {
					minutes = '0' + minutes
				}
				if (seconds < 10) {
					seconds = '0' + seconds
				}
				return `${hours}:${minutes}:${seconds}`
			}
		},
		computed: {
			getGroupTimes() {
				let timeGroups = [...this.$store.getters.getTimeGroups]

				let timeGroupsTemp = []
				timeGroups.forEach((timeGroup) => {
					if (timeGroup.stamps) {
						timeGroup.stamps.forEach((stamp) => {
							// Checking if stamp date is todays date
							if (stamp.date === this.date) {
								// Checking if group is already in tempArr
								let foundIndex = timeGroupsTemp.findIndex(
									(_timeGroup) => _timeGroup.name === timeGroup.name
								)
								// If it is not present, add it, else add stamp to it
								if (foundIndex === -1) {
									let groupO = {
										name: timeGroup.name,
										stamps: [stamp]
									}
									timeGroupsTemp.push(groupO)
								} else {
									timeGroupsTemp[foundIndex].stamps.push(stamp)
								}
							}
						})
					}
				})

				// Adding total duration for all groups
				timeGroupsTemp.forEach((timeGroup) => {
					let totalTimeInMs = 0
					timeGroup.stamps.forEach((stamp) => {
						if (stamp.ended) {
							totalTimeInMs += stamp.durationInMs
						}
					})
					timeGroup.durationInMs = totalTimeInMs

					timeGroup.duration = this.calculateDurationFromMs(totalTimeInMs)
				})
				return timeGroupsTemp
			},
			getAllDailyTimeStamps() {
				let timeGroups = [...this.$store.getters.getTimeGroups]

				let stamps = []

				timeGroups.forEach((timeGroup) => {
					if (timeGroup.stamps) {
						timeGroup.stamps.forEach((stamp) => {
							if (stamp.date === this.date) {
								let stampO = { ...stamp }
								stampO.group = timeGroup.name
								stamps.push(stampO)
							}
						})
					}
				})

				stamps = stamps.sort((a, b) => {
					return new Date(a.startTime) - new Date(b.startTime)
				})

				stamps.forEach((stamp) => {
					if (stamp) {
						stamp.startTime = stamp.startTime.split(' ')[1]
						if (stamp.endTime) {
							stamp.endTime = stamp.endTime.split(' ')[1]
						} else {
							// stamp.endTime = 'Now'
							stamp.endTime = moment().format('HH:mm:ss')
						}
					}
				})
				return stamps
			}
		},
		component: {
			DailyCalendar
		}
	}
</script>

<style scoped>
	.day-time-container {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
	.group-container {
		display: grid;
		gap: 10px;
		padding: 30px;
		background-color: #f1eeee;
	}
	.time-group {
		border-bottom: 1px solid black;
		display: grid;
		grid-template-columns: 1fr 1fr;
		padding: 5px;
	}
	.time-group p {
		margin: 0;
	}

	.stamp-container {
		display: grid;
		padding: 20px;
		gap: 40px;
	}
	.stamp {
		border: 1px solid black;
		border-radius: 5px;
		box-shadow: 0px 0px 5px 1px #676767;
		min-height: 50px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 5px;
		align-content: flex-start;
		justify-content: center;
		padding: 20px;
	}
	.stamp p {
		margin: 0;
	}
	.start-time {
		grid-row: 1;
		grid-column: 1;
	}
	.stamp-group {
		font-size: 0.8rem;
		grid-row: 1;
		grid-column: 2;
	}
	.name {
		grid-row: 2;
		grid-column: 2;
	}
</style>
