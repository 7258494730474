<template>
  <div class="sub-task">
    <img
      class="checkbox"
      src="../../assets/tasklist/unchecked.svg"
      alt="unchecked"
      v-if="subTask.status === 'new'"
      @click="toggleSubTaskStatus"
    />
    <img
      class="checkbox"
      src="../../assets/tasklist/checked.svg"
      alt="checked"
      v-if="subTask.status === 'done'"
      @click="toggleSubTaskStatus"
    />
    <span>
      {{ subTask.name }}
    </span>
  </div>
</template>

<script>
export default {
  emits: ['toggleSubTaskStatus'],
  props: ['subTask'],
  methods: {
    toggleSubTaskStatus() {
      this.$emit('toggleSubTaskStatus', this.subTask)
    }
  }
}
</script>

<style scoped>
.sub-task {
  border-bottom: 1px solid #4e5166;
  height: 30px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  padding-left: 10px;
}

.sub-task img {
  grid-row: 1;
  grid-column: 1;
  height: 25px;
  align-self: center;
}

.sub-task span {
  grid-column: 1;
  grid-row: 1;
  justify-self: center;
  align-self: end;
}
</style>
