<template>
	<div class="change-parent-container">
		<header>
			<img src="@/assets/close.svg" alt="Close" @click="closeChangeParent" />
			<h1>
				Change Parent
			</h1>
		</header>
		<main>
			<select v-model="parentId">
				<option value="none">None</option>
				<option v-for="item in containers" :key="item.id" :value="item.id">
					{{ item.name }}
				</option>
			</select>
			<input type="button" value="Save" @click="changeParent" />
		</main>
	</div>
</template>

<script>
	export default {
		emits: ['closeChangeParent', 'changeParent'],
		props: ['allItems', 'itemId'],
		data() {
			return {
				parentId: 'none'
			}
		},
		methods: {
			closeChangeParent() {
        console.log('hejsan')
				this.$emit('closeChangeParent')
			},
			changeParent() {
				if (this.parentId === 'none') this.parentId = null

				this.$emit('changeParent', this.parentId)
				this.closeChangeParent()
			}
		},
		computed: {
			containers() {
				let containers = this.allItems.filter(
					(item) => item.container === true || item.container === 'true'
				)
				const index = containers.findIndex(
					(container) => container.id === this.itemId
				)
				if (index === -1) return containers
				containers.splice(index, 1)
				return containers
			}
		}
	}
</script>

<style scoped>
	.change-parent-container {
		position: absolute;
		inset: 0;
		background-color: #dce1e3;
		z-index: 3;
		height: 100%;
	}
	header {
		display: grid;
		padding: 20px;
	}
	header img {
		grid-row: 1;
		grid-column: 1;
		justify-self: start;
		align-self: center;
	}
	header h1 {
		grid-row: 1;
		grid-column: 1;
		margin: 0;
		justify-self: center;
	}
</style>
