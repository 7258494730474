<template>
  <div>{{ formattedTimer }}</div>
  <input type="text" placeholder="Name" v-model="name" @keyup.enter="clockIn" />
  <button v-if="!stampedIn" @click="clockIn">Stamp in</button>
  <button v-else @click="clockOut">Stamp out</button>
</template>

<script>
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

export default {
  emits: ['stampIn', 'stampOut'],
  props: ['stamp'],
  data() {
    return {
      elapsedTime: 0,
      timer: null,
      name: '',
      stampedIn: false
    }
  },
  methods: {
    startTimer() {
      if (this.timer) return
      console.log('starTimer')
      this.timer = setInterval(() => {
        this.elapsedTime += 1000
      }, 1000)
    },
    clockIn() {
      this.startTime = moment().format('YYYY-MM-DD HH:mm:ss')
      let date = moment().format('YYYY-MM-DD')
      this.stampedIn = true
      let postObject = {
        name: this.name,
        startTime: this.startTime,
        ended: false,
        date: date,
        id: uuidv4()
      }

      this.$emit('stampIn', postObject)
      this.startTimer()
    },
    clockOut() {
      // setting an endtime
      let stampOut = moment().format('YYYY-MM-DD HH:mm:ss')

      // duration of stamp calculation
      const formatted = this.formattedTimer

      // setting the value of the post
      const postObject = this.stamp
      postObject.ended = true
      postObject.endTime = stampOut
      postObject.duration = formatted
      postObject.durationInMs = this.elapsedTime

      // setting back to original values and clearing the timer
      this.stampedIn = false
      clearInterval(this.timer)
      this.elapsedTime = 0
      this.timer = null

      this.$emit('stampOut', postObject)
      this.name = ''
    }
  },
  computed: {
    formattedTimer() {
      return moment()
        .hour(0)
        .minute(0)
        .second(this.elapsedTime / 1000)
        .format('HH:mm:ss')
    }
  },
  mounted() {
    // starts timer if there is a stamp and the stamp is not ended
    if (this.stamp) {
      if (!this.stamp.ended) {
        let now = moment().format('YYYY-MM-DD HH:mm:ss')
        let duration = moment.duration(
          moment(now).diff(moment(this.stamp.startTime))
        )

        this.elapsedTime = duration.asMilliseconds()
        this.startTimer()
        this.stampedIn = true
        this.name = this.stamp.name
      }
    }
  },
  // watch in case the data updates from anotther location
  watch: {
    stamp: function(newVal) {
      if (newVal) {
        let now = moment().format('YYYY-MM-DD HH:mm:ss')
        let duration = moment.duration(
          moment(now).diff(moment(newVal.startTime))
        )

        this.elapsedTime = duration.asMilliseconds()
        this.startTimer()
        this.stampedIn = true
        this.name = newVal.name
      } else {
        if (this.stampedIn) {
          this.stampedIn = false
          clearInterval(this.timer)
          this.elapsedTime = 0
          this.timer = null

          this.name = ''
        }
      }
    }
  }
}
</script>
