<template>
  <div class="entries-container">
    <div
      class="entry-container"
      v-for="entry in getEntriesForTheDay"
      :key="entry.time"
    >
      <div class="entry-header">
        {{ entry.time }}
      </div>
      <div class="entry-body">
        <div class="entry-content">
          {{ entry.entry }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['date'],
  computed: {
    getEntriesForTheDay() {
      // Empty arr to store entries in
      let entries = []
      // Copies arr from the store
      let allNotes = [...this.$store.getters.getAllNotes]

      // Finds note wiht same date as this.date
      allNotes.forEach(_note => {
        if (_note.date === this.date) {
          // Spreads it
          let noteO = { ..._note }
          // Twice
          entries = [...noteO.entry]
        }
      })

      // Removes date from time string 2021-11-11 23:50:00 becomes 23:50:00
      if (entries) {
        entries.forEach(entry => {
          if (entry.time) {
            // This code should not be necessary, I do not understand why it is needed.. FUCK THIS
            entry.time = entry.time.split(' ')[1]
              ? entry.time.split(' ')[1]
              : entry.time
          }
        })
      }
      return entries.length > 0 ? entries : [{ entry: 'No entries yet' }]
    }
  }
}
</script>

<style scoped>
.entries-container {
  display: grid;
  gap: 20px;
  padding: 20px;
  overflow: auto;
}
.entry-container {
  border: 1px solid black;
}
.entry-header {
  background-color: #2d3142;
  color: #fff;
  text-align: start;
  padding: 5px;
}
.entry-content {
  padding: 10px;
  line-height: 20px;
  background-color: white;
  text-align: start;
  white-space: pre-line;
}
</style>
