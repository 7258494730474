<template>
  <div class="main-container" :class="{ 'page-lock': displayFullNote }">
    <div>
      <div class="header-bar">
        <h1>Journal</h1>
        <button tabindex="1" class="search-button" @click="initSearch" data-cy="toggleSearchButton">Search</button>
      </div>
    </div>
    <div v-if="displaySearchBar">
      <input
        type="text"
        ref="searchInput"
        data-cy="searchNoteInput"
        v-model="searchInput"
      />
      <span data-cy="searchNoteLengthSpan">{{ searchNotes.length }}</span>
    </div>
    <div v-if="!displaySearchBar">
      <div class="filter-bar">
        <journal-filter @change-filter="setFilters"></journal-filter>
        <button tabindex="2" class="btn" @click="showAddNote" data-cy="startAddNoteButton">
          Add note
        </button>
      </div>
    </div>
    <!-- <teleport to="body"> -->
    <teleport to=".app-container">
      <event-journal
        data-cy="eventJournal"
        v-if="displayFullNote"
        :editNote="updatedFullNote"
        @closeEditNote="closeEditNote"
        @saveNoteInDb="saveNoteInDb"
        @deleteNote="deleteNote"
        @saveDetails="saveNoteInDb"
      >
      </event-journal>
    </teleport>
    <add-note-form
      data-cy="addNoteForm"
      v-if="displayAddNote"
      @add-note="addNote"
      @cancel-add-note="hideAddNote"
    ></add-note-form>
    <div class="note-container" v-if="!displaySearchBar">
      <Note
        v-for="(note, index) in filteredNotes"
        :tabindex="index + 3"
        :key="note.id"
        :note="note"
        @viewFullNote="viewFullNote"
      ></Note>
    </div>
    <div class="note-container" v-if="displaySearchBar">
      <Note
        v-for="note in searchNotes"
        :key="note.id"
        :note="note"
        @viewFullNote="viewFullNote"
      ></Note>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import Note from '@/components/journal/Note'
import JournalFilter from '@/components/journal/JournalFilter'
import AddNoteForm from '@/components/journal/AddNoteForm'
import EventJournal from '../components/journal/EventJournal'

export default {
  data() {
    return {
      displayAddNote: false,
      localNotes: [],
      displayFullNote: false,
      displaySearchBar: false,
      searchInput: '',
      singleNote: '',
      filters: {
        dailyFilter: true,
        randomFilter: false
      },
      scrollPosition: 0
    }
  },
  methods: {
    initSearch() {
      this.displaySearchBar = !this.displaySearchBar
      this.$nextTick().then(() => {
        if (this.displaySearchBar) this.$refs.searchInput.focus()
      })
    },
    setFilters(updatedFilters) {
      this.filters = updatedFilters
    },
    viewFullNote(note) {
      this.singleNote = note
      this.displayFullNote = true
      this.scrollPosition = window.scrollY
    },
    closeEditNote() {
      this.displayFullNote = false
      this.singleNote = ''
      this.$nextTick().then(() => {
        window.scroll(0, this.scrollPosition)
      })
    },
    showAddNote() {
      this.displayAddNote = true
    },
    hideAddNote() {
      this.displayAddNote = false
    },
    addNote(noteObject) {
      this.$store.dispatch('addNoteToDb', noteObject)
      this.hideAddNote()
    },
    saveNoteInDb(note) {
      this.$store.dispatch('saveNoteInDb', note)
    },
    deleteNote(note) {
      this.$store.dispatch('deleteNoteFromDb', note)
    }
  },
  computed: {
    ...mapGetters(['getAllNotes']),
    ...mapActions(['addNoteToDb']),
    filteredNotes() {
      return this.getAllNotes.filter(note => {
        if (this.filters.randomFilter && note.option.includes('random')) {
          return true
        }
        if (this.filters.dailyFilter && note.option.includes('daily')) {
          return true
        }
        return false
      })
    },
    searchNotes() {
      let allNotes = this.getAllNotes
      if (this.searchInput.length < 1) return allNotes
      return allNotes.filter(note => {
        if (note.title.toLowerCase().includes(this.searchInput)) return true
        if (
          typeof note.entry === 'string' &&
          note.entry.toLowerCase().includes(this.searchInput)
        )
          return false
        if (
          typeof note.entry === 'object' &&
          note.entry.some(entry =>
            entry.entry.toLowerCase().includes(this.searchInput)
          )
        )
          return true
      })
    },
    updatedFullNote() {
      return this.getAllNotes.find(note => note.id === this.singleNote.id)
    }
  },
  components: {
    Note,
    JournalFilter,
    AddNoteForm,
    EventJournal
  }
}
</script>

<style scoped>
.header-bar {
  position: fixed;
  top: 0px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  z-index: 0;
}
h1 {
  text-align: center;
  justify-self: center;
  z-index: 1;
  grid-row: 1;
}

.search-button {
  position: fixed;
  right: 10px;
  top: 10px;
}

.main-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 100px;
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  padding: 5px;
}

.note-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 100px;
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  padding: 5px;
}

.page-lock {
  overflow-y: hidden;
  display: none;
}

.filter-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
  position: fixed;
  top: 120px;
  left: 0px;
}
@media screen and (min-width: 900px) {
  .page {
    padding-left: 400px;
    padding-right: 400px;
  }
}
</style>
