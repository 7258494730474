<template>
	<h1>Shoppinglist</h1>
	<form @submit.prevent autocomplete="off">
		<input type="text" v-model="itemInput"  data-cy="add-name-input"/>
		<button @click="dispatchShoppingItem" class="btn-primary" data-cy="add-item-button">Add</button>
	</form>
	<div class="shopping-container" v-if="itemInput.length > 0">
		<shopping-item
			data-cy="filtered-item"
			v-for="item in filteredBoughtItems"
			:item="item"
			:key="item.id"
			class="shopping-item"
			@toggleItemStatus="toggleItemStatus"
		></shopping-item>
	</div>
	<div class="shopping-container" v-if="itemInput.length === 0">
		<shopping-item
			data-cy="new-shopping-item"
			v-for="item in allNewItems"
			:item="item"
			:key="item.id"
			class="shopping-item"
			@toggleItemStatus="toggleItemStatus"
		></shopping-item>
	</div>
	<h2 v-if="itemInput.length === 0">Bought:</h2>
	<div class="shopping-container" v-if="itemInput.length === 0">
		<shopping-item
			data-cy="bought-shopping-item"
			v-for="item in allBoughtItems"
			:item="item"
			:key="item.id"
			class="shopping-item"
			@toggleItemStatus="toggleItemStatus"
		></shopping-item>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex'

	import ShoppingItem from '../components/shoppinglist/ShoppingItem'

	export default {
		data() {
			return {
				itemInput: ''
			}
		},
		methods: {
			dispatchShoppingItem() {
				if (this.itemInput) {
					let shoppingO = {
						name: this.itemInput,
						status: 'new'
					}
					this.$store.dispatch('addShoppingItem', shoppingO)
				}
				this.itemInput = ''
			},
			toggleItemStatus(item) {
				if (item.status === 'new') {
					item.status = 'bought'
				} else if (item.status === 'bought') {
					item.status = 'new'
					this.itemInput = ''
				}
				this.$store.dispatch('changeShoppingItem', item)
			}
		},
		computed: {
			...mapGetters(['returnAllShoppingItems', 'getUser']),
			...mapActions([
				'fetchAllShoppingItems',
				'addShoppingItem',
				'changeShoppingItem'
			]),
			allNewItems() {
				let returnVal
				if (this.returnAllShoppingItems.length >= 1) {
					returnVal = this.returnAllShoppingItems.filter(
						(note) => note.status === 'new'
					)
				} else {
					returnVal = null
				}
				return returnVal
			},
			allBoughtItems() {
				let returnVal
				if (this.returnAllShoppingItems.length >= 1) {
					returnVal = this.returnAllShoppingItems.filter(
						(item) => item.status === 'bought'
					)
				} else {
					returnVal = null
				}
				return returnVal
			},
			filteredBoughtItems() {
				let returnVal = this.returnAllShoppingItems.filter(item => item.status === 'bought')
				returnVal = returnVal.filter(item => item.name.includes(this.itemInput))
				return returnVal
			}
		},
		components: {
			ShoppingItem
		}
	}
</script>

<style scoped>
	form input {
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: 1px solid black;
		margin-right: 5px;
		margin-bottom: 10px;
	}
	form input:focus {
		box-shadow: 1px 1px 1px 1px #676767;
	}

	.shopping-container {
		justify-content: center;
		padding: 10px;
		display: grid;
		grid-template-columns: repeat(4, 70px);
		grid-gap: 10px;
		grid-template-rows: auto;
		list-style-type: none;
	}
</style>
