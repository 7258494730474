<template>
	<div class="small-cli-container">
		<input
			class="input"
			v-model="input"
			ref="input"
			type="text"
			@keyup.esc="closeCli"
      @keyup.enter="goToCli"
		/>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				input: ''
			}
		},
		emits: ['closeCli'],
		methods: {
			closeCli() {
				this.$emit('closeCli')
			},
      goToCli() {
        let command = this.input.split(':')[1]
        this.$router.push('/cli/' + command)
        this.$emit('closeCli')
      }
		},
		mounted() {
			this.$refs.input.focus()
		}
	}
</script>

<style scoped>
	.small-cli-container {
		z-index: 99;
		position: fixed;
		bottom: 0;
		width: 100%;
	}

	.input {
		width: 100%;
		text-align: start;
    padding-left: 10px;
	}
</style>
