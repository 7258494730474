<template>
  <div class="backdrop" @keyup.esc="closeFullNote">
    <div class="modal" @keyup.esc="closeFullNote">
      <event-journal-header
        :option="editNote.option"
        :title="editNote.title"
        @emitDisplayDetails="listenForDisplayDetails"
        @deleteNote="deleteNote"
        @closeFullNote="closeFullNote"
      ></event-journal-header>
      <EditNoteDetails
        v-if="displayDetails"
        :note="editNote"
        @saveDetails="saveDetails"
        @closeDetails="closeDetails"
      ></EditNoteDetails>
      <div class="text-area-container">
        <div class="add-event-button-div">
          <div
            tabindex="1"
            class="checkbox"
            data-cy="addEntryEvent"
            :class="{ checked: displayAddEvent, unchecked: !displayAddEvent }"
            @click="addEvent"
            @keyup.enter="addEvent"
            @keyup.space="addEvent"
          >
            Add Event
          </div>
          <div
            data-cy="dailyQuestionsButton"
            v-if="displayAddEvent"
            class="daily-question-button"
            :class="{
              checked: displayDailyQuestions,
              unchecked: !displayDailyQuestions
            }"
            @click="displayDailyQuestions = !displayDailyQuestions"
          >
            Daily Questions
          </div>
        </div>

        <event-journal-entry
          v-if="displayAddEvent && !displayDailyQuestions"
          class="enter"
          :entryObject="editEntryContent"
          @saveEntry="saveEntry"
          @closeEntry="addEvent"
        ></event-journal-entry>

        <DailyQuestionsForm
          v-if="displayDailyQuestions"
          @saveQuestions="saveDailyQuestions"
        ></DailyQuestionsForm>
        <div v-if="splitEntries" class="entry">
          <div class="content" v-if="dailyQuestions">
            <div class="content-header">
              <span>{{ dailyQuestions.time }}</span>
            </div>
            <p class="content-body">
              <span> Steps: {{ dailyQuestions.steps }} </span>
              <span> Day: {{ dailyQuestions.dayRating }}/5 </span>
            </p>
          </div>
          <entry-content
            data-cy="entryContent"
            v-for="entry in filteredByTime"
            :key="entry.time"
            :entry="entry"
            :option="editNote.option"
            @editEntry="editEntry"
          ></entry-content>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import EditNoteDetails from './EditNoteDetails'
import EventJournalEntry from './EventJournalEntry'
import EntryContent from './EntryContent'
import EventJournalHeader from './EventJournalHeader'
import DailyQuestionsForm from './DailyQuestionsForm'

export default {
  emits: ['closeEditNote', 'saveNoteInDb', 'deleteNote', 'saveDetails'],
  props: ['editNote'],
  data() {
    return {
      splitEntries: false,
      editEntryContent: null,
      displayOptions: false,
      displayAddEvent: false,
      localNote: '',
      displayDetails: false,
      displayDailyQuestions: false
    }
  },
  methods: {
    saveDailyQuestions(payload) {
      this.localNote = this.editNote
      let questionsObject = {
        time: moment().format('HH:mm:ss'),
        dayRating: payload.dayRating,
        steps: payload.steps
      }
      this.localNote.questions = questionsObject
      this.$emit('saveNoteInDb', this.localNote)

      this.displayAddEvent = false
      this.displayDailyQuestions = false
    },
    listenForDisplayDetails() {
      this.displayDetails = !this.displayDetails
    },
    closeDetails() {
      this.displayDetails = false
    },
    saveDetails(note) {
      this.$emit('saveDetails', note)
      this.closeDetails()
    },
    deleteNote() {
      this.$emit('deleteNote', this.editNote)
      this.$emit('closeEditNote')
    },
    addEvent() {
      if (this.displayAddEvent) {
        this.displayAddEvent = false
        this.displayDailyQuestions = false
        return
      }
      let entryO = {
        time: moment().format('yyyy-MM-DD HH:mm:ss'),
        entry: ''
      }
      this.displayAddEvent = true
      this.editEntryContent = entryO
    },
    editEntry(entry) {
      this.displayAddEvent = true
      this.editEntryContent = entry
    },
    closeFullNote() {
      this.$emit('closeEditNote')
    },
    saveEntry(entryO) {
      this.localNote = this.editNote
      console.log('entryO', this.localNote)

      let index = this.localNote.entry.findIndex(x => x.time === entryO.time)

      // check if it is edit or addition
      if (index === -1) this.localNote.entry.push(entryO)
      if (index >= 0) this.localNote.entry[index] = entryO

      this.$emit('saveNoteInDb', this.localNote)

      this.displayAddEvent = false
      this.localNote = ''
      this.editEntryContent = ''
    },
    convertEntryToArrayAndSave() {
      // Is needed because old entries were saved as string
      this.localNote = this.editNote
      let tempEntry = this.localNote.entry
      this.localNote.entry = []
      let entryO = {
        time: moment().format('HH:mm:ss'),
        entry: tempEntry
      }
      this.localNote.entry.push(entryO)
      this.$emit('saveNoteInDb', this.localNote)
    },
    initCheck() {
      if (typeof this.editNote.entry === 'object') {
        this.splitEntries = true
        if (this.editNote.entry.length < 1) {
          this.addEvent()
        }
      } else if (typeof this.editNote.entry === 'string') {
        this.convertEntryToArrayAndSave()
        this.splitEntries = false
        this.editEntryContent = this.editNote
      }
    },
    checkTimeAndChangeDate(arr) {
      arr.forEach(entry => {
        let time = entry.time.split(' ')
        if (time.length === 1) {
          // Check if time is between 00 and 06.30 to set date to next day
          let tempTime = moment(entry.time, 'HH:mm:ss')
          let date = this.editNote.date
          let beforeTime = moment('00:00:00', 'HH:mm:ss')
          let afterTime = moment('06:30:00', 'HH:mm:ss')

          if (tempTime.isBetween(beforeTime, afterTime)) {
            date = moment(date, 'yyyy:MM:DD').add(1, 'days')
            date = date.format('yyyy-MM-DD')
          }
          tempTime = tempTime.format('HH:mm:ss')
          entry.time = date + ' ' + tempTime
        }
      })
      return arr
    }
  },
  computed: {
    dailyQuestions() {
      return this.editNote.questions
    },
    filteredByTime() {
      let retVal = this.editNote.entry
      retVal = this.checkTimeAndChangeDate(retVal)
      return retVal.sort((a, b) => {
        return new Date(a.time) - new Date(b.time)
      })
    }
  },
  beforeMount() {
    this.initCheck()
  },
  watch: {
    editNote: function() {
      this.initCheck()
    }
  },
  components: {
    EventJournalEntry,
    EditNoteDetails,
    EntryContent,
    EventJournalHeader,
    DailyQuestionsForm
  }
}
</script>

<style scoped>
.backdrop {
  z-index: 3;
  overflow-x: hidden;
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: rgba(80, 69, 69, 0.835);
}

.modal {
  z-index: 4;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: #dce1e3;
  box-shadow: 1px 10px 10px 1px #676767;
  border: 1px solid #676767;
  display: flex;
  flex-direction: column;
}
.entry {
  flex-grow: 1;
  padding: 20px;
  background-color: #dce1e3;
  font-size: 1rem;
  outline: none;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
}

.input {
  width: 98%;
  height: 100%;
}

.text-area-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.add-event-button-div {
  height: 100px;
  display: flex;
  justify-content: center;
}
.add-event-button-div button {
  height: 40px;
}
.checkbox {
  align-self: center;
  border-radius: 5px;
  width: 80px;
  height: 40px;
  border: 1px solid black;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-right: 5px;
  user-select: none;
}

.daily-question-button {
  align-self: center;
  border-radius: 5px;
  width: 70px;
  height: 35px;
  border: 1px solid black;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-right: 5px;
  user-select: none;
}

.checked {
  box-shadow: inset 1px 1px 1px 1px #676767;
  transition-duration: 0.5s;
}
.unchecked {
  box-shadow: 1px 1px 1px 1px #676767;
}

.enter {
  height: 300px;
  z-index: 4;
}

.content {
  min-height: 100px;
  border-radius: 5px;
  border: 1px solid #727272;
  background-color: #eef0f2;
  box-shadow: 1px 5px 5px 1px #676767;
}

.content-header {
  background-color: #2d3142;
  color: white;
  height: 30px;
  border-bottom: 1px solid black;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  padding-left: 10px;
  padding-right: 10px;
}
.content-header span {
  grid-row: 1;
  align-self: center;
}
.content-header div {
  grid-row: 1;
  justify-self: end;
}
.content-header img {
  height: 27px;
}
.content-body {
  white-space: pre-line;
  background-color: #f4f5f6;
  padding: 10px;
}
</style>
