<template>
  <div
    class="item"
    :class="{
      _container: item.container === 'true' || item.container === true,
      _item: item.container === 'false' || item.container === false
    }"
    @click="openItem"
  >
    {{ item.name }}
  </div>
</template>

<script>
export default {
  emits: ['openItem'],
  props: ['item'],
  methods: {
    openItem() {
      this.$emit('openItem', this.item.id)
    }
  }
}
</script>

<style scoped>
.item {
  border: 1px solid black;
  border-radius: 5px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: black;
}
._container {
  background-color: #05204a;
  color: white;
}
._item {
  background-color: #88292F;
}
</style>
