<template>
  <div class="backdrop" @click="closeDetails">
    <div class="details">
      <div class="details-first-row">
        <img
          data-cy="closeNoteDetailsButton"
          src="../../assets/close.svg"
          alt="close"
          @click="closeDetails"
        />
        <input
          data-cy="editNoteNameInput"
          type="text"
          :placeholder="note.title"
          v-model="titleInput"
        />
      </div>
      <input
        data-cy="editNoteDateInput"
        type="text"
        id="date-input"
        :placeholder="note.date"
        v-model="dateInput"
      />
      <button data-cy="saveNoteDetailsButton" @click="saveDetails">Save</button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['saveDetails', 'closeDetails'],
  props: ['note'],
  data() {
    return {
      titleInput: '',
      dateInput: ''
    }
  },
  methods: {
    closeDetails(event) {
      if (
        event.target.className === 'backdrop' ||
        event.target.alt === 'close'
      ) {
        this.$emit('closeDetails')
      }
    },
    saveDetails() {
      let payload = this.note
      if (this.titleInput && this.dateInput) {
        payload.title = this.titleInput
        payload.date = this.dateInput
        this.$emit('saveDetails', payload)
      } else if (this.titleInput && !this.dateInput) {
        payload.title = this.titleInput
        this.$emit('saveDetails', payload)
      } else if (this.dateInput && !this.titleInput) {
        payload.date = this.dateInput
        this.$emit('saveDetails', payload)
      }
    }
  }
}
</script>

<style scoped>
.details {
  background-color: blanchedalmond;
  position: absolute;
  z-index: 99;
  right: 10px;
  left: 10px;
  top: 70px;
  bottom: 500px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
@media screen and (min-width: 900px) {
  .details {
    margin-left: 200px;
    margin-right: 200px;
  }
}
@media screen and (min-width: 1100px) {
  .details {
    margin-left: 400px;
    margin-right: 400px;
  }
}
.details-first-row {
  display: flex;
  flex-grow: 1;
}
.details-first-row img {
  align-self: flex-start;
  height: 20px;
}
.details-first-row input {
  align-self: center;
  flex-grow: 1;
  /* height: 20px; */
}
.details input {
  margin-left: 5px;
  margin-right: 20px;
  margin-bottom: 5px;
}
#date-input {
  margin-left: 25px;
}
.details button {
  width: 60px;
  height: 40px;
  align-self: center;
}
</style>
