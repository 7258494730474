<template>
  <img
    id="show-side-bar"
    src="../assets/quotes/show.svg"
    alt="show side-bar"
    @click="toggleSideBar"
  />
  <div
    class="side-bar"
    :class="{
      'show-side-bar': displaySideBar,
      'hide-side-bar': !displaySideBar
    }"
  >
    <div class="side-bar-header">
      <img
        id="close-icon"
        src="../assets/close.svg"
        alt="show side-bar"
        @click="toggleSideBar"
      />
      <h2>{{ username }}</h2>
    </div>
    <div class="side-bar-body">
      <div class="side-bar-link" @click="goToLink" id="home">Home</div>
      <div class="side-bar-links" v-if="!displayTaskGroupings">
        <div class="side-bar-link" @click="goToLink" id="journal">Journal</div>
        <div class="side-bar-link" @click="goToLink" id="taskgroups">
          Tasklist
        </div>

        <div class="side-bar-link" @click="goToLink" id="budget">Budget</div>
        <div class="side-bar-link" @click="goToLink" id="shoppinglist">
          Shopping list
        </div>
        <div class="side-bar-link" @click="goToLink" id="quotes">Quotes</div>
        <div class="side-bar-link" @click="goToLink" id="random-quotes">
          Random Quotes
        </div>
        <div class="side-bar-link" @click="goToLink" id="userpage">
          User Page
        </div>
      </div>
      <div v-else class="side-bar-links">
        <div class="side-bar-link" @click="goToLink" id="taskgroups">
          Tasklist
        </div>
        <div
          data-cy="group-link"
          class="side-bar-link"
          v-for="group in groups"
          :key="group.id"
          @click="goToTaskGroup(group)"
        >
          {{ group.name }}
        </div>
        <div class="side-bar-link" v-if="displayAddGroup">
          <input data-cy="add-group-name-input" type="text" v-model="name" />
          <button data-cy="addGroupButton" @click="addGroup">Add</button>
        </div>
        <button data-cy="start-add-group-button" @click="startAddGroup">Add Group</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      displaySideBar: false,
      displayAddGroup: false,
      name: ''
    }
  },
  methods: {
    goToLink(event) {
      this.$router.push('/' + event.target.id)
      this.displaySideBar = false
    },
    toggleSideBar() {
      this.displaySideBar = !this.displaySideBar
    },
    startAddGroup() {
      this.displayAddGroup = !this.displayAddGroup
    },
    addGroup() {
      if (!this.name) return
      let payload = {
        name: this.name,
        container: true,
        tasks: []
      }
      this.startAddGroup()
      this.$store.dispatch('addGroupToDb', payload)
    },
    goToTaskGroup(group) {
      this.$router.push('/taskgroup/' + group.id)
      this.displaySideBar = false
    }
  },
  computed: {
    displayTaskGroupings() {
      return this.$route.name === 'Tasks' || this.$route.name === 'TaskGroup' || this.$route.name === 'TaskGroups'
    },
    groups() {
      return this.$store.getters.returnAllGroups
    },
    username() {
      return this.$store.getters.getUser.name
    }
  }
}
</script>

<style scoped>
#show-side-bar {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1;
}

.side-bar {
  border: 1px solid black;
  position: fixed;
  background-color: #dce1e3;
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-height: 100vh;
  width: 80vw;
  z-index: 99;
  overflow: auto;
}

@media screen and (min-width: 900px) {
  .side-bar {
    width: 30vw;
  }
}

.show-side-bar {
  top: 0px;
  left: 0px;
  transition: left 0.5s;
}

.hide-side-bar {
  top: 0px;
  left: -100vw;
  transition: left 0.5s;
}

.side-bar-header {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.side-bar-header h2 {
  grid-row-start: 1;
  justify-self: center;
  align-self: center;
  margin: 0;
}

#close-icon {
  justify-self: end;
  grid-column-start: 5;
}

.side-bar-body {
  margin-top: 10px;
  display: grid;
  grid-auto-rows: 40px;
  grid-template-columns: 1fr;
  grid-row-gap: 5px;
}

.side-bar-links {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: 5px;
}
.side-bar-link {
  background-color: #464f51;
  color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  user-select: none;
  border: 0.5px solid black;
  cursor: pointer;
  height: 40px;
}
</style>
