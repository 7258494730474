<template>
	<div
		:data-cy="noteOption"
		class="note"
		:class="noteOption"
		@click="viewFullNote"
		@keyup.enter="viewFullNote"
	>
		<div class="title" data-cy="note-title">{{ note.title }}</div>
		<span data-cy="day-rating" class="rating" v-if="noteQuestions"
			>{{ note.questions.dayRating }}/5</span
		>
	</div>
</template>

<script>
	export default {
		emits: ['viewFullNote'],
		props: ['note'],
		data() {
			return {}
		},
		methods: {
			viewFullNote() {
				console.log('this.note.title', this.note.title)
				this.$emit('viewFullNote', this.note)
			}
		},
		computed: {
			trimLength() {
				// trims the length of the entry -- not used
				if (this.note.entry.length < 200) {
					return this.note.entry
				}
				return `${this.note.entry.substring(0, 200)}...`
			},
			noteOption() {
				// Setting the class and data-cy for each note-item
				if (this.note.option === 'daily') {
					return 'daily-note'
				} else {
					return 'random-note'
				}
			},
			noteQuestions() {
				return this.note.questions
			}
		}
	}
</script>

<style scoped>
	.note {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		border: 1px solid #727272;
		background-color: #eef0f2;
		box-shadow: 0px 4px 4px #727272;
		padding-left: 10px;
		padding-right: 10px;
		user-select: none;
		z-index: 2;
	}

	.title {
		grid-row: 1;
		align-self: center;
	}

	.rating {
		grid-row: 1;
		align-self: center;
	}

	.daily-note {
		border-left: 5px solid #a37a74;
	}
	.daily-note:hover,
	.daily-note:focus {
		box-shadow: -5px 3px 1px 1px #a37a74;
		transform: translate(5px, 5px);
		border: 1px solid #727272;
	}

	.random-note {
		border-left: 5px solid #2b4570;
	}

	.random-note:hover,
	.random-note:focus {
		box-shadow: -5px 3px 1px 1px #2b4570;
		transform: translate(5px, 5px);
		border: 1px solid #727272;
	}
	.entry {
		background-color: #fff;
		padding: 5px;
	}
</style>
