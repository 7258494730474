<template>
	<div class="add-note" :class="noteOption">
		<form @submit.prevent>
			<input
				type="text"
				id="title"
				data-cy="noteTitleInput"
				placeholder="Title"
				v-model="title"
			/>
			<div>
				<input
					type="radio"
					v-model="option"
					value="daily"
					id="addNoteDaily"
					data-cy="addNoteDailyRadioButton"
					checked
					@change="toggleRadio"
				/>
				<label for="addNoteDaily">Daily</label>
				<input
					type="radio"
					v-model="option"
					value="random"
					id="addNoteRandom"
					data-cy="addNoteRandomRadioButton"
					@change="toggleRadio"
				/>
				<label for="addNoteRandom">Random</label>
			</div>
			<button @click="addNote" data-cy="addNoteButton" class="btn add-btn">
				Add
			</button>
			<button data-cy="cancelAddNoteButton" @click="cancelAddNote" class="btn cancel-btn">Cancel</button>
		</form>
	</div>
</template>

<script>
	import * as journalHelper from '../../helpers/journalHelper'

	export default {
		emit: ['cancel-add-note', 'add-note'],
		data() {
			return {
				title: '',
				date: '',
				option: 'daily'
			}
		},
		methods: {
			addNote() {
				let noteObject = {
					date: journalHelper.getCurrentDate(),
					entry: [],
					option: this.option,
					title: this.title
				}
				this.$emit('add-note', noteObject)
				this.title = ''
				this.date = ''
				this.option = 'daily'
			},
			fillTitleWithCurrentDate() {
				this.title = journalHelper.fillTitleWithCurrentDate()
			},
			toggleRadio(event) {
				if (event.target.value === 'random') {
					this.title = ''
				} else {
					this.fillTitleWithCurrentDate()
				}
			},
			cancelAddNote() {
				this.title = ''
				this.$emit('cancel-add-note')
			}
		},
		computed: {
			noteOption() {
				// Setting the class for each note-item
				if (this.option === 'daily') {
					return 'daily-note'
				} else {
					return 'random-note'
				}
			}
		},
		created() {
			this.fillTitleWithCurrentDate()
		}
	}
</script>

<style scoped>
	.cancel-btn {
		border: 1px solid red;
		margin-left: 10px;
		box-shadow: 1px 1px 1px 1px #676767;
	}

	.add-btn {
		border: 1px solid green;
		box-shadow: 1px 1px 1px 1px #676767;
	}

	input[type='radio'] {
		outline: none;
	}

	.add-note {
		display: flex;
		flex-direction: column;
		border: 1px solid #727272;
		box-shadow: 0px 4px 4px #727272;
		padding: 10px;
		background-color: #eef0f2;
	}
	.add-note input {
		margin-bottom: 10px;
		height: unset;
	}

	.daily-note {
		border-left: 5px solid #a37a74;
	}

	.random-note {
		border-left: 5px solid #2b4570;
	}
</style>
