<template>
  <div>
    <h1>Tasks</h1>
    <div class="container" data-cy="task-groups-container">
      <task-group-item :tasks="tasks" :name="'All tasks'" :link="'tasklist'"></task-group-item>
      <task-group-item
        data-cy="task-group"
        :id="group.id"
        v-for="group in groups"
        :key="group.id"
        :tasks="group.tasks"
        :name="group.name"
        :link="'taskgroup/' + group.id"
      ></task-group-item>
    </div>
  </div>
</template>

<script>
import TaskGroupItem from '../../components/tasks/TaskGroupItem.vue'
export default {
  methods: {
    goToLink(event) {
      this.$router.push('/' + event.target.id)
    },
    goToTaskGroup(group) {
      this.$router.push('/taskgroup/' + group.id)
    }
  },
  computed: {
    groups() {
      let groups = [...this.$store.getters.returnAllGroups]
      return groups
    },
    tasks() {
      let tasks = [...this.$store.getters.returnAllTasks]
      let groups = this.groups
      groups.forEach(group => {
        tasks = tasks.concat(group.tasks)
      })
      return tasks
    }
  },
  components: {
    TaskGroupItem
  }
}
</script>

<style scoped>
.container {
  gap: 10px;
}
</style>
