<template>
  <div class="calendar-container">
    <full-calendar
      ref="fullCalendar"
      :options="calendarOptions"
    ></full-calendar>
  </div>
</template>

<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'

import moment from 'moment'

export default {
  props: ['stamps', 'date'],
  components: {
    FullCalendar
  },
  data() {
    return {
      calendarOptions: {
        height: '100%',
        // contentHeight: '100%',
        // aspectRatio: 0.5,
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short',
          // Change the time on y-axis to 24-hour
          hour12: false
        },
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        },
        headerToolbar: false,
        allDaySlot: false,
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: 'timeGrid',
        events: [],
        initialDate: this.date,
        slotDuration: '00:30:00'
      }
    }
  },
  methods: {
    setEvents() {
      this.calendarOptions.events = []
      this.stamps.forEach((stamp) => {
        this.calendarOptions.events.push({
          title: stamp.name,
          date: stamp.date,
          start: `${stamp.date}T${stamp.startTime}`,
          end: `${stamp.date}T${stamp.endTime}`,
          allDay: false
        })
      })
    }
  },
  mounted() {
    this.stamps.forEach((stamp) => {
      this.calendarOptions.events.push({
        title: stamp.name,
        date: stamp.date,
        start: `${stamp.date}T${stamp.startTime}`,
        end: `${stamp.date}T${stamp.endTime}`,
        allDay: false
      })
    })
  },
  watch: {
    date: function(date) {
      let momentDate = moment(date)
      let diff = momentDate.diff(moment(this.calendarOptions.initialDate))
      if (diff > 0) {
        let calendarApi = this.$refs.fullCalendar.getApi()
        calendarApi.next()
      } else if (diff < 0) {
        let calendarApi = this.$refs.fullCalendar.getApi()
        calendarApi.prev()
      }
      this.calendarOptions.initialDate = date
      this.setEvents()
    }
  }
}
</script>

<style scoped>
.calendar-container {
  /* height: 100%; */
  flex-grow: 1;
}
</style>
