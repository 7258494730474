import { createStore } from 'vuex'

import JournalStore from './modules/journalStore'
import UserStore from './modules/userStore'
import ShoppingListStore from './modules/shoppingListStore'
import TaskListStore from './modules/taskStore'
import WeeklyGoalsStore from './modules/weeklyGoalsStore'
import BudgetStore from './modules/budgetStore'
import StampStore from './modules/stampStore'
import TimeTrackerStore from './modules/timeTrackerStore'
import StorageStore from './modules/storageStore'
import NhlStore from './modules/nhlStore'

const store = createStore({
  modules: {
    UserStore,
    JournalStore,
    ShoppingListStore,
    TaskListStore,
    WeeklyGoalsStore,
    BudgetStore,
    StampStore,
    StorageStore,
    TimeTrackerStore,
    NhlStore
  }
})

export default store
