<template>
  <div class="stamp-item-container">
    <div class="header">
      <img src="../../assets/close.svg" alt="Close" @click="closeEdit" />
      <h1>{{ stamp.name }}</h1>
    </div>
    <div class="edit-stamp-body">
      <div>
        <label>Name:</label>
        <input type="text" v-model="name" @blur="saveStampItem" />
      </div>
      <div>
        <label>Date:</label>
        <input
          data-cy="stampItemDate"
          type="text"
          v-model="date"
          @blur="saveStampItem"
        />
      </div>
      <div>
        <label>Start:</label>
        <input
          data-cy="startTimeInput"
          type="text"
          v-model="startTime"
          @blur="recalculateDuration"
        />
      </div>
      <div>
        <label>End:</label>
        <input
          data-cy="endTimeInput"
          type="text"
          v-model="endTime"
          @blur="recalculateDuration"
        />
      </div>
      <div>
        <label>Duration:</label>
        <span data-cy="durationSpan"> {{ duration }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  emits: ['closeEditSubStamp', 'saveSubStampItem'],
  props: ['stamp'],
  data() {
    return {
      name: this.stamp.name,
      date: this.stamp.date,
      startTime: this.stamp.startTime,
      endTime: this.stamp.endTime,
      duration: this.stamp.duration
    }
  },
  methods: {
    recalculateDuration() {
      let payload = { ...this.stamp }
      payload.startTime = moment(this.startTime).format('YYYY-MM-DD HH:mm:ss')
      payload.endTime = moment(this.endTime).format('YYYY-MM-DD HH:mm:ss')

      const duration = moment.duration(
        moment(payload.endTime).diff(moment(payload.startTime))
      )
      const milliseconds = duration.asMilliseconds()
      this.duration = moment()
        .hour(0)
        .minute(0)
        .second(milliseconds / 1000)
        .format('HH:mm:ss')
      payload.durationInMs = milliseconds
      payload.duration = this.duration

      this.$emit('saveSubStampItem', payload)
    },
    saveStampItem() {
      let payload = { ...this.stamp }
      payload.name = this.name
      payload.date = this.date
      this.$emit('saveSubStampItem', payload)
    },
    closeEdit() {
      this.$emit('closeEditSubStamp')
    }
  }
}
</script>

<style scoped>
.stamp-item-container {
  position: absolute;
  inset: 0px;
  background-color: #dce1e3;
  z-index: 2;
  padding: 10px;
}
.header {
  display: grid;
}
.header img {
  grid-row: 1;
  grid-column: 1;
}
.header h1 {
  grid-row: 1;
  grid-column: 1;
  margin-top: 0;
  justify-self: center;
}

span {
  display: inline-block;
  width: 200px;
  font-size: 20px;
  color: black;
  margin-top: 10px;
}
</style>
