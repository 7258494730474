<template>
	<div class="add-event-container">
		Add Event!
		<form @submit.prevent="addEvent" class="add-event-form">
			<select v-model="group">
				<option
					v-for="group in allTaskGroups"
					:key="group.id"
					:value="group.id"
					>{{ group.name }}</option
				>
			</select>
			<input type="text" placeholder="Name" v-model="name" />
			<input type="text" placeholder="Start date" v-model="startDate" />
			<input type="text" placeholder="Start time" v-model="startTime" />
			<input type="text" placeholder="End date" v-model="endDate" />
			<input type="time" placeholder="End time" v-model="endTime" />
			<input type="submit" value="Add" class="btn-primary" @click="addEvent" />
			<input type="button" value="Cancel" class="btn" @click="cancelAddEvent" />
		</form>
	</div>
</template>

<script>
	import { v4 as uuidv4 } from 'uuid'

	export default {
		emits: ['closeAddEvent'],
		props: ['eventData'],
		data() {
			return {
				name: '',
				startDate: this.eventData.startDate,
				startTime: this.eventData.startTime,
				endDate: this.eventData.endDate,
				endTime: this.eventData.endTime,
				group: ''
			}
		},
		methods: {
			cancelAddEvent() {
				this.$emit('closeAddEvent')
			},
			addEvent() {
				let group = this.allTaskGroups.find((group) => group.id === this.group)
				let task = {
					name: this.name,
					date: this.startDate,
					startTime: this.startTime,
					endTime: this.endTime,
					status: 'new'
				}
				if (!group) {
          this.$store.dispatch('addTaskToDb', task)
				} else {
          task.id = uuidv4(),
					group.tasks.push(task)
					this.$store.dispatch('saveGroup', group)
					this.$emit('closeAddEvent')
				}
			}
		},
		computed: {
			allTaskGroups() {
				return this.$store.getters.returnAllGroups
			}
		}
	}
</script>

<style scoped>
	.add-event-container {
		position: absolute;
		inset: 0;
		background: rgba(0, 0, 0, 0.5);
		z-index: 2;
	}
	.add-event-form {
		inset: 200px 40px;
		background-color: white;
		position: absolute;
		display: flex;
		flex-direction: column;
		padding: 20px;
	}
	input[type='button'] {
		margin-top: 5px;
	}
</style>
