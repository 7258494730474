<template>
	<div class="cli-container" @click="focusInput" ref="cliContainer">
		<div class="cli-body">
			<p v-for="message in messages" :key="message">{{ message }}</p>
		</div>
		<div class="cli-input-container" autocomplete="off">
			<form class="cli-input-form" @submit.prevent="onSubmit">
				<input
					ref="input"
					class="cli-input"
					type="text"
					@keyup.up="previousCommand"
					@keyup.down="nextCommand"
					@keyup.enter="checkInput"
					@keyup.esc="clearInput"
					v-model="input"
				/>
			</form>
		</div>
	</div>
</template>

<script>
	import moment from 'moment'
	import { v4 as uuidv4 } from 'uuid'

	export default {
		data() {
			return {
				messages: ['$ Enter something'],
				input: '',
				inputList: [],
				inputIndex: -1
			}
		},
		methods: {
			clearInput() {
				this.input = ''
			},
			clearBody() {
				this.messages = ['$ Enter something']
			},
			addMessage(message) {
				let newMessage = '$ ' + message
				this.messages.push(newMessage)
				this.$refs.input.scrollIntoView()
			},
			previousCommand() {
				this.inputIndex++
				if (this.inputIndex > this.inputList.length - 1) {
					this.inputIndex--
				}
				this.input = this.inputList[this.inputIndex]
			},
			nextCommand() {
				this.inputIndex--
				if (this.inputIndex < -1) {
					this.inputIndex++
				}
				this.input = this.inputList[this.inputIndex]
			},
			displayAllTimeGroups() {
				this.allTimeGroups.forEach((group) => {
					this.addMessage(' - ' + group.name)
				})
			},
			formattTimeFromMs(ms) {
				const duration = moment.duration(ms / 1000 / 60, 'minutes')
				let hours = Math.floor(duration.asHours())
				let minutes = Math.floor(duration.asMinutes()) - hours * 60
				let seconds =
					Math.floor(duration.asSeconds()) - hours * 60 * 60 - minutes * 60

				if (hours < 10) {
					hours = '0' + hours
				}
				if (minutes < 10) {
					minutes = '0' + minutes
				}
				if (seconds < 10) {
					seconds = '0' + seconds
				}
				const result = `${hours}:${minutes}:${seconds}`
				return result
			},
			clockOut(stamp) {
				// setting an endtime
				let stampOut = moment().format('YYYY-MM-DD HH:mm:ss')
				let now = moment().format('YYYY-MM-DD HH:mm:ss')
				const duration = moment.duration(
					moment(now).diff(moment(stamp.startTime))
				)
				console.log('duration', duration)
				// duration of stamp calculation
				const formatted = this.formattTimeFromMs(duration.asMilliseconds())

				// setting the value of the post
				const postObject = stamp
				postObject.ended = true
				postObject.endTime = stampOut
				postObject.duration = formatted
				postObject.durationInMs = duration.asMilliseconds()

				this.stampOut(postObject)
				this.name = ''
			},
			stampOut(postObject) {
				let timeGroups = [...this.allTimeGroups]
				let timeGroup
				timeGroups.forEach((group) => {
					if (group.stamps) {
						group.stamps.forEach((stamp) => {
							if (stamp.id === postObject.id) {
								console.log('hej')
								stamp = postObject
								timeGroup = { ...group }
							}
						})
					}
				})
				console.log('timegroup', timeGroup)
				this.$store.dispatch('saveTimeGroup', timeGroup)
			},
			clockIn(name, group) {
				let startTime = moment().format('YYYY-MM-DD HH:mm:ss')
				let date = moment().format('YYYY-MM-DD')
				this.stampedIn = true
				let postObject = {
					name: name,
					startTime: startTime,
					ended: false,
					date: date,
					id: uuidv4(),
					group: group
				}

				this.stampIn(postObject)
			},
			stampIn(postObject) {
				let stampObject = {
					name: postObject.name,
					startTime: postObject.startTime,
					ended: postObject.ended,
					id: postObject.id,
					date: postObject.date
				}
				let timeGroup = this.allTimeGroups.find(
					(group) => group.id === postObject.group.id
				)
				if (!timeGroup.stamps) {
					timeGroup.stamps = []
				}
				timeGroup.stamps.push(stampObject)
				this.addMessage('Clocked in!')
				this.$store.dispatch('saveTimeGroup', timeGroup)
			},
			displayAllStampsInGroup(groupName) {
				const groups = [...this.allTimeGroups]
				const group = { ...groups.find((_group) => _group.name === groupName) }

				let allStamps = [...group.stamps]
				allStamps.forEach((stamp, index) => {
					if (stamp.ended === false) {
						allStamps.splice(index, 1)
					}
				})

				// Sorting by name
				allStamps = allStamps.sort((a, b) => {
					if (a.name < b.name) {
						return -1
					}
					if (a.name > b.name) {
						return 1
					}
					return 0
				})

				const tempArr = []

				// checking for duplicates
				allStamps.forEach((stamp, i) => {
					allStamps.forEach((_stamp, j) => {
						// Not checking itself
						if (i !== j) {
							// If name is the same
							if (stamp.name === _stamp.name) {
								// If no arr is present, create arr
								if (tempArr.length === 0) {
									let subStampObject = {
										name: _stamp.name,
										subStamps: [_stamp]
									}
									tempArr.push(subStampObject)
								} else {
									// Else, check if name is already in arr
									let foundIndex = tempArr.findIndex(
										(s) => s.name === _stamp.name
									)

									if (foundIndex === -1) {
										let subStampObject = {
											name: _stamp.name,
											subStamps: [_stamp]
										}
										tempArr.push(subStampObject)
									} else {
										// And check if in subarr
										let foundIdIndex = tempArr[foundIndex].subStamps.findIndex(
											(s) => s.id === _stamp.id
										)
										if (foundIdIndex === -1) {
											tempArr[foundIndex].subStamps.push(_stamp)
										}
									}
								}
							} else {
								let foundIndex = tempArr.findIndex(
									(s) => s.name === _stamp.name
								)
								if (foundIndex === -1) {
									let subStampObject = {
										name: _stamp.name,
										subStamps: [_stamp]
									}
									tempArr.push(subStampObject)
								}
							}
						}
					})
				})

				// Backup-check for single stamp in group
				if (tempArr.length < 1) {
					allStamps.forEach((stamp) => {
						let subStampObject = {
							name: stamp.name,
							subStamps: [stamp]
						}
						tempArr.push(subStampObject)
					})
				}
				allStamps = tempArr

				allStamps.forEach((stampGroup) => {
					let stampString
					if (stampGroup.subStamps.length !== 1) {
						stampString = ' stamps'
					} else {
						stampString = ' stamp'
					}
					this.addMessage(
						' - ' +
							stampGroup.name +
							' ' +
							stampGroup.subStamps.length +
							stampString
					)
				})
			},
			checkTimeSecondCommand(command) {
				if (command === 'groups') {
					this.displayAllTimeGroups()
				} else if (command === 'group') {
					const groupName = this.input.split(' ')[2]
					if (!groupName) {
						this.addMessage('Must specify group!')
						return
					}
					this.displayAllStampsInGroup(groupName)
				} else if (command === 'in') {
					let name = this.input.split(' ')

					// removes first three commands
					name.splice(0, 3)
					name = name.join(' ')
					const groupName = this.input.split(' ')[2]
					// Check groupname input
					if (!groupName) {
						this.addMessage('Must specify group!')
						return
					}
					// Check name input
					if (!name) {
						this.addMessage('Must specify name!')
						return
					}
					const group = this.allTimeGroups.find(
						(group) => group.name === groupName
					)
					// Check if group exists
					if (!group) {
						this.addMessage('Group does not exist!')
						return
					}
					this.clockIn(name, group)
				} else if (command === 'out') {
          // Double code, same as in timetrackergroup
					const allGroups = [...this.allTimeGroups]
					let stamp
					allGroups.forEach((group) => {
						if (group.stamps) {
							group.stamps.forEach((_stamp) => {
								if (!_stamp.ended) {
									stamp = _stamp
								}
							})
						}
					})
					if (stamp) {
						this.clockOut(stamp)
						console.log(stamp)
						this.addMessage(
							'Clocked out from ' + stamp.name + ' - ' + stamp.duration
						)
					} else {
						this.addMessage('No active stamps')
					}
				} else if (command === 'today') {
					const allGroups = [...this.allTimeGroups]
					const date = moment().format('yyyy-MM-DD')
					let stamps = []
					allGroups.forEach((group) => {
						if (group.stamps) {
							group.stamps.forEach((stamp) => {
								if (stamp.date === date) {
									stamps.push(stamp)
								}
							})
						}
					})

					stamps = stamps.sort((a, b) => {
						return new Date(a.startTime) - new Date(b.startTime)
					})

					stamps.forEach((stamp) => {
						if (!stamp.ended) {
							stamp.duration = 'Active'
						}
						this.addMessage(' - ' + stamp.name + ' - ' + stamp.duration)
					})
				} else if (!command) {
					const allGroups = [...this.allTimeGroups]
					const stamps = []
					allGroups.forEach((group) => {
						if (group.stamps) {
							group.stamps.forEach((_stamp) => {
								if (!_stamp.ended) {
									stamps.push(_stamp)
								}
							})
						}
					})
					if (stamps.length > 0) {
						stamps.forEach((stamp) => {
							// double code as stampOut() - refactor at some point
							let now = moment().format('YYYY-MM-DD HH:mm:ss')
							const duration = moment.duration(
								moment(now).diff(moment(stamp.startTime))
							)

							// duration of stamp calculation
							const formatted = this.formattTimeFromMs(
								duration.asMilliseconds()
							)
							this.addMessage(' - ' + stamp.name + ' - ' + formatted)
						})
					} else {
						this.addMessage('No active stamps')
					}
				} else if (command === 'help') {
					this.addMessage(' - time -- displays active stamp if there is one')
					this.addMessage(' - time [in, out, groups]')
					this.addMessage(
						' - time in <groupName> <stampName> --stamps in with passed information'
					)
					this.addMessage(' - time out --stamps out one active stamp')
					this.addMessage(' - time today --displays all stamps from today')
					this.addMessage(' - time groups --displays all time groups')
					this.addMessage(
						' - time group <groupName> --displays all stamps in chosen time group'
					)
				} else {
					this.addMessage('time ' + command + ' is not recognized as a command')
				}
			},
			checkJournalSecondCommand(command, enterArr) {
				if (command === 'enter') {
					const allNotes = [...this.allJournalNotes]
					const date = moment().format('yyyy-MM-DD')
					const entry = enterArr.join(' ')
					const entryO = {
						entry: entry,
						time: moment().format('yyyy-MM-DD HH:mm:ss')
					}
					let note

					allNotes.forEach((_note) => {
						if (_note.date === date && _note.option === 'daily') {
							note = { ..._note }
						}
					})

					if (!note) {
						this.addMessage('No note found!')
						return
					}

					if (!note.entry) note.entry = []

					note.entry.push(entryO)
					this.$store.dispatch('saveNoteInDb', note)
					this.addMessage('Added entry to journal!')
				} else if (command === 'help') {
					this.addMessage(' - journal [enter]')
					this.addMessage(
						' - journal enter <entry> -- adds an entry to todays journal note'
					)
				} else if (command === '' || command === ' ' || !command) {
					this.addMessage('Need a second command, type journal help if needed!')
				} else {
					this.addMessage(
						'journal ' + command + ' is not recognized as a command'
					)
				}
			},
			checkGoSecondCommand(command) {
				if (!command) {
					this.addMessage('Need a route to go to')
					this.input = ''
					return
				}
				// First character to uppercase
				command = command.split('')
				command[0] = command[0].toUpperCase()
				command = command.join('')

				let link
				try {
					link = this.$router.resolve({
						name: command
					})
				} catch (e) {
					this.addMessage('Route does not exist')
				}
				// might need to check if href !== '/'
				if (link) {
					this.$router.push({ name: command })
				}
			},
			checkInput() {
				this.addMessage(this.input)
				this.inputList.unshift(this.input)
				let command = this.input.split(' ')[0]
				if (command === 'time') {
					this.checkTimeSecondCommand(this.input.split(' ')[1])
				} else if (command === 'journal') {
					let body = this.input.split(' ')
					let secondCommand = body[1]

					// Remove first and second command
					body.splice(0, 2)
					this.checkJournalSecondCommand(secondCommand, body)
				} else if (command === 'help') {
					this.addMessage('Commands:')
					this.addMessage(' - time [in, out, groups, help]')
					this.addMessage(' - journal [enter] <entry>')
					this.addMessage(' - go <location>')
				} else if (command === 'clear') {
					this.clearBody()
				} else if (command === 'go') {
					this.checkGoSecondCommand(this.input.split(' ')[1])
				} else if (command === ' ' || !command) {
					console.log('hej')
				} else {
					this.addMessage(command + ' is not recognized as a command')
				}
				this.clearInput()
			},
			focusInput() {
				this.$refs.input.focus()
			},
			checkParamCommand() {
				if (this.$route.params.command && this.$route.params.command !== ' ') {
					this.input = this.$route.params.command
					this.checkInput()
				}
			}
		},
		computed: {
			allTimeGroups() {
				return this.$store.getters.getTimeGroups
			},
			allJournalNotes() {
				return this.$store.getters.getAllNotes
			}
		},
		mounted() {
			this.focusInput()
			this.checkParamCommand()
		}
	}
</script>

<style scoped>
	.cli-container {
		position: relative;
		z-index: 2;
		background-color: black;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		color: #fff;
		overflow: hidden;
	}
	.cli-body {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-end;
		padding: 10px;
		overflow-y: auto;
	}
	.cli-body p {
		margin: 1px;
	}

	.cli-input-container {
		display: flex;
		padding-left: 10px;
	}
	.cli-input-form {
		flex-grow: 1;
		display: flex;
	}
	.cli-input {
		color: inherit;
		flex-grow: 1;
		text-align: start;
		background-color: inherit;
		caret-color: #fff;
	}
	.cli-input:focus {
		background-color: inherit;
		color: inherit;
		caret-color: #fff;
	}
</style>
