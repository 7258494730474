const getCurrentDate = () => {
  let now = new Date()
  let month =
  now.getMonth() + 1 > 9 ? now.getMonth() + 1 : '0' + (now.getMonth() + 1)
  let date = now.getDate() > 9 ? now.getDate() : '0' + now.getDate()
  let year = now.getFullYear()
  let dateO =
    year.toString() + '-' + month.toString() + '-' + date.toString()
  return dateO
}

const parseDay = (date) => {
  let day = date.getDay()
  switch (day) {
    case 0:
      day = 'Söndag'
      break
    case 1:
      day = 'Måndag'
      break
    case 2:
      day = 'Tisdag'
      break
    case 3:
      day = 'Onsdag'
      break
    case 4:
      day = 'Torsdag'
      break
    case 5:
      day = 'Fredag'
      break
    case 6:
      day = 'Lördag'
      break
  }
  return day
}

const fillTitleWithCurrentDate = () => {
  let currentDate = getCurrentDate()
  // Tempdate because new Date returns an unwanted date-string
  let tempDate = new Date(currentDate)
  let dayOfWeek = parseDay(tempDate)
  return dayOfWeek + ' ' + currentDate
}

const returnDayAndDateString = (date) => {
  let tempDate = new Date(date)
  let dayOfWeek = parseDay(tempDate)
  return dayOfWeek + ' ' + date
}

export { fillTitleWithCurrentDate, parseDay, getCurrentDate, returnDayAndDateString }
