<template>
	<div class="details-container">
		<div class="details-header">
			<img
				class="back-arrow"
				src="../../assets/tasklist/arrow.svg"
				alt="Back"
				@click="closeDetails"
			/>

			<input
				id="header-input"
				type="text"
				v-model="name"
				@blur="saveName"
				v-on:keyup.enter="saveName"
			/>
		</div>
		<div class="details-body">
			<input
				data-cy="subtask-name-input"
				type="text"
				placeholder="Add task"
				v-model="subTaskName"
				v-on:keyup.enter="addSubTask"
			/>
			<input type="button" value="Add" class="btn" @click="addSubTask" />
			<div class="sub-tasks">
				<div
					class="sub-task"
					v-for="(subTask, index) in task.subTasks"
					:key="subTask.id"
				>
					<img
						@click="toggleSubTaskStatus(subTask)"
						v-if="subTask.status === 'new'"
						class="sub-check-button"
						src="../../assets/tasklist/unchecked.svg"
						alt="Unchecked"
					/>
					<img
						@click="toggleSubTaskStatus(subTask)"
						v-if="subTask.status === 'done'"
						class="sub-check-button"
						src="../../assets/tasklist/checked.svg"
						alt="Checked"
					/>
					<input
						:data-cy="'subtask' + index"
						type="text"
						v-model="subTask.name"
						v-on:keyup.enter="saveSubTask(subTask)"
						@blur="saveSubTask(subTask)"
					/>
					<img
						@click="deleteSubTask(subTask)"
						class="delete-sub-task-button"
						src="../../assets/close.svg"
						alt="delete"
					/>
				</div>
			</div>
			<textarea
				data-cy="subtask-comment-input"
				ref="comment"
				class="task-comment"
				contenteditable="true"
				placeholder="Comment"
				v-model="comment"
				@blur="saveComment"
			></textarea>

			<div class="task-date">
				<input type="date" v-model="date" @change="saveDate" />
			</div>
		</div>
	</div>
</template>

<script>
	import { v4 as uuidv4 } from 'uuid'

	export default {
		emits: [
			'closeDetails',
			'toggleTaskStatus',
			'toggleSubTaskStatus',
			'saveTask'
		],
		props: ['task'],
		data() {
			return {
				subTaskName: '',
				date: this.task.date,
				name: this.task.name,
				comment: this.task.comment
			}
		},
		methods: {
			closeDetails() {
				this.$emit('closeDetails')
			},
			toggleStatus() {
				this.$emit('toggleTaskStatus', this.task)
			},
			saveName() {
				let task = { ...this.task }
				task.name = this.name
				this.$emit('saveTask', task)
			},
			saveComment() {
				// this.comment = e.target.textContent
				let task = { ...this.task }
				task.comment = this.comment
				this.$emit('saveTask', task)
			},
			saveDate() {
				let task = { ...this.task }
				task.date = this.date
				this.$emit('saveTask', task)
			},
			addSubTask() {
				let task = { ...this.task }
				let payload = {
					id: uuidv4(),
					name: this.subTaskName,
					status: 'new'
				}
				if (!task.subTasks) task.subTasks = []
				task.subTasks.push(payload)
				this.$emit('saveTask', task)
				this.subTaskName = ''
			},
			saveSubTask(subTask) {
				let task = { ...this.task }
				task.subTasks.forEach((_subTask, index) => {
					if (subTask.id === _subTask.id) {
						task.subTasks[index] = subTask
					}
				})
				this.$emit('saveTask', task)
			},
			toggleSubTaskStatus(subTask) {
				let task = { ...this.task }
				task.subTasks.forEach((_subTask, index) => {
					if (subTask.id === _subTask.id) {
						if (subTask.status === 'new') {
							subTask.status = 'done'
						} else {
							subTask.status = 'new'
						}
						task.subTasks[index] = subTask
					}
				})
				this.$emit('toggleSubTaskStatus', task)
			},
			deleteSubTask(subTask) {
				let task = { ...this.task }
				task.subTasks.forEach((_subTask, index) => {
					if (subTask.id === _subTask.id) {
						task.subTasks.splice(index, 1)
					}
				})
				this.$emit('saveTask', task)
			}
		},
		mounted() {
			this.$refs.comment.textContent = this.task.comment
		},
		watch: {
			task: function(newVal) {
				this.name = newVal.name
				this.date = newVal.date
				this.$refs.comment.textContent = newVal.comment
			}
		},
		computed: {
			checkedTask() {
				return this.task.status === 'new'
			}
		}
	}
</script>

<style scoped>
	.details-container {
		height: 100%;
		width: 100%;
		position: fixed;
		background-color: #dce1e3;
		top: 0;
		z-index: 2;
	}
	.details-header {
		display: grid;
		grid-template-columns: 0.2fr 2fr 1fr;
		grid-template-rows: 1fr;
		padding: 20px;
	}
	#header-input {
		grid-row: 1;
		grid-column: 2 / 5;
		align-self: start;
		justify-self: start;
		text-align: start;
		border-bottom: unset;
		font-size: 1.5rem;
	}
	#header-input:focus {
		box-shadow: unset;
	}
	.back-arrow {
		grid-row: 1;
		grid-column: 1;
		align-self: center;
		transform: rotate(90deg);
		height: 30px;
	}
	.check-button {
		grid-row: 2;
		grid-column: 2;
		align-self: center;
		justify-self: center;
	}

	.btn {
		margin-left: 20px;
	}
	.details-body {
		display: flex;
		flex-direction: column;
		padding-left: 40px;
		padding-right: 40px;
	}
	.details-body input {
		margin-bottom: 10px;
	}
	.btn {
		width: 100px;
		height: 40px;
		align-self: center;
	}
	.sub-tasks {
		display: grid;
		grid-template-columns: 1fr;
		gap: 20px;
	}
	.sub-check-button {
		grid-row: 1;
		grid-column: 1;
		align-self: start;
		justify-self: start;
    height: 20px;
	}

	.sub-task {
		display: grid;
    grid-template-columns: .1fr 1fr .1fr;
    border-bottom: 1px solid black;
	}
	.sub-task img {
		grid-row: 1;
		align-self: center;
		z-index: 1;
	}
	.sub-task input {
		z-index: 0;
		grid-column: 2;
		grid-row: 1;
		border-bottom: unset;
		font-size: 1rem;
	}
	.sub-task input:focus {
		box-shadow: unset;
	}

	.delete-sub-task-button {
		justify-self: end;
		height: 15px;
    grid-column: 3;
	}

	.task-comment {
		margin-top: 20px;
		align-self: center;
		width: 100%;
		resize: none;
		background-color: unset;
		outline: none;
		border: none;
		border-bottom: 1px solid black;
		color: black;
		text-align: center;
		font-size: 18px;
		white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
	}
	@media screen and (min-width: 600px) {
		.details-container {
			height: 100%;
			width: 30%;
			position: fixed;
			right: 0;
			border: 1px solid black;
		}
	}
</style>
