<template>
	<h1>User page</h1>
	<router-link to="/settings">Settings</router-link>
	<p>Signed in as: {{ username }}</p>
	<router-link to="/login" @click="signOut()">Logout</router-link>
</template>

<script>
	export default {
		data() {
			return {
				username: '',
				apiKeyInput: ''
			}
		},
		methods: {
			signOut() {
				this.$store.dispatch('logout')
			}
		},
		created() {
			this.username = this.$store.getters.getUser.name
		}
	}
</script>
