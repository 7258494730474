<template>
  <div
    data-cy="modal-header"
    class="modal-header"
    :class="{
      'daily-header': option === 'daily',
      'random-header': option === 'random'
    }"
  >
    <img
      data-cy="closeNoteButton"
      class="close-button"
      @click="closeFullNote"
      src="../../assets/close.svg"
      alt="close"
    />
    <h2 class="title">{{ title }}</h2>
    <div
      class="expand-div"
      @click="displayOptions = !displayOptions"
      :class="{ rotated: displayOptions }"
    >
      <img
        src="../../assets/expand.svg"
        alt="expand"
        data-cy="expandOptionsButton"
      />
    </div>
    <div v-if="displayOptions" class="options" data-cy="optionsField">
      <div @click="emitDisplayDetails" data-cy="displayDetailsButton">
        <img src="../../assets/edit.svg" alt="edit" />
      </div>
      <div @click="deleteNote" data-cy="deleteNoteButton">
        <img src="../../assets/trash.svg" alt="delete" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['emitDisplayDetails', 'deleteNote', 'closeFullNote'],
  props: ['title', 'option'],
  data() {
    return {
      displayOptions: false,
      displayDetails: false
    }
  },
  methods: {
    emitDisplayDetails() {
      this.$emit('emitDisplayDetails')
    },
    deleteNote() {
      this.$emit('deleteNote')
    },
    closeFullNote() {
      this.$emit('closeFullNote')
    }
  }
}
</script>

<style scoped>
.modal-header {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
}
.daily-header {
  border-bottom: 2px solid #a37a74;
  box-shadow: 0px 1px 3px 1px #a37a74;
}
.random-header {
  border-bottom: 2px solid #2b4570;
  box-shadow: 0px 1px 3px 1px #2b4570;
}
.title {
  grid-row: 1;
  grid-column-start: 1;
  align-self: center;
  justify-self: center;
}
.close-button {
  grid-row: 1;
  align-self: start;
  grid-column-start: 1;
  padding: 10px;
}
/* .close-div {
  grid-column-start: 1;
  grid-row: 1;
  padding: 10px;
} */
.expand-div {
  grid-column-start: 1;
  grid-row: 1;
  align-self: center;
  justify-self: end;
  padding: 10px;
  transition-duration: 0.5s;
}
.rotated {
  transform: rotate(90deg);
  transition-duration: 0.5s;
}
.options {
  position: absolute;
  top: 65px;
  right: 15px;
  transition: 10s;
}
.options div {
  margin-top: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 1px 1px #676767;
  border-radius: 10px;
}
</style>
