<template>
  <div class="tasks-container">
    <task-component
      v-for="task in getAllTasksForTheDay"
      :key="task.id"
      :task="task"
    >
    </task-component>
  </div>
</template>

<script>
import TaskComponent from '../tasks/TaskComponent.vue'
export default {
  props: ['date'],
  computed: {
    getAllTasksForTheDay() {
      let tasks = []
      let allGroups = [...this.$store.getters.returnAllGroups]
      allGroups.forEach(group => {
        group.tasks.forEach(task => {
          if (task.date === this.date) {
            tasks.push(task)
          }
        })
      })
      return tasks
    }
  },
  components: {
    TaskComponent
  }
}
</script>

<style>
.tasks-container {
  display: grid;
  gap: 10px;
  padding: 20px;
}
</style>
