<template>
  <span class="parent-trail-container">
    <parent-trail
      v-if="parent"
      :parentId="parent"
      :allItems="allItems"
    ></parent-trail>
    <span @click="goToItem"> {{ item.name }} > </span>
    <span v-if="itemName">{{ itemName }}</span>
  </span>
</template>

<script>
import ParentTrail from './ParentTrail.vue'
export default {
  props: ['parentId', 'allItems', 'itemName'],
  methods: {
    goToItem() {
      if (this.item.id) {
        this.$router.push({
          name: 'Item',
          params: { id: this.item.id }
        })
      }
    }
  },
  computed: {
    item() {
      let index = this.allItems.findIndex((item) => item.id === this.parentId)
      if (index === -1) return { name: '[Deleted]' }
      return this.allItems[index]
    },
    parent() {
      if (!this.item) return null
      if (this.item.parent) return this.item.parent
      return null
    }
  },
  components: {
    ParentTrail
  }
}
</script>

<style scoped>
.parent-trail-container {
  /* display: inline-block; */
  height: 40px;
  /* display: flex; */
  align-items: center;
  user-select: none;
}
</style>
