<template>
  <div class="post">
    <div class="post-name">{{ post.name }}</div>
    <div class="post-duration">
      {{ post.duration }}
      <!-- {{ post }} -->
    </div>
    <button @click="deletePost">Delete</button>
  </div>
</template>

<script>
export default {
  props: ['post'],
  methods: {
    deletePost() {
      this.$emit('deletePost', this.post.id)
    }
  }
}
</script>

<style scoped>
.post {
  height: 100px;
  border: 1px solid black;
  box-shadow: 1px 1px 5px 1px #676767;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
}

.post-name {
  grid-column: 1;
}

.post button {
  grid-column: 3;
}
</style>
