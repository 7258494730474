<template>
  <div>
    <h1>Timetracker!</h1>
    <div v-if="displayAddTimeGroup">
      <input
        data-cy="groupNameInput"
        type="text"
        placeholder="Name"
        v-model="name"
      />
      <button data-cy="addGroupButton" class="btn" @click="addTimeGroup">
        Add
      </button>
    </div>
    <time-filter @change-filter="changeFilter"></time-filter>
    <div class="group-container">
      <TimeTrackerGroupItem
        data-cy="timetracker-group"
        v-for="group in groups"
        :key="group.id"
        :timeGroup="group"
        :filter="filter"
        @click="goToGroup(group)"
      />
    </div>
    <img
      data-cy="startAddGroupButton"
      class="add-button"
      src="../assets/tasklist/addTask.svg"
      alt="Add"
      @click="toggleDisplayAddTimeGroup"
    />
  </div>
</template>

<script>
import TimeTrackerGroupItem from '@/components/timetracker/TimeTrackerGroupItem.vue'
import TimeFilter from '@/components/timetracker/TimeFilter.vue'

export default {
  name: 'TimeTracker',
  data() {
    return {
      displayAddTimeGroup: false,
      name: '',
      filter: 'today',
    }
  },
  methods: {
    changeFilter(filter) {
      this.filter = filter
    },
    toggleDisplayAddTimeGroup() {
      this.displayAddTimeGroup = !this.displayAddTimeGroup
    },
    addTimeGroup() {
      if (!this.name) return
      let payload = {
        name: this.name
      }
      this.$store.dispatch('addTimeTrackerGroup', payload)
      this.toggleDisplayAddTimeGroup()
      this.name = ''
    },
    goToGroup(group) {
      this.$router.push('/timetrackergroup/' + group.id)
    }
  },
  computed: {
    groups() {
      return this.$store.getters.getTimeGroups
    }
  },
  components: {
    TimeTrackerGroupItem,
    TimeFilter
  }
}
</script>

<style scoped>
.active-filter {
  color: black;
}

.add-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
}
.group-container {
  padding: 40px;
  display: grid;
  gap: 20px;
}

@media screen and (min-width: 600px) {
  .time-filter {
    padding-left: 100px;
    padding-right: 100px;
  }
  .group-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 900px) {
  .time-filter {
    padding-left: 200px;
    padding-right: 200px;
  }
}

@media screen and (min-width: 1100px) {
  .time-filter {
    padding-left: 300px;
    padding-right: 300px;
  }
}

@media screen and (min-width: 1500px) {
  .time-filter {
    padding-left: 700px;
    padding-right: 700px;
  }
}
</style>
