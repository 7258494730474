<template>
	<input
		type="text"
		placeholder="Name"
		v-model="subTaskNameInput"
		@keyup="emitChanges"
		data-cy="subtaskNameInput"
	/>
</template>

<script>
	export default {
		emits: ['subTaskNameChange'],
		props: ['id', 'name'],
		data() {
			return {
				subTaskNameInput: ''
			}
		},
		methods: {
			emitChanges() {
				let payload = {
					id: this.id,
					name: this.subTaskNameInput,
					status: 'new'
				}
				this.$emit('subTaskNameChange', payload)
			}
		},
		mounted() {
			this.subTaskNameInput = this.name
		}
	}
</script>
