<template>
	<div class="search-storage-container">
		<header>
			<img
				class="close-button"
				src="@/assets/close.svg"
				alt="Close"
				@click="closeSearch"
			/>
			<h1>Search</h1>
		</header>
		<main>
			<input type="text" v-model="input"/>
			<div class="items-container">
				<item-component
					v-for="item in filteredItems"
					:key="item.id"
					:item="item"
          @openItem="openItem"
				></item-component>
			</div>
		</main>
	</div>
</template>

<script>
	import ItemComponent from '@/components/storage/ItemComponent.vue'

	export default {
    data() {
      return {
        input: ''
      }
    },
		methods: {
			closeSearch() {
				this.$router.push('/storage')
			},
      openItem(id) {
        this.$router.push('/item/' + id)
      }
		},
    computed: {
      allItems() {
        return this.$store.getters.getAllItems
      },
      filteredItems() {
        return this.allItems.filter(item => item.name.toLowerCase().includes(this.input.toLowerCase()))
      }
    },
		components: {
			ItemComponent
		}
	}
</script>

<style scoped>
	.search-storage-container {
		position: relative;
		z-index: 2;
		background-color: #dce1e3;
	}
	header {
		display: grid;
		padding: 20px;
	}
	.close-button {
		grid-row: 1;
		grid-column: 1;
		align-self: center;
	}
	header h1 {
		grid-row: 1;
		grid-column: 1;
		margin: 0;
		justify-self: center;
	}
	.items-container {
		max-height: 40em;
	}
</style>
