<template>
  <div class="enter enter-container">
    <input data-cy="noteTimeInput" type="text" :placeholder="entryO.time" v-model="entryO.time" />
    <textarea
      tabindex="1"
      data-cy="entryEventInput"
      ref="textarea"
      @keyup="checkKey"
      v-model="entryO.entry"
    ></textarea>
    <div
      tabindex="2"
      class="button"
      data-cy="entryEventSaveButton"
      @click="saveEntry"
      @keyup.enter="saveEntry"
      @keyup.space="saveEntry"
    >
      Save
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  emits: ['saveEntry', 'closeEntry'],
  props: ['entryObject'],
  data() {
    return {
      entryO: '',
      date: null
    }
  },
  methods: {
    checkKey(event) {
      if (event.key === 'Escape') {
        this.$emit('closeEntry')
      }
    },
    saveEntry(e) {
      e.preventDefault()
			let time = this.entryO.time
			let timeCheck = time.split(' ')
			if (timeCheck.length < 2) {
				this.entryO.time = this.date + ' ' + time
			}
      this.$emit('saveEntry', this.entryO)
    },
    updateEntryO() {
      this.entryO = this.entryObject
      this.entryO.time = moment().format('yyyy-MM-DD HH:mm:ss')
			let dateAndTime = this.entryO.time.split(' ')
      this.entryO.time = dateAndTime[1]
      this.date = dateAndTime[0]
    },
    focusTextArea() {
      this.$refs.textarea.focus()
    }
  },
  watch: {
    entryObject: function() {
      this.updateEntryO()
    }
  },
  mounted() {
    this.focusTextArea()
  },
  created() {
    this.updateEntryO()
    // this.entryO = this.entryObject
  }
}
</script>

<style scoped>
.enter-container {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.1fr 1fr 0.1fr;
  gap: 0px;
}
input {
  height: 30px;
  grid-row: 1;
  background-color: #a5adb6;
}

textarea {
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 13;
  background-color: #ecebe4;
  resize: none;
  width: 98%;
  height: 80%;
  outline: none;
}
.button {
  user-select: none;
  grid-row: 3;
  height: 40px;
  width: 60px;
  display: grid;
  align-content: center;
  justify-content: center;
  justify-self: center;
  border: 1px solid black;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px #676767;
  outline: none;
}
.button:focus {
  border: 2px solid black;
}
.button:active {
  box-shadow: inset 1px 1px 1px 1px #676767;
}
</style>
