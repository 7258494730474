<template>
	<!-- <h1>Site</h1> -->
	<div class="container">
		<div class="goals">
			<div class="goals-header">
				Weekly goals
			</div>
			<ul id="weeklygoals" v-if="allWeeklyGoals.length > 0">
				<li v-for="goal in allWeeklyGoals" :key="goal.id">{{ goal.name }}</li>
			</ul>
			<p v-else>No weekly goals</p>
		</div>
		<div
			@keyup.enter="goToLink"
			@keyup.space="goToLink"
			@click="goToLink"
			:id="'day/' + date"
			class="link"
			tabindex="1"
		>
			Home
		</div>
		<div
			@keyup.enter="goToLink"
			@keyup.space="goToLink"
			@click="goToLink"
			id="journal"
			class="link"
			tabindex="2"
		>
			Journal
		</div>
		<div
			@keyup.enter="goToLink"
			@keyup.space="goToLink"
			@click="goToLink"
			id="timetracker"
			class="link"
			tabindex="3"
		>
			Timetracker
		</div>
		<div
			@keyup.enter="goToLink"
			@keyup.space="goToLink"
			@click="goToLink"
			id="calendar"
			class="link"
			tabindex="4"
		>
			Calendar
		</div>
		<div
			@keyup.enter="goToLink"
			@keyup.space="goToLink"
			@click="goToLink"
			id="storage"
			class="link"
			tabindex="5"
		>
			Storage
		</div>
		<div
			@keyup.enter="goToLink"
			@keyup.space="goToLink"
			@click="goToLink"
			id="budget"
			class="link"
			tabindex="6"
		>
			Budget
		</div>

		<div
			@keyup.enter="goToLink"
			@keyup.space="goToLink"
			@click="goToLink"
			id="shoppinglist"
			class="link"
			tabindex="7"
		>
			Shoppinglist
		</div>
		<div
			@keyup.enter="goToLink"
			@keyup.space="goToLink"
			@click="goToLink"
			id="taskgroups"
			class="link"
			tabindex="8"
		>
			Tasklist
		</div>
		<div
			@keyup.enter="goToLink"
			@keyup.space="goToLink"
			@click="goToLink"
			id="stampClock"
			class="link"
			tabindex="9"
		>
			Stamp clock
		</div>
		<div
			@keyup.enter="goToLink"
			@keyup.space="goToLink"
			@click="goToLink"
			id="quotes"
			class="link"
			tabindex="10"
		>
			Quotes
		</div>
		<div
			@keyup.enter="goToLink"
			@keyup.space="goToLink"
			@click="goToLink"
			id="cli/ "
			class="link"
			tabindex="11"
		>
			CLI
		</div>
		<div
			@keyup.enter="goToLink"
			@keyup.space="goToLink"
			@click="goToLink"
			id="nhl"
			class="link"
			tabindex="12"
		>
			NHL
		</div>
		<div
			@keyup.enter="goToLink"
			@keyup.space="goToLink"
			@click="goToLink"
			id="days"
			class="link"
			tabindex="13"
		>
			Days
		</div>
	</div>
</template>

<script>
	import moment from 'moment'

	export default {
		name: 'Home',
		data() {
			return {
				snusCount: 0,
				date: moment().format('YYYY-MM-DD')
			}
		},
		methods: {
			goToLink(event) {
				console.log(event.target.id)
				this.$router.push('/' + event.target.id)
			}
		},
		computed: {
			allWeeklyGoals() {
				return this.$store.getters.getWeeklyGoals
			}
		}
	}
</script>

<style scoped>
	.goals {
		border: 1px solid black;
		height: 200px;
		box-shadow: 0 0 3pt 1px black;
	}
	.goals ul {
		list-style-type: upper-roman;
	}

	.goals-header {
		height: 45px;
		border-bottom: 1px solid black;
	}

	.link {
		height: 100px;
		font-size: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		border: 1px solid black;
		margin-top: 5px;
		border-radius: 4px;
		outline: none;
		box-shadow: inset 0 0 3pt 1px black;
	}

	.link:focus {
		box-shadow: 0 0 3pt 2pt black;
	}

	.link:hover {
		cursor: pointer;
		/* user-select: none; */
		box-shadow: 0px 0px 4px black;
	}

	@media screen and (min-width: 600px) {
		.container {
			grid-template-columns: 1fr 1fr 1fr;
			gap: 20px;
			padding: 100px;
		}
		.goals {
			grid-column: 1 / 4;
		}
	}
</style>
