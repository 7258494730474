// import Vuex from 'vuex'
import * as fb from '../../firebase'

const state = {
	items: []
}
const mutations = {
	setItems(state, val) {
		state.items = val
	}
}
const actions = {
	async fetchAllItems(context, uid) {
		let itemsArr = []
		await fb
			.storageCollection(uid)
			.get()
			.then((storageContainers) => {
				storageContainers.forEach((item) => {
					let storageObject = item.data()
          storageObject.id = item.id
          itemsArr.push(storageObject)
				})
			})

		context.commit('setItems', itemsArr)
	},
  async addItem(context, payload) {
    const uid = context.rootState.UserStore.userProfile.uid

    await fb.storageCollection(uid).add(payload).catch(err => console.log(err))
    context.dispatch('fetchAllItems', uid)
  },
	async saveItem(context, payload) {
		const uid = context.rootState.UserStore.userProfile.uid
		await fb.storageCollection(uid).doc(payload.id).set(payload)
		context.dispatch('fetchAllItems', uid)
	},
	async deleteItem(context, id) {
		const uid = context.rootState.UserStore.userProfile.uid
		await fb.storageCollection(uid).doc(id).delete()
		context.dispatch('fetchAllItems', uid)
	}
}

const getters = {
	getAllItems(state) {
		return state.items
	}
}

export default { state, mutations, actions, getters }
