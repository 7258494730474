import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
	auth,
	dailyJournalCollection,
	randomJournalCollection,
	timeTrackerCollection,
	taskListCollection
} from './firebase'

let app
auth.onAuthStateChanged((user) => {
	if (!app) {
		app = createApp(App)
		app.use(router)
		app.use(store)
		app.mount('#app')
	}

	if (user) {
		store.dispatch('fetchUserProfile', user)
		store.dispatch('fetchAllNotes', user.uid)
		store.dispatch('fetchAllShoppingItems', user.uid)
		store.dispatch('fetchAllTasks', user.uid)
		store.dispatch('fetchAllGroups', user.uid)
		store.dispatch('fetchWeeklyGoals', user.uid)
		store.dispatch('fetchAllItems', user.uid)
		store.dispatch('fetchTimeGroups', user.uid)
		store.dispatch('fetchFavouriteTeam', user.uid)
		store.dispatch('fetchWatchedGames', user.uid)
		// updating without having to reload the page
		dailyJournalCollection(user.uid).onSnapshot(() => {
			store.dispatch('fetchAllNotes', user.uid)
		})
		randomJournalCollection(user.uid).onSnapshot(() => {
			store.dispatch('fetchAllNotes', user.uid)
		})
		timeTrackerCollection(user.uid).onSnapshot(() => {
			store.dispatch('fetchTimeGroups', user.uid)
		})
		taskListCollection(user.uid).onSnapshot(() => {
			store.dispatch('fetchAllGroups', user.uid)
		})
	}
})
