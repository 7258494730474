<template>
  <div>
    <div class="group" id="taskgroup" @click="goToLink">
      <p>
        {{ name }}
      </p>
      <p>{{ activeTasks.length }} tasks</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['tasks', 'name', 'link'],
  methods: {
    goToLink() {
      this.$router.push('/' + this.link)
    }
  },
  computed: {
    activeTasks() {
      let tasks = [...this.tasks]
      if (tasks.length < 1) return false
      for (let i = tasks.length - 1; i >= 0; i--) {
        if (tasks[i].status === 'done') {
          tasks.splice(i, 1)
        }
      }
      return tasks
    }
  }
}
</script>

<style>
.group {
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
  display: grid;
  align-items: center;
}
</style>
