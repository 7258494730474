<template>
  <div>
    <h1>{{ timeTrackerGroup.name }}</h1>
    <stamp-timer-form
      :stamp="returnActiveStamp"
      @stampIn="stampIn"
      @stampOut="stampOut"
    ></stamp-timer-form>
    <div class="time-stamp-container">
      <div class="card">
        <p>Total time:</p>
        {{ totalTime }}
      </div>
      <div class="card">
        <p>Date</p>
        <p>Name</p>
        <p>Time</p>
      </div>
      <stamp-item
        data-cy="finished-stamp"
        v-for="stamp in returnFinishedStamps"
        :key="stamp.name"
        :stamp="stamp"
        @click="toggleDisplayStampItem(stamp)"
      />
    </div>
    <stamp-item-details
      data-cy="stamp-item"
      :stampItem="stampItem"
      v-if="displayStampItem"
      @closeStampItem="toggleDisplayStampItem"
      @saveStampItem="saveStampItem"
    ></stamp-item-details>
  </div>
</template>

<script>
import moment from 'moment'

import StampTimerForm from '../../components/stampclock/StampTimerForm.vue'
import StampItemDetails from '../../components/timetracker/StampItemDetails.vue'
import StampItem from '../../components/timetracker/StampItem.vue'

export default {
  data() {
    return {
      displayStampItem: false,
      stampItem: null
    }
  },
  methods: {
    stampIn(postObject) {
      let timeGroup = { ...this.timeTrackerGroup }
      if (!timeGroup.stamps) {
        timeGroup.stamps = []
      }
      timeGroup.stamps.push(postObject)
      this.$store.dispatch('saveTimeGroup', timeGroup)
    },
    stampOut(postObject) {
      let timeGroup = { ...this.timeTrackerGroup }
      timeGroup.stamps.forEach(stamp => {
        if (stamp.ended === false) {
          stamp = postObject
        }
      })
      this.$store.dispatch('saveTimeGroup', timeGroup)
    },
    toggleDisplayStampItem(stamp) {
      if (this.stampItem) {
        this.stampItem = null
      } else {
        this.stampItem = stamp
      }
      this.displayStampItem = !this.displayStampItem
    },
    saveStampItem(stampItem) {
      let timeGroup = { ...this.timeTrackerGroup }
      timeGroup.stamps.forEach((stamp, index) => {
        if (stamp.id === stampItem.id) {
          timeGroup.stamps[index] = stampItem
        }
      })
      this.$store.dispatch('saveTimeGroup', timeGroup)
    },
    calculateTotalTimeInMs(stampArr) {
      let totalTimeInMs = 0
      stampArr.forEach(stamp => {
        if (stamp.ended !== false) {
          totalTimeInMs += stamp.durationInMs
        }
      })
      return totalTimeInMs
    },
    returnDurationFromMs(ms) {
      const duration = moment.duration(ms / 1000 / 60, 'minutes')
      let hours = Math.floor(duration.asHours())
      let minutes = Math.floor(duration.asMinutes()) - hours * 60
      let seconds =
        Math.floor(duration.asSeconds()) - hours * 60 * 60 - minutes * 60

      if (hours < 10) {
        hours = '0' + hours
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (seconds < 10) {
        seconds = '0' + seconds
      }
      const result = `${hours}:${minutes}:${seconds}`
      return result
    }
  },
  computed: {
    totalTime() {
      let allStamps = this.timeTrackerGroup.stamps
      if (!allStamps) return false

      let totalTimeInMs = this.calculateTotalTimeInMs(allStamps)

      const result = this.returnDurationFromMs(totalTimeInMs)

      return result
    },
    returnActiveStamp() {
      let allStamps = this.timeTrackerGroup.stamps
      if (!allStamps) return false
      let index = allStamps.findIndex(stamp => !stamp.ended)
      if (index === -1) {
        return false
      } else {
        return allStamps[index]
      }
    },
    returnFinishedStamps() {
      if (!this.timeTrackerGroup.stamps) return false
      let allStamps = [...this.timeTrackerGroup.stamps]

      allStamps.forEach((stamp, index) => {
        if (stamp.ended === false) {
          allStamps.splice(index, 1)
        }
      })

      // Sorting by name
      allStamps = allStamps.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })

      const tempArr = []

      // checking for duplicates
      allStamps.forEach((stamp, i) => {
        allStamps.forEach((_stamp, j) => {
          // Not checking itself
          if (i !== j) {
            // If name is the same
            if (stamp.name === _stamp.name) {
              // If no arr is present, create arr
              if (tempArr.length === 0) {
                let subStampObject = {
                  name: _stamp.name,
                  subStamps: [_stamp]
                }
                tempArr.push(subStampObject)
              } else {
                // Else, check if name is already in arr
                let foundIndex = tempArr.findIndex(s => s.name === _stamp.name)

                if (foundIndex === -1) {
                  let subStampObject = {
                    name: _stamp.name,
                    subStamps: [_stamp]
                  }
                  tempArr.push(subStampObject)
                } else {
                  // And check if in subarr
                  let foundIdIndex = tempArr[foundIndex].subStamps.findIndex(
                    s => s.id === _stamp.id
                  )
                  if (foundIdIndex === -1) {
                    tempArr[foundIndex].subStamps.push(_stamp)
                  }
                }
              }
            } else {
              let foundIndex = tempArr.findIndex(s => s.name === _stamp.name)
              if (foundIndex === -1) {
                let subStampObject = {
                  name: _stamp.name,
                  subStamps: [_stamp]
                }
                tempArr.push(subStampObject)
              }
            }
          }
        })
      })

      // Backup-check for single stamp in group
      if (tempArr.length < 1) {
        allStamps.forEach(stamp => {
          let subStampObject = {
            name: stamp.name,
            subStamps: [stamp]
          }
          tempArr.push(subStampObject)
        })
      }
      allStamps = tempArr
      return allStamps
    },
    timeTrackerGroup() {
      let allGroups = this.$store.getters.getTimeGroups
      let id = this.$route.params.id
      let group = {
        name: 'Loading'
      }
      allGroups.forEach(_group => {
        if (_group.id === id) {
          group = _group
        }
      })
      return group
    }
  },
  watch: {
    returnFinishedStamps: function(newVal) {
      if (newVal.length > 0) {
        newVal.forEach(stampGroup => {
          if (this.stampItem) {
            if (stampGroup.name === this.stampItem.name) {
              console.log('this.stampItem', this.stampItem)
              this.stampItem = stampGroup
            }
          }
        })
      }
    }
  },
  components: {
    StampTimerForm,
    StampItemDetails,
    StampItem
  }
}
</script>

<style scoped>
.time-stamp-container {
  display: grid;
  padding: 40px;
}
.card {
  min-height: 30px;
  display: grid;
  align-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom: 1px solid black;
}
.card p {
  margin: 0;
}
</style>
