import * as fb from '../../firebase'

const state = {
	expenses: []
}

const mutations = {
	setExpenses(state, val) {
		state.expenses = val
	}
}

const actions = {
	async addExpense(context, payload) {
		const uid = context.rootState.UserStore.userProfile.uid
		// fb.weeklyGoalsCollection(uid).add(payload)
    console.log('context', context)
    console.log('payload', payload.expenseObject)
    fb.monthlyBudgetCollection(uid, payload.year, payload.month).add(payload.expenseObject).then(() => {
      console.log('Expense added successfully!')
    }).catch(err => {
      console.log('Error when adding expense', err)
    })
		context.dispatch('fetchMonth', payload)
	},
	async fetchMonth(context, payload) {
    let expenses = []
    
    const uid = fb.auth.currentUser.uid
    const monthlyBudget = await fb.monthlyBudgetCollection(uid, payload.year, payload.month).get()
    // const monthlyBudget = await fb.monthlyBudgetCollection(uid, '2021', 'march').get()
    monthlyBudget.forEach(expense => {
      let expenseO = expense.data()
      expenseO.id = expense.id
      expenses.push(expenseO)
    })
    
		// set user profile in state
		context.commit('setExpenses', expenses)
	},
	async deleteWeeklyGoalFromDb(context, id) {
		const uid = context.rootState.UserStore.userProfile.uid

		fb.weeklyGoalsCollection(uid)
			.doc(id)
			.delete()
			.then(() => {
				console.log('Goal removed from database!')
			}).catch(err => {
        console.log('Error when deleting goal from db', err)
      })
		context.dispatch('fetchWeeklyGoals', uid)
	}
}

const getters = {
	getMonthlyExpenses(state) {
    console.log(state.expenses)
		return state.expenses
	}
}

export default {
	state,
	mutations,
	actions,
	getters
}
